import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { onLoading } from "../../actions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { API_PATH } from "../ApiPath/Apipath";
import PromptMappingModel from "./PromptMappingModel";
import { promptGuideLines } from "../utils/constants";

function PromptMapping(props) {
  const { setPage } = props;

  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [options, setOptions] = useState([]);
  const [promptData, setPromptData] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [showAdditionalDropdown, setShowAdditionalDropdown] = useState({});
  const [additionalValues, setAdditionalValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formLoader, setFormLoader] = useState(true);

  const history = useHistory();

  const optionalOption = [
    { label: "text", value: "text" },
    { label: "textarea", value: "textarea" },
  ];

  useEffect(() => {
    fetchProductAttributes();
    fetchPromptData();
  }, []);

  const fetchProductAttributes = async () => {
    try {
      const response = await axios.post(API_PATH.GET_GEMINI_PRODUCT_ATTRIBUTES);
      if (response.data.success) {
        const attributeOptions = response.data.data.map((attribute) => ({
          label: attribute,
          value: attribute,
        }));

        const defaultOption = {
          label: "Need_To_Create",
          value: "Need_To_Create",
        };
        setOptions([defaultOption, ...attributeOptions]);
      } else {
        console.error("Failed to fetch product attributes");
      }
    } catch (error) {
      console.error("Error fetching product attributes:", error);
    } finally {
      setFormLoader(false); // Ensure formLoader is set to false once data is fetched
    }
  };


  // const fetchPromptData = async () => {
  //   try {
  //     const id = localStorage.getItem("addPromptId");
  //     const supplierId = localStorage.getItem("supplierId");
  //     const response = await axios.post(API_PATH.GET_PROMTPT_VAL_BY_ID, {
  //       id,
  //       supplierId,
  //     });

  //     if (response.data.success) {
  //       const parsedData = JSON.parse(response.data.data.GeminiAttributeJSON);

  //       const finalAttributeMap = JSON.parse(
  //         response.data.data.
  //           GeminiFinalAttributeMap
  //       );
  //       const prefillOptions = {};
  //       if (finalAttributeMap?.prompt_mapping) {
  //         for (const key in finalAttributeMap?.prompt_mapping) {
  //           if (finalAttributeMap?.prompt_mapping.hasOwnProperty(key)) {
  //             prefillOptions[key] = {
  //               label: finalAttributeMap?.prompt_mapping[key],
  //               value: finalAttributeMap?.prompt_mapping[key],
  //             };
  //           }
  //         }
  //       }

  //       const additionalValues = {};
  //       if (finalAttributeMap?.Need_To_Create) {
  //         for (const key in finalAttributeMap?.Need_To_Create) {
  //           if (finalAttributeMap?.Need_To_Create.hasOwnProperty(key)) {
  //             additionalValues[key] = finalAttributeMap?.Need_To_Create[key];
  //             prefillOptions[key] = {
  //               label: "Need_To_Create",
  //               value: "Need_To_Create",
  //             };
  //           }
  //         }
  //       }
  //       setPromptData({
  //         ...response.data.data,
  //         GeminiAttributeJSON: parsedData,
  //         prefillOptions: prefillOptions,
  //       });

  //       setSelectedAttributes({
  //         ...finalAttributeMap?.prompt_mapping,
  //         ...Object.fromEntries(
  //           Object.keys(finalAttributeMap?.Need_To_Create || {}).map((key) => [
  //             key,
  //             "Need_To_Create",
  //           ])
  //         ),
  //       });

  //       setAdditionalValues(additionalValues);

  //       const showAdditionalDropdown = {};
  //       for (const attribute in additionalValues) {
  //         showAdditionalDropdown[attribute] = true;
  //       }
  //       setShowAdditionalDropdown(showAdditionalDropdown);
  //     } else {
  //       console.error("Failed to fetch prompt data");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching prompt data:", error);
  //   } finally {
  //     setFormLoader(false); // Ensure formLoader is set to false once data is fetched
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Validation to check if at least one attribute is mapped
  //   // const isMappingValid = Object.values(selectedAttributes).some(
  //   //   (value) => value && value !== "Need_To_Create"
  //   // );
  //   console.log("map=======",selectedAttributes)
  //   const isMappingValid = Object.values(selectedAttributes).some(
  //     (value) => value 
  //   );

  //   if (!isMappingValid) {
  //     toast.error("Please map at least one value.");
  //     return;
  //   }

  //   setIsLoadingExit(true);
  //   try {
  //     const id = localStorage.getItem("addPromptId");
  //     const supplierId = localStorage.getItem("supplierId");

  //     const promptMapping = {};
  //     const needToCreate = {};

  //     for (const attribute in selectedAttributes) {
  //       if (selectedAttributes[attribute] === "Need_To_Create") {
  //         needToCreate[attribute] = additionalValues[attribute] || "text";
  //       } else {
  //         promptMapping[attribute] = selectedAttributes[attribute];
  //       }
  //     }

  //     const dataPayload = {
  //       id: id,
  //       supplierId: supplierId,
  //       data: {
  //         prompt_mapping: promptMapping,
  //         Need_To_Create: needToCreate,
  //       },
  //     };
  //     const response = await axios.post(
  //       API_PATH.ADD_OR_EDIT_PROMPT_MAPPING,
  //       dataPayload
  //     );

  //     if (response.data.success) {
  //       setPage("3");
  //       setIsLoadingExit(false);
  //       toast.success("Data Saved successfully");
  //       // history.push("/prompt-list");
  //       // localStorage.removeItem("supplierId");
  //       // localStorage.removeItem("addPromptId");
  //       // localStorage.removeItem("currentPage");
  //     } else {
  //       console.error("Failed to submit prompt mapping");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting prompt mapping:", error);
  //   } finally {
  //     setIsLoadingExit(false);
  //   }
  // };


  const fetchPromptData = async () => {
    try {
      const id = localStorage.getItem("addPromptId");
      const supplierId = localStorage.getItem("supplierId");
      const response = await axios.post(API_PATH.GET_PROMTPT_VAL_BY_ID, {
        id,
        supplierId,
      });
      if (response.data.success) {
        const parsedData = JSON.parse(response.data.data.GeminiAttributeJSON);
        const finalAttributeMap = JSON.parse(response.data.data.GeminiFinalAttributeMap);

        // Prepare prefilled options
        const prefillOptions = {};
        for (const key in finalAttributeMap) {
          if (finalAttributeMap.hasOwnProperty(key)) {
            prefillOptions[key] = {
              label: key,
              value: finalAttributeMap[key],
            };
          }
        }

        setPromptData({
          ...response.data.data,
          GeminiAttributeJSON: parsedData,
          prefillOptions: prefillOptions,
        });

        setSelectedAttributes({
          ...finalAttributeMap
          // ...Object.fromEntries(
          //   Object.keys(finalAttributeMap?.Need_To_Create || {}).map((key) => [
          //     key,
          //     "Need_To_Create",
          //   ])
          // ),
        });

        // setAdditionalValues(Object.fromEntries(
        //   Object.keys(finalAttributeMap?.Need_To_Create || {}).map((key) => [
        //     key,
        //     finalAttributeMap?.Need_To_Create[key] || "text",
        //   ])
        // ));

        // setShowAdditionalDropdown(Object.fromEntries(
        //   Object.keys(finalAttributeMap?.Need_To_Create || {}).map((key) => [key, true])
        // ));
      } else {
        console.error("Failed to fetch prompt data");
      }
    } catch (error) {
      console.error("Error fetching prompt data:", error);
    } finally {
      setFormLoader(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isMappingValid = Object.values(selectedAttributes).some(
      (value) => value
    );

    if (!isMappingValid) {
      toast.error("Please map at least one value.");
      return;
    }

    setIsLoadingExit(true);
    try {
      const id = localStorage.getItem("addPromptId");
      const supplierId = localStorage.getItem("supplierId");

      const promptMapping = {};
      const needToCreate = {};

      for (const attribute in selectedAttributes) {
        if (selectedAttributes[attribute] === "Need_To_Create") {
          needToCreate[attribute] = additionalValues[attribute] || "text";
        } else {
          promptMapping[attribute] = selectedAttributes[attribute];
        }
      }

      const dataPayload = {
        id: id,
        supplierId: supplierId,
        data: {
          prompt_mapping: promptMapping,
          Need_To_Create: needToCreate,
        },
      };
      const response = await axios.post(
        API_PATH.ADD_OR_EDIT_PROMPT_MAPPING,
        dataPayload
      );

      if (response.data.success) {
        setPage("3");
        toast.success("Data Saved successfully");
      } else {
        console.error("Failed to submit prompt mapping");
      }
    } catch (error) {
      console.error("Error submitting prompt mapping:", error);
    } finally {
      setIsLoadingExit(false);
    }
  };


  const handleAttributeChange = (attribute, selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attribute]: selectedValue,
    }));

    setShowAdditionalDropdown((prevState) => ({
      ...prevState,
      [attribute]: selectedValue === "Need_To_Create",
    }));
  };

  const handleOptionalOptionChange = (attribute, selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : "text";
    setAdditionalValues((prevValues) => ({
      ...prevValues,
      [attribute]: selectedValue,
    }));
  };

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <form name="myForm" encType="multipart/form-data" onSubmit={handleSubmit}>
        <div style={{ marginTop: "15px" }}>
          <div className="alert alert-primary col-12 mt-3 d-flex justify-content-between align-items-center" role="alert">
            <div>
              <strong>INFO:</strong> {"  "}
              {promptGuideLines}
            </div>
            <button className="btn btn-primary w-auto btn-lg button-class" type="button" onClick={() => openModal()}>
              Prompt Guidelines
            </button>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 button-class">
              <div className="d-flex">
                <button className="btn btn-primary w-auto btn-lg mr-2" type="submit" disabled={isLoadingExit}>
                  {isLoadingExit ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    "Save & Next"
                  )}
                </button>
              </div>
            </div>
          </div>
          {formLoader && (
            <div className="loader-wrapper">
              <i className="fa fa-refresh fa-spin"></i>
            </div>
          )}
          <div className="row mt-0">
            <div className="col-sm-12">
              <table className="w-100 table-responsive-md">
                <thead>
                  <tr>
                    <th>Prompt Attribute</th>
                    <th>Product Attribute</th>
                    <th>Field Type</th>
                  </tr>
                </thead>
                <tbody>
                  {promptData && promptData.GeminiAttributeJSON &&
                    promptData.GeminiAttributeJSON.map((attribute, index) => (
                      <tr key={index}>
                        <td>{attribute}</td>
                        <td>
                          <div className="select-container">
                            {/* <Select
                              options={options}
                              defaultValue={promptData.prefillOptions && promptData.prefillOptions[attribute]}
                              value={
                                selectedAttributes[attribute]
                                  ? {
                                    label: selectedAttributes[attribute],
                                    value: selectedAttributes[attribute],
                                  }
                                  : null
                              }
                              isSearchable={true}
                              className="select"
                              styles={{
                                option: (styles, { data }) => ({
                                  ...styles,
                                  background: data.color,
                                }),
                              }}
                              onChange={(selectedOption) => handleAttributeChange(attribute, selectedOption)}
                            />*/}

                            <Select
                              options={options}
                              value={
                                selectedAttributes[attribute] // this ensures the current value is controlled
                                  ? {
                                    label: selectedAttributes[attribute],
                                    value: selectedAttributes[attribute],
                                  }
                                  : promptData.prefillOptions && promptData.prefillOptions[attribute]
                              }
                              isSearchable={true}
                              className="select"
                              onChange={(selectedOption) => handleAttributeChange(attribute, selectedOption)}
                            />

                          </div>
                        </td>
                        <td>
                          {showAdditionalDropdown[attribute] && (
                            <Select
                              options={optionalOption}
                              defaultValue={
                                additionalValues[attribute]
                                  ? {
                                    label: additionalValues[attribute],
                                    value: additionalValues[attribute],
                                  }
                                  : { label: "text", value: "text" }
                              }
                              value={
                                additionalValues[attribute]
                                  ? {
                                    label: additionalValues[attribute],
                                    value: additionalValues[attribute],
                                  }
                                  : { label: "text", value: "text" }
                              }
                              isSearchable={true}
                              className="select"
                              onChange={(selectedOption) => handleOptionalOptionChange(attribute, selectedOption)}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <PromptMappingModel showModal={showModal} setShowModal={setShowModal} />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = ({ LoadingReducer }) => ({
  isLoading: LoadingReducer.isLoading,
});
export default connect(mapStateToProps, { onLoading })(PromptMapping);
