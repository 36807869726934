import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { validatePassword } from "../Validations/Validation";
import axios from "axios";
import { toast } from "react-toastify";
import { API_PATH } from "../ApiPath/Apipath";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = (props) => {
  const [initFormData, setInitFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [changePasswordResponse, setChangePasswordResponse] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const history = useHistory();

  const userId = localStorage.getItem("_id");

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `${API_PATH.FETCH_USER_PROFILE_DETAILS}/${userId}`
      );
      const userData = response.data.data;
      setInitFormData(userData);
    } catch (error) {
      console.error(error);
    }
  };
  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(
          (prevShowCurrentPassword) => !prevShowCurrentPassword
        );
        break;
      case "new":
        setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(
          (prevShowConfirmPassword) => !prevShowConfirmPassword
        );
        break;
      default:
        break;
    }
  };
  const handleChange = (key, value) => {
    const newFormData = new FormData(document.forms.myForm);
    newFormData.set(key, value);
    const errors = validatePassword(newFormData);
    setFormErrors(errors);
    setIsFormValid(Object.keys(errors).length === 0);
  };

  const handleNameChange = (e, key) => {
    const value = e.target.value.trim();
    setInitFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    handleChange(key, value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const errors = validatePassword(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        setIsLoadingExit(true);
        const payload = {
          _id: userId,
          currentPassword: initFormData.currentPassword,
          newPassword: initFormData.newPassword,
        };
        const response = await axios.post(
          `${API_PATH.CHANGE_PASSWORD}`,
          payload
        );
        setChangePasswordResponse(response.data);
        setIsLoadingExit(false);
        toast.success(response.data.message);
      } catch (error) {
        setIsLoadingExit(false);
      }
    }
  };

  const goToDashboard = () => {
    Swal.fire({
      title: "Are you sure, <br> you want to exit ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        // setFormData();
        // setLogoData();
        history.push("/dashboard");
      }
    });
  };

  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText="Manage Profile"
              Breadcrumb={[{ name: "Manage Profile", navigate: "#" }]}
              style={{ position: "sticky", top: 0, zIndex: 999 }}
            />
            <div className="tab-component">
              <div className="card">
                <div className="body">
                  {props.loading ? (
                    <div className="loader-wrapper">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : null}

                  <form onSubmit={handleSubmit} name="myForm">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 button-class">
                        <div className="d-flex">
                          <button
                            className="btn btn-primary w-auto btn-lg mr-2"
                            type="submit"
                          >
                            {isLoadingExit ? (
                              <>
                                <Spinner animation="border" size="sm" /> Please
                                wait...
                              </>
                            ) : (
                              "Save & Exit"
                            )}
                          </button>
                          <button
                            className="btn btn-secondary w-auto btn-lg"
                            type="button"
                            onClick={goToDashboard}
                          >
                            Exit
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 mt-lg-0">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Current Password</label>
                          <div className="password-input">
                            <input
                              className="form-control"
                              type={showCurrentPassword ? "text" : "password"}
                              name="currentPassword"
                              placeholder="Current Password"
                              value={initFormData.currentPassword}
                              onChange={(e) =>
                                handleNameChange(e, "currentPassword")
                              }
                            />
                            <button
                              className="eye-btn"
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("current")
                              }
                            >
                              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors && formErrors.currentPassword && (
                            <span className="text-danger">
                              {formErrors.currentPassword}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Change Password</label>
                          <div className="password-input">
                            <input
                              className="form-control"
                              type={showNewPassword ? "text" : "password"}
                              name="newPassword"
                              placeholder="New Password"
                              value={initFormData.newPassword}
                              onChange={(e) =>
                                handleNameChange(e, "newPassword")
                              }
                            />
                            <button
                              className="eye-btn"
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("new")
                              }
                            >
                              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors && formErrors.newPassword && (
                            <span className="text-danger">
                              {formErrors.newPassword}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Confirm Password</label>
                          <div className="password-input">
                            <input
                              className="form-control"
                              type={showConfirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              value={initFormData.confirmPassword}
                              onChange={(e) =>
                                handleNameChange(e, "confirmPassword")
                              }
                            />
                            <button
                              className="eye-btn"
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("confirm")
                              }
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors && formErrors.confirmPassword && (
                            <span className="text-danger">
                              {formErrors.confirmPassword}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
