import React, { useState, useEffect } from "react";
import moment from "moment";
import Pagination from "react-responsive-pagination";
import axios from "axios";
import { onLoading } from "../../actions";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import Select from "react-select";
import { API_PATH } from "../ApiPath/Apipath";
import {formatDateToMMDDYYYYhhmma1} from "../FormatedDate/FormatedDate";

function ExportLogs(props) {
  const [exportData, setExportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLimit, setDataLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(2);
  const [autoId, setAutoId] = useState(1);

  const startIndex = (currentPage - 1) * dataLimit + 1;
  useEffect(() => {
    props.onLoading(true);
    getExportLogsData();
  }, [currentPage, dataLimit]);

  const getExportLogsData = async () => {
    props.onLoading(true);

    await axios
      .get(
        `${API_PATH.EXPORT_DATA_LOGS}?page=${currentPage}&limit=${dataLimit}`
      )
      .then((res) => {
        let totlePage = Math.ceil(res.data.totalRecord / res.data.limit);
        setTotalPages(totlePage);
        setExportData(res.data.data);
        if (currentPage === 1) {
          setAutoId((currentPage - 1) * dataLimit + 1);
        }
        props.onLoading(false);
      })
      .catch((e) => {
        setExportData([]);
        props.onLoading(false);
      });
  };
  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Export Data Logs"
            Breadcrumb={[{ name: "Export Data Logs", navigate: "#" }]}
          />
          <div className="tab-component">
            <div className="card">
              <div className="body">
                <div className="mb-3 top__header">
                  {/* <div style={{ minWidth: "140px" }}>
                  <Select
                    options={filterList}
                    onChange={handleFilterChange}
                    defaultValue={filterList[0]}
                  />
</div>*/}
                </div>

                <div className="data-table">
                  {props.isLoading ? (
                    <div className="loader-wrapper">
                      {" "}
                      <i className="fa fa-refresh fa-spin"></i>{" "}
                    </div>
                  ) : null}{" "}
                  <table className="table w-100 table-responsive-lg">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Supplier Name</th>
                        <th>MarketPlace Name</th>
                        <th>Exported Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exportData?.map((exportData, idx) => (
                        <tr key={exportData.id} className="custom-border-table">
                        <td>{startIndex + idx}</td>
                          <td>{exportData.supplierName}</td>
                          <td>{exportData.marketPlaceName}</td>
                          <td>
                            {exportData.updated
                              ? formatDateToMMDDYYYYhhmma1(exportData.updated)
                              : formatDateToMMDDYYYYhhmma1(exportData.created)}
                          </td>
                          {/*<td className="action-group">
                            <i
                              data-placement="top"
                              title="Edit"
                              style={{ color: "#49c5b6" }}
                              className="fa fa-eye"
                              onClick={() => openModal(exportData._id)}
                            ></i>
                            </td>*/}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {exportData?.length === 0 && (
                    <h4
                      className="no-data"
                      style={{ color: props.isLoading ? "white" : "#8b8a8a" }}
                    >
                      No Data Found
                    </h4>
                  )}
                  {exportData?.length > 0 && (
                    <div className="pagination-wrapper">
                      <Pagination
                          current={currentPage}
                          total={totalPages}
                          onPageChange={setCurrentPage}
                        maxWidth={400}
                      />
                      <select
                        name="companyOwner"
                        className="form-control"
                          onChange={(e) => {
                            setCurrentPage(1);
                            setDataLimit(e.target.value);
                          }}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ LoadingReducer }) => ({
  isLoading: LoadingReducer.isLoading,
});
export default connect(mapStateToProps, { onLoading })(ExportLogs);
