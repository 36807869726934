import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Spinner, Table } from "react-bootstrap";
import axios from "axios";
import { API_PATH } from "../ApiPath/Apipath";

const GeminiPromptHistory = ({
  showGeminiModal,
  setShowGeminiModal,
  selectedId,
}) => {
  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchApiLogData = async () => {
    if (showGeminiModal) {
      setLoading(true); // Start loader
      try {
        const response = await axios.post(
          API_PATH.GET_PROMPT_HISTORY_LOG_BY_ID,
          {
            id: selectedId,
          }
        );
        if (response.status === 200) {
          setApiData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching prompt history:", error);
      } finally {
        setLoading(false); // Stop loader
      }
    }
  };


  useEffect(() => {
    fetchApiLogData();
  }, [showGeminiModal]);

  return (
    <Modal
      show={showGeminiModal}
      onHide={() => setShowGeminiModal(false)}
      dialogClassName="modal__main"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {apiData.PromptType} Prompt Log
        </Modal.Title>
      </Modal.Header>

      {loading ? (
        // Display spinner while loading
        <div className="loader-wrapper" style={{ background: "none", color: "gray", marginTop:"7%" }}>
          <i className="fa fa-refresh fa-spin"></i>
        </div>
      ) : (
        <Modal.Body>
          <>
            {apiData.new_gpt_system_prompt || apiData.old_gpt_system_prompt ? (
              <Table bordered>
                <thead>
                  <tr>
                    <th className="text-center">Old System Prompt</th>
                    <th className="text-center">New System Prompt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <textarea
                        style={{ width: "100%", height: "200px", resize: "none", background: "#efeeee" }}
                        value={apiData.new_gpt_system_prompt || ""}
                        readOnly
                      />
                    </td>
                    <td>
                      <textarea
                        style={{ width: "100%", height: "200px", resize: "none", background: "#efeeee" }}
                        value={apiData.old_gpt_system_prompt || ""}
                        readOnly
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>) : null}
            <Table bordered>
              <thead>
                <tr>
                  <th className="text-center">Old Prompt</th>
                  <th className="text-center">New Prompt</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <textarea
                      style={{ width: "100%", height: "400px", resize: "none", background: "#efeeee" }}
                      value={apiData.old_prompt || ""}
                      readOnly
                    />
                  </td>
                  <td>
                    <textarea
                      style={{ width: "100%", height: "400px", resize: "none", background: "#efeeee" }}
                      value={apiData.new_prompt || ""}
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </>

        </Modal.Body>
      )}

      <Modal.Footer>
        <button
          onClick={() => setShowGeminiModal(false)}
          className="btn btn-outline-info"
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default GeminiPromptHistory;
