import React, { createContext, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { connect } from "react-redux";
import { onUpdateFormLoading } from "../../actions";
import "./Variant.css";
import { Tab, Tabs } from "react-bootstrap";
import Parent from "./parent/Parent";
import Variant from "./variants/Variant";

export const FormContext = createContext();

const ManageVariant = (props) => {
  const [key, setKey] = useState("parent");

  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText={"Product Details"}
              Breadcrumb={[
                { name: "Products", navigate: "#" },
                { name: "Products List", navigate: "/products" },
                { name: "Product Details", navigate: "#" },
              ]}
            />

            <div className="tab-component">
              <div className="card">
                <div className="">
                  {props.updateFormLoading ? (
                    <div className="loader-wrapper">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : null}
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                  >
                    <Tab eventKey="parent" title="PARENT">
                      <Parent activeKey={key} setKey={setKey} />
                    </Tab>
                    <Tab eventKey="variants" title="VARIANTS">
                      <Variant activeKey={key} setKey={setKey} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ LoadingReducer }) => ({
  updateFormLoading: LoadingReducer.updateFormLoading,
});
export default connect(mapStateToProps, { onUpdateFormLoading })(ManageVariant);
