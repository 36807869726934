import React from "react";
import Select from "react-select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function PriceCalcIndex({
  calculation,
  calcIndex,
  price,
  supplierId,
  formErrors,
  handleSelectChange,
  handleInputChange,
  handleDeleteField,
  supplier,
}) {
  return (
    <>
      <div className="row mt-3 p-md-1" key={calcIndex}>
        <div className="col-sm-3 col-md-4 col-lg-2">
          <div>
            <label>Select Price</label>

            <Select
              options={price}
              placeholder="Select"
              name="costPriceField"
              className="temp"
              menuPlacement="top"
              defaultValue={{
                label: calculation.costPriceField,
                value: calculation.costPriceField,
              }}
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, supplierId, calcIndex)
              }
              styles={{
                option: (styles) => {
                  return {
                    ...styles,
                    color: "#545454",
                    fontSize: "14px",
                  };
                },
              }}
            />
            {formErrors.costPriceField && (
              <span className="text-danger validation">
                {formErrors.costPriceField}
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
          <div className="form-group">
            <label htmlFor="value2">Min</label>
            <input
              id="value1"
              className="form-control price__input__height min"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="minimumValue"
              defaultValue={
                calculation.minimumValue ? calculation.minimumValue : ""
              }
              onChange={(e) => handleInputChange(e, supplierId, calcIndex)}
            />
          </div>
          {formErrors && formErrors.minimumValue && (
            <span className="text-danger valiadtion">
              {formErrors.minimumValue}
            </span>
          )}
        </div>
        <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
          <div className="form-group">
            <label htmlFor="value2">Max</label>
            <input
              id="value2"
              className="form-control price__input__height min"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="maximumValue"
              defaultValue={calculation.maximumValue || ""}
              onChange={(e) => handleInputChange(e, supplierId, calcIndex)}
            />
          </div>
          {formErrors && formErrors.maximumValue && (
            <span className="text-danger valiadtion">
              {formErrors.maximumValue}
            </span>
          )}
        </div>
        <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
          <div className="form-group">
            <label htmlFor="value2">Multiple</label>
            <input
              id="value3"
              className="form-control price__input__height"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="multipleValue"
              defaultValue={calculation.multipleValue || ""}
              onChange={(e) => handleInputChange(e, supplierId, calcIndex)}
            />
            {formErrors && formErrors.multipleValue && (
              <span className="text-danger valiadtion">
                {formErrors.multipleValue}
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-4 col-lg-2">
          <div className="form-group">
            <label htmlFor="value2">Fixed</label>
            <input
              id="value4"
              className="form-control price__input__height"
              placeholder="Enter Value"
              step="any"
              type="number"
              name="fixedValue"
              defaultValue={calculation.fixedValue || ""}
              onChange={(e) => handleInputChange(e, supplierId, calcIndex)}
            />
            {formErrors && formErrors.fixedValue && (
              <span className="text-danger valiadtion">
                {formErrors.fixedValue}
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-4 col-lg-2">
          <div className="form-group">
            <label htmlFor="value3">Tax</label>
            <input
              id="value5"
              className="form-control price__input__height"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="taxValue"
              defaultValue={calculation.taxValue || ""}
              onChange={(e) => handleInputChange(e, supplierId, calcIndex)}
            />
            {formErrors && formErrors.taxValue && (
              <span className="text-danger valiadtion">
                {formErrors.taxValue}
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-4 col-lg-1">
          <div className="form-group">
            <label htmlFor="value4">Discount</label>
            <input
              id="value6"
              className="form-control price__input__height min"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="discountValue"
              defaultValue={calculation.discountValue || ""}
              onChange={(e) => handleInputChange(e, supplierId, calcIndex)}
            />
            {formErrors && formErrors.discountValue && (
              <span className="text-danger valiadtion">
                {formErrors.discountValue}
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-4 col-lg-1">
          <div className="form-group ml-3" style={{ marginTop: "35px" }}>
            {supplier.price_calculation.length > 1 && (
              <DeleteForeverIcon
              className="delete-idx-icon"
                onClick={() =>
                  handleDeleteField(
                    calculation.priceRangeId
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceCalcIndex;
