
const formatDateToMMDDYYYYhhmma=((dateString=>{
  const dateParts = dateString.match(/\d+/g);
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]);
  const day = parseInt(dateParts[2]);
  const hours = parseInt(dateParts[3]);
  const minutes = parseInt(dateParts[4]);

  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, "0");
  const utcDay = String(date.getUTCDate()).padStart(2, "0");
  const utcYear = date.getUTCFullYear();
  const utcHours = String(date.getUTCHours()).padStart(2, "0");
  const utcMinutes = String(date.getUTCMinutes()).padStart(2, "0");
  const ampm = utcHours >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  const formattedHours = utcHours % 12 || 12;

  const formattedDate = `${utcMonth}/${utcDay}/${utcYear} ${formattedHours}:${utcMinutes} ${ampm}`;
  return formattedDate;
})) 

export default formatDateToMMDDYYYYhhmma

export const formatDateToMMDDYYYYhhmma1 = (dateString) => {
  const date = new Date(dateString);

  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, "0");
  const utcDay = String(date.getUTCDate()).padStart(2, "0");
  const utcYear = date.getUTCFullYear();
  const utcHours = String(date.getUTCHours()).padStart(2, "0");
  const utcMinutes = String(date.getUTCMinutes()).padStart(2, "0");
  const ampm = utcHours >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  const formattedHours = utcHours % 12 || 12;

  const formattedDate = `${utcMonth}/${utcDay}/${utcYear} ${formattedHours}:${utcMinutes} ${ampm}`;
  return formattedDate;
};


