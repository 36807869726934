import React from "react";
import { connect } from "react-redux";
import "echarts-gl";
import refreshLogo from "../../assets/images/refreshLogo.png";
import PageHeader from "../../components/PageHeader";
import { saleGaugeOption, sparkleCardData } from "../../Data/DashbordData";
import {
  toggleMenuArrow,
  onPressTopProductDropDown,
  loadSparcleCard,
  onPressReferralsDropDown,
  onPressRecentChatDropDown,
  onPressDataManagedDropDown,
  facebookProgressBar,
  twitterProgressBar,
  affiliatesProgressBar,
  searchProgressBar,
} from "../../actions";
import SparkleCard from "../../components/SparkleCard";
import axios from "axios";
import { API_PATH } from "../ApiPath/Apipath";

var timer = null;
class Dashbord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],
      loadingPage: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadDataCard();
  }

  async loadDataCard() {
    try {
      this.setState({ loadingPage: true });
      const response = await axios.get(`${API_PATH.GET_DASHBOARD_DATA}`);
      const data = response.data;

      const updatedCardData = sparkleCardData.map((card) => {
        let count;
        switch (card.heading) {
          case "SUPPLIER":
            count = data.suppliers;
            break;
          case "INTEGRATOR":
            count = data.integrators;
            break;
          case "MARKETPLACE":
            count = data.MarketPlace;
            break;
          case "PRODUCT":
            count = data.products;
            break;
          case `TODAY'S COST($)`:
            count = data.cost;
            break;
          default:
            count = 0;
        }

        return {
          ...card,
          money: count,
        };
      });

      this.setState({ cardData: updatedCardData, loadingPage: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ loadingPage: false });
    }
  }

  render() {
    const { cardData, loadingPage } = this.state;

    if (loadingPage) {
      return (
        <div className="page-loader-wrapper">
          <div className="loader">
            <div className="m-t-30">
              <img src={refreshLogo} style={{ height: "20px" }} alt="Azura" />
            </div>
            <p>Please wait...</p>
          </div>
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText="Dashboard"
              Breadcrumb={[{ name: "Dashboard", navigate: "#" }]}
            />
            <div className="row clearfix">
              {cardData.map((data, i) => (
                <SparkleCard
                  index={i}
                  key={data.heading}
                  Heading={data.heading}
                  Money={data.money}
                  isRandomUpdate={true}
                  mainData={data.sparklineData.data}
                  chartColor={data.sparklineData.areaStyle.color}
                  ContainerClass="col-lg-3 col-md-6 col-sm-6"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  loginReducer,
  navigationReducer,
  analyticalReducer,
}) => ({
  email: loginReducer.email,
  menuArrowToggle: navigationReducer.menuArrowToggle,
  sparkleCardData: analyticalReducer.sparkleCardData,
  topProductDropDown: analyticalReducer.topProductDropDown,
  referralsDropDown: analyticalReducer.referralsDropDown,
  recentChatDropDown: analyticalReducer.recentChatDropDown,
  facebookShowProgressBar: analyticalReducer.facebookShowProgressBar,
  twitterShowProgressBar: analyticalReducer.twitterShowProgressBar,
  affiliatesShowProgressBar: analyticalReducer.affiliatesShowProgressBar,
  searchShowProgressBar: analyticalReducer.searchShowProgressBar,
  loadingPage: analyticalReducer.loadingPage,
});

export default connect(mapStateToProps, {
  toggleMenuArrow,
  loadSparcleCard,
  onPressTopProductDropDown,
  onPressReferralsDropDown,
  onPressRecentChatDropDown,
  onPressDataManagedDropDown,
  facebookProgressBar,
  twitterProgressBar,
  affiliatesProgressBar,
  searchProgressBar,
})(Dashbord);
