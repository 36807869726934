import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Spinner, Accordion, Card } from "react-bootstrap";
import Select from "react-select";
import { onLoading } from "../../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FormContext } from "../ManageRetailer/ManageRetailerSetting";
import { useHistory } from "react-router-dom";
import { validatePriceCalculation } from "../Validations/Validation";
import { API_PATH } from "../ApiPath/Apipath";
import "../ManageRetailer/Retailer.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PriceCalcIndex from "./PriceCalcIndex";
import Swal from "sweetalert2";

const radioBtns = [
  { label: "Round up to Nearest Decimal Point", value: "decimal" },
  { label: "Round up to Nearest Multiple of 5", value: "5" },
];
function PriceCalculation(props) {
  const { setPage } = props;
  const { processCancel } = useContext(FormContext);
  const [additionalFieldsCount, setAdditionalFieldsCount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initFormData, setInitFormData] = useState([]);
  const [price, setPrice] = useState([]);
  const history = useHistory();
  const [supplierData, setSupplierData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedRadioOption, setSelectedRadioOption] = useState(["5"]);

  useEffect(() => {
    setIsFormValid(Object.keys(formErrors).length === 0);
  }, [formErrors]);

  useEffect(() => {
    getPrice();
    getRetailerIntegrationById();
  }, []);

  const getPrice = () => {
    try {
      axios
        .get(`${API_PATH.GET_RETAILER_PRICE_LIST}`)
        .then((response) => {
          const { success, message, data } = response.data;
          if (success) {
            const options = Object.keys(data).map((price) => ({
              value: data[price],
              label: price,
            }));
            setPrice(options);
          } else {
            toast.error(message);
          }
        })
        .catch((error) => {
          console.error("Failed to retrieve supplier list:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRadioChange = (event, accDataId) => {
    const { value } = event.target;
    const newSelectedRadioOption = [...selectedRadioOption];
    newSelectedRadioOption[accDataId] = value;
    setSelectedRadioOption(newSelectedRadioOption);
  };

  useEffect(() => {
    const defaultValue = "5";
    const defaultSelectedRadioOption = Array(supplierData.length).fill(
      defaultValue
    );
    setSelectedRadioOption(defaultSelectedRadioOption);
  }, [supplierData]);

  useEffect(() => {
    const initialData = supplierData.map((supplier) => ({
      supplierId: supplier.supplierId,
      price_calculation: supplier.price_calculation?.map((calculation) => ({
        costPriceField: calculation.costPriceField || "",
        minimumValue: calculation.minimumValue || "",
        maximumValue: calculation.maximumValue || "",
        multipleValue: calculation.multipleValue || "",
        fixedValue: calculation.fixedValue || "",
        taxValue: calculation.taxValue || "",
        discountValue: calculation.discountValue || "",
      })) || [
        {
          costPriceField: "",
          minimumValue: "",
          maximumValue: "",
          multipleValue: "",
          fixedValue: "",
          taxValue: "",
          discountValue: "",
        },
      ],
    }));
    initialData.forEach((data) => {
      if (data.price_calculation.length === 0) {
        data.price_calculation.push({
          costPriceField: "",
          minimumValue: "",
          maximumValue: "",
          multipleValue: "",
          fixedValue: "",
          taxValue: "",
          discountValue: "",
        });
      }
    });

    setInitFormData(initialData);
  }, [supplierData]);

  const handleSelectChange = (selectedOption, supplierId, calcIndex) => {
    setInitFormData((prevInitFormData) => {
      return prevInitFormData.map((supplier) => {
        if (supplier.supplierId === supplierId) {
          return {
            ...supplier,
            price_calculation: supplier.price_calculation?.map(
              (calculation, index) => {
                if (index === calcIndex) {
                  return {
                    ...calculation,
                    costPriceField: selectedOption.label,
                  };
                }
                return calculation;
              }
            ),
          };
        }
        return supplier;
      });
    });
  };

  const handleChange = (key, value) => {
    const formData = new FormData(document.forms.priceForm);
    formData.set(key, value);
    const errors = validatePriceCalculation(formData);
    setFormErrors(errors);
    setIsFormValid(Object.keys(errors).length === 0);
  };

  const handleInputChange = (e, supplierId, calcIndex) => {
    const { name, value } = e.target;

    setInitFormData((prevState) => {
      return prevState.map((supplier) => {
        if (supplier.supplierId === supplierId) {
          return {
            ...supplier,
            price_calculation: supplier.price_calculation?.map(
              (calculation, index) => {
                if (index === calcIndex) {
                  return {
                    ...calculation,
                    [name]: value,
                  };
                }
                return calculation;
              }
            ),
          };
        }
        return supplier;
      });
    });

    handleChange(name, value);
  };

  const handleAddField = (supplierId) => {
    setAdditionalFieldsCount((prevState) => {
      return {
        ...prevState,
        [supplierId]: (prevState[supplierId] || 0) + 1,
      };
    });

    setInitFormData((prevState) => {
      const updatedState = prevState.map((supplier) => {
        if (supplier.supplierId === supplierId) {
          const newCalculation = {
            costPriceField: "",
            minimumValue: "",
            maximumValue: "",
            multipleValue: "",
            fixedValue: "",
            taxValue: "",
            discountValue: "",
          };

          if (Array.isArray(supplier.price_calculation)) {
            return {
              ...supplier,
              price_calculation: [
                ...supplier.price_calculation,
                newCalculation,
              ],
            };
          } else {
            return {
              ...supplier,
              price_calculation: [newCalculation],
            };
          }
        }
        return supplier;
      });

      return updatedState;
    });
  };

  const handleDeleteField = (priceRangeId) => {
    console.log("Deleting row with priceRangeId:", priceRangeId);
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteRow(priceRangeId);
      }
    });
  };

  const handleDeleteField1 = (supplierId, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedFormData = initFormData.map((formData) => {
          if (formData.supplierId === supplierId) {
            formData.price_calculation = formData.price_calculation.filter(
              (item, itemIndex) => itemIndex !== parseInt(index, 10)
            );
          }
          return formData;
        });
        setInitFormData(updatedFormData);
      }
    });
  };

  const handleDeleteRow = async (priceRangeId) => {
    try {
      await axios.post(`${API_PATH.DELETE_PRICE_CALCLATION_ROW}`, {
        id: priceRangeId,
      });

      setSupplierData((prevSupplierData) => {
        const updatedSupplierData = prevSupplierData.map((supplier) => ({
          ...supplier,
          price_calculation: supplier.price_calculation?.filter(
            (calculation) => calculation.priceRangeId !== priceRangeId
          ),
        }));
        return updatedSupplierData;
      });
      props.onLoading(true);
      await getRetailerIntegrationById();
      props.onLoading(false);
    } catch (error) {
      console.error("Failed to delete row:", error);
    }
  };

  const checkAnyValueIsNegative = (payload) => {
    return payload.some(
      (val) =>
        val.multipleValue < 0 ||
        val.fixedValue < 0 ||
        val.taxValue < 0 ||
        val.discountValue < 0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const errors = validatePriceCalculation(formData);
    console.log("error-----",errors)

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const retailerIntegrationId = localStorage.getItem(
          "retailerIntegrationId"
        );
        const supplierSettingId = localStorage.getItem("supplierSettingId");

        const payload = [];
        const missingSelections = [];
        supplierData.forEach((supplier, idx) => {
          const supplierId = supplier.supplierId;

          const initFormDataItem = initFormData.find(
            (data) => data.supplierId === supplierId
          );

          const priceCalculationData =
            (initFormDataItem && initFormDataItem.price_calculation) || [];

          const isPriceSelected = priceCalculationData.some(
            (calculation) =>
              !calculation.costPriceField ||
              calculation.costPriceField.value === "" ||
              calculation.costPriceField.label === "Select"
          );

          if (isPriceSelected) {
            missingSelections.push(supplierId);
          }

          const supplierData = {
            id: retailerIntegrationId,
            supplierId: supplierId,
            price_calculation: priceCalculationData,
            roundUp: selectedRadioOption[idx],
            discountType: "Percentage",
          };

          payload.push(supplierData);
        });
        if (missingSelections.length > 0) {
          const missingSuppliersMessage = missingSelections.join(", ");
          // return toast.error(
          //   `Please select Price for supplier(s) ${missingSuppliersMessage}.`
          // );
          return toast.error(`Please Select Price`);
        }
        const checkNegativeValue = checkAnyValueIsNegative(payload);
        if (checkNegativeValue) {
          return toast.error("Values cannot be negative.");
        }
        console.log("payload--", payload);
        setIsLoading(true);
        const response = await axios.post(
          `${API_PATH.CREATE_RETAILER_PRICE}`,
          payload
        );

        const { success, message } = response.data;
        if (success) {
          toast.success(message);
          setPage(6);
        } else {
          toast.error(message);
        }
      } catch (error) {
        console.error("Failed to submit data:", error);
        setIsLoading(false);
      }
    }
  };

  const getRetailerIntegrationById = async () => {
    try {
      const id = localStorage.getItem("retailerIntegrationId");
      const response = await axios.post(`${API_PATH.GET_RETAILER_BY_ID}`, {
        id: id,
      });
      const { success, message, data } = response.data;
      if (success) {
        setSupplierData(data);
        const formattedData = [];
        const initialSelectedOptions = {};

        data.forEach((supplier) => {
          const formattedSupplier = {
            id: supplier.id,
            supplierId: supplier.supplierId,
            price_calculation: supplier.price_calculation?.map(
              (calculation) => ({
                costPriceField: calculation.costPriceField,
                minimumValue: calculation.minimumValue || "",
                maximumValue: calculation.maximumValue || "",
                multipleValue: calculation.multipleValue || "",
                fixedValue: calculation.fixedValue || "",
                taxValue: calculation.taxValue || "",
                discountValue: calculation.discountValue || "",
                discountType: calculation.discountType || "",
                extraValue: calculation.extraValue || "",
                // roundUp: calculation.roundUp || "",
              })
            ),
          };

          formattedData.push(formattedSupplier);
        });
        setInitFormData(formattedData);
        setSelectedOptions(initialSelectedOptions);
        const selectedOptions = data.map(
          (supplier) => supplier.price_calculation[0].roundUp
        );
        setSelectedRadioOption(selectedOptions);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Failed to retrieve retailer integration data:", error);
    }
  };

  const handleOnClick = async (e) => {
    e.preventDefault();
    const form = e.currentTarget.closest("form");
    if (!form) {
      return;
    }
    const formData = new FormData(form);
    const errors = validatePriceCalculation(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const retailerIntegrationId = localStorage.getItem(
          "retailerIntegrationId"
        );
        const supplierSettingId = localStorage.getItem("supplierSettingId");

        const payload = [];
        const missingSelections = [];
        supplierData.forEach((supplier, idx) => {
          const supplierId = supplier.supplierId;

          const initFormDataItem = initFormData.find(
            (data) => data.supplierId === supplierId
          );

          const priceCalculationData =
            (initFormDataItem && initFormDataItem.price_calculation) || [];

          const isPriceSelected = priceCalculationData.some(
            (calculation) =>
              !calculation.costPriceField ||
              calculation.costPriceField.value === "" ||
              calculation.costPriceField.label === "Select"
          );

          if (isPriceSelected) {
            missingSelections.push(supplierId);
          }

          const supplierData = {
            id: retailerIntegrationId,
            supplierId: supplierId,
            price_calculation: priceCalculationData,
            roundUp: selectedRadioOption[idx],
            discountType: "Percentage",
          };

          payload.push(supplierData);
        });
        if (missingSelections.length > 0) {
          const missingSuppliersMessage = missingSelections.join(", ");
          // return toast.error(
          //   `Please select Price for supplier(s) ${missingSuppliersMessage}.`
          // );
          return toast.error(`Please Select Price`);
        }
        const checkNegativeValue = checkAnyValueIsNegative(payload);
        if (checkNegativeValue) {
          return toast.error("Values cannot be negative.");
        }

        setIsLoadingExit(true);
        axios
          .post(`${API_PATH.CREATE_RETAILER_PRICE}`, payload)
          .then((response) => {
            const { success, message } = response.data;
            if (success) {
              localStorage.removeItem("supplierSettingId");
              localStorage.removeItem("selectedSupplierName");
              localStorage.removeItem("retailerIntegrationId");
              localStorage.removeItem("currentPage");

              toast.success(message);
              history.push("/setting-retailer-list");
            } else {
              toast.error(message);
            }
          })
          .catch((error) => {
            console.error("Failed to submit data:", error);
            setIsLoadingExit(false);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} name="priceForm">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 button-class">
            <div className="d-flex">
              <button
                className="btn btn-primary w-auto btn-lg mr-2"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Please wait...
                  </>
                ) : (
                  "Save & Next"
                )}
              </button>

              <button
                className="btn btn-primary w-auto btn-lg mr-2"
                type="submit"
                onClick={handleOnClick}
              >
                {isLoadingExit ? (
                  <>
                    <Spinner animation="border" size="sm" /> Please wait...
                  </>
                ) : (
                  "Save & Exit"
                )}
              </button>
              <button
                className="btn btn-secondary w-auto btn-lg"
                type="button"
                onClick={processCancel}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
        <div className="alert alert-primary col-12 mt-3 ml-2" role="alert">
          <strong>INFO:</strong> <br />
          Please specify the price calculation logic for each supplier.
        </div>
        <div className="row mt-3" style={{ backgroundColor: "#6c757d0f" }}>
          {supplierData.length === 0 ? (
            <div className="loader-wrapper w-100" style={{ marginTop: "14%" }}>
              <i className="fa fa-refresh fa-spin"></i>
            </div>
          ) : (
            ""
          )}
          <div className="col-12">
            <Accordion defaultActiveKey="0" className="accordian__main">
              {supplierData.map((supplier, index) => (
                <Card key={index}>
                  <Card.Header>
                    <Accordion.Toggle
                      eventKey={index.toString()}
                      className="btn btn-link collapsed text-decoration-none"
                      style={{ borderBottom: "2px solid #49c5b6" }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{supplier.supplierNames}</span>
                        <div className="d-flex align-items-center">
                        <span style={{fontSize:"14px"}}>Source Currency : {supplier.supplierCurrency}</span>
                        <i className="fa fa-angle-down arrow color-arrow ml-2"></i>
                    </div>
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body className="price-calc-card">
                      <div className="price-calc-card-div">
                        <div className="row ml-2">
                          <label className="text-success">
                            Price Calculation Formula: ((Cost * Multiple + Fixed
                            ) * Tax) - discount %)
                          </label>
                        </div>
                        <div className="row" style={{ marginLeft: "95%" }}>
                          <div>
                            <AddCircleIcon
                              onClick={() =>
                                handleAddField(supplier.supplierId)
                              }
                              style={{
                                cursor: "pointer",
                                color: "#49c5b6",
                                fontSize: "29px",
                              }}
                            />
                          </div>
                        </div>
                        {props.loading ? (
                          <div className="loader-wrapper">
                            <i className="fa fa-refresh fa-spin"></i>
                          </div>
                        ) : (
                          <>
                            {supplier.price_calculation?.map(
                              (calculation, calcIndex) => (
                                <PriceCalcIndex
                                  key={calcIndex}
                                  calculation={calculation}
                                  calcIndex={calcIndex}
                                  price={price}
                                  supplierId={supplier.supplierId}
                                  formErrors={formErrors}
                                  handleSelectChange={handleSelectChange}
                                  handleInputChange={handleInputChange}
                                  handleDeleteField={handleDeleteField}
                                  supplier={supplier}
                                />
                              )
                            )}
                          </>
                        )}

                        {!supplier.price_calculation?.length && (
                          <div className="row mt-3 p-md-1">
                            <div className="col-sm-3 col-md-4 col-lg-2">
                              <div>
                                <label htmlFor={`price-${index}`}>
                                  Select Price
                                </label>
                                <Select
                                  options={price}
                                  placeholder="Select"
                                  name="costPriceField"
                                  className="temp"
                                  menuPlacement="top"
                                  onChange={(selectedOption) =>
                                    handleSelectChange(
                                      selectedOption,
                                      supplier.supplierId,
                                      0
                                    )
                                  }
                                  styles={{
                                    option: (styles) => {
                                      return {
                                        ...styles,
                                        color: "#545454",
                                        fontSize: "14px",
                                      };
                                    },
                                  }}
                                />
                                {formErrors.costPriceField && (
                                  <span className="text-danger validation">
                                    {formErrors.costPriceField}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
                              <div className="form-group">
                                <label htmlFor="value2">Min</label>
                                <input
                                  id={`value1-${index}`}
                                  className="form-control price__input__height min"
                                  placeholder="Enter Value"
                                  type="number"
                                  step="any"
                                  name="minimumValue"
                                  onChange={(e) =>
                                    handleInputChange(e, supplier.supplierId, 0)
                                  }
                                />
                              </div>
                              {formErrors && formErrors.minimumValue && (
                                <span className="text-danger valiadtion">
                                  {formErrors.minimumValue}
                                </span>
                              )}
                            </div>
                            <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
                              <div className="form-group">
                                <label htmlFor="value2">Max</label>
                                <input
                                  id={`value2-${index}`}
                                  className="form-control price__input__height min"
                                  placeholder="Enter Value"
                                  type="number"
                                  step="any"
                                  name="maximumValue"
                                  onChange={(e) =>
                                    handleInputChange(e, supplier.supplierId, 0)
                                  }
                                />
                              </div>
                              {formErrors && formErrors.maximumValue && (
                                <span className="text-danger valiadtion">
                                  {formErrors.maximumValue}
                                </span>
                              )}
                            </div>
                            <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
                              <div className="form-group">
                                <label htmlFor="value2">Multiple</label>
                                <input
                                  id={`value3-${index}`}
                                  className="form-control price__input__height"
                                  placeholder="Enter Value"
                                  type="number"
                                  step="any"
                                  name="multipleValue"
                                  onChange={(e) =>
                                    handleInputChange(e, supplier.supplierId, 0)
                                  }
                                />
                                {formErrors && formErrors.multipleValue && (
                                  <span className="text-danger valiadtion">
                                    {formErrors.multipleValue}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4 col-lg-2">
                              <div className="form-group">
                                <label htmlFor="value2">Fixed</label>
                                <input
                                  id={`value4-${index}`}
                                  className="form-control price__input__height"
                                  placeholder="Enter Value"
                                  step="any"
                                  type="number"
                                  name="fixedValue"
                                  onChange={(e) =>
                                    handleInputChange(e, supplier.supplierId, 0)
                                  }
                                />
                                {formErrors && formErrors.fixedValue && (
                                  <span className="text-danger valiadtion">
                                    {formErrors.fixedValue}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4 col-lg-2">
                              <div className="form-group">
                                <label htmlFor="value3">Tax</label>
                                <input
                                  id={`value4-${index}`}
                                  className="form-control price__input__height"
                                  placeholder="Enter Value"
                                  type="number"
                                  step="any"
                                  name="taxValue"
                                  onChange={(e) =>
                                    handleInputChange(e, supplier.supplierId, 0)
                                  }
                                />
                                {formErrors && formErrors.taxValue && (
                                  <span className="text-danger valiadtion">
                                    {formErrors.taxValue}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4 col-lg-2">
                              <div className="form-group">
                                <label htmlFor="value4">Discount</label>
                                <input
                                  id={`value4-${index}`}
                                  className="form-control price__input__height"
                                  placeholder="Enter Value"
                                  type="number"
                                  step="any"
                                  name="discountValue"
                                  onChange={(e) =>
                                    handleInputChange(e, supplier.supplierId, 0)
                                  }
                                />
                                {formErrors && formErrors.discountValue && (
                                  <span className="text-danger valiadtion">
                                    {formErrors.discountValue}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row mt-3 p-md-1">
                          {" "}
                          {Array.from({
                            length:
                              additionalFieldsCount[supplier?.supplierId] || 0,
                          }).map((_, i) => {
                            const lastIndex =
                              supplier.price_calculation?.length - 1;
                            const newIndex = lastIndex + i + 1;
                            if (
                              supplier.price_calculation &&
                              supplier.price_calculation.length > 0
                            ) {
                              return (
                                <div key={newIndex}>
                                  <>
                                    <div className="row mt-3 p-md-1">
                                      <div className="col-sm-3 col-md-4 col-lg-2">
                                        <div style={{ marginLeft: "12px" }}>
                                          <label htmlFor={`price-${index}`}>
                                            Select Price
                                          </label>
                                          <Select
                                            options={price}
                                            onChange={(selectedOption) =>
                                              handleSelectChange(
                                                selectedOption,
                                                supplier.supplierId,
                                                newIndex
                                              )
                                            }
                                            placeholder="Select"
                                            name="costPriceField"
                                            className="temp"
                                            menuPlacement="top"
                                            styles={{
                                              option: (styles) => {
                                                return {
                                                  ...styles,
                                                  color: "#545454",
                                                  fontSize: "14px",
                                                };
                                              },
                                            }}
                                          />
                                          {formErrors.costPriceField && (
                                            <span className="text-danger validation">
                                              {formErrors.costPriceField}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
                                        <div className="form-group">
                                          <label htmlFor="value2">Min</label>
                                          <input
                                            id="value7"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="minimumValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                newIndex
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.minimumValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.minimumValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
                                        <div className="form-group">
                                          <label htmlFor="value2">Max</label>
                                          <input
                                            id="value8"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="maximumValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                newIndex
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.maximumValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.maximumValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
                                        <div className="form-group">
                                          <label htmlFor="value2">
                                            Multiple
                                          </label>
                                          <input
                                            id="value9"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="multipleValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                newIndex
                                              )
                                            }
                                            style={{ widht: "30vw" }}
                                          />

                                          {formErrors &&
                                            formErrors.multipleValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.multipleValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
                                        <div className="form-group">
                                          <label htmlFor="value2">Fixed</label>
                                          <input
                                            id="value10"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="fixedValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                newIndex
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.fixedValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.fixedValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
                                        <div className="form-group">
                                          <label htmlFor="value2">Tax</label>
                                          <input
                                            id="value11"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="taxValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                newIndex
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.taxValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.taxValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
                                        <div className="form-group">
                                          <label htmlFor="value2">
                                            Discount
                                          </label>
                                          <input
                                            id="value12"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="discountValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                newIndex
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.discountValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.discountValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-1">
                                        <DeleteForeverIcon
                                          className="delete-icon"
                                          onClick={() =>
                                            handleDeleteField1(
                                              supplier.supplierId,
                                              newIndex
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                </div>
                              );
                            } else {
                              return (
                                <div key={i}>
                                  <>
                                    <div className="row mt-3 p-md-1">
                                      <div className="col-sm-3 col-md-4 col-lg-2">
                                        <div style={{ marginLeft: "12px" }}>
                                          <label htmlFor={`price-${index}`}>
                                            Select Price
                                          </label>
                                          <Select
                                            options={price}
                                            onChange={(selectedOption) =>
                                              handleSelectChange(
                                                selectedOption,
                                                supplier.supplierId,
                                                i + 1
                                              )
                                            }
                                            placeholder="Select"
                                            name="costPriceField"
                                            className="temp"
                                            menuPlacement="top"
                                            styles={{
                                              option: (styles) => {
                                                return {
                                                  ...styles,
                                                  color: "#545454",
                                                  fontSize: "14px",
                                                };
                                              },
                                            }}
                                          />
                                          {formErrors.costPriceField && (
                                            <span className="text-danger validation">
                                              {formErrors.costPriceField}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
                                        <div className="form-group">
                                          <label htmlFor="value2">Min</label>
                                          <input
                                            id="value7"
                                            className="form-control price__input__height min"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="minimumValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                i + 1
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.minimumValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.minimumValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
                                        <div className="form-group">
                                          <label htmlFor="value2">Max</label>
                                          <input
                                            id="value8"
                                            className="form-control price__input__height min"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="maximumValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                i + 1
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.maximumValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.maximumValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
                                        <div className="form-group">
                                          <label htmlFor="value2">
                                            Multiple
                                          </label>
                                          <input
                                            id="value9"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="multipleValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                i + 1
                                              )
                                            }
                                            style={{ widht: "30vw" }}
                                          />

                                          {formErrors &&
                                            formErrors.multipleValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.multipleValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
                                        <div className="form-group">
                                          <label htmlFor="value2">Fixed</label>
                                          <input
                                            id="value10"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="fixedValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                i + 1
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.fixedValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.fixedValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
                                        <div className="form-group">
                                          <label htmlFor="value2">Tax</label>
                                          <input
                                            id="value11"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="taxValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                i + 1
                                              )
                                            }
                                          />
                                          {formErrors &&
                                            formErrors.taxValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.taxValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
                                        <div className="form-group">
                                          <label htmlFor="value2">
                                            Discount
                                          </label>
                                          <input
                                            id="value12"
                                            className="form-control price__input__height"
                                            placeholder="Enter Value"
                                            type="number"
                                            step="any"
                                            name="discountValue"
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                supplier.supplierId,
                                                i + 1
                                              )
                                            }
                                            style={{ width: "7vw" }}
                                          />
                                          {formErrors &&
                                            formErrors.discountValue && (
                                              <span className="text-danger valiadtion">
                                                {formErrors.discountValue}
                                              </span>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-sm-6 col-lg-1">
                                        <DeleteForeverIcon
                                          onClick={() =>
                                            handleDeleteField1(
                                              supplier.supplierId,
                                              i + 1
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#c90e0e",
                                            marginLeft: "12px",
                                            marginTop: "41px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <label
                          htmlFor="roundupRadio mt-3"
                          style={{ padding: "0 10px" }}
                        >
                          Round Up Amount
                        </label>

                        {radioBtns.map((option, idx) => (
                          <>
                            <div className="row px-2" key={idx}>
                              <div className="col-12 mt-0">
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    value={option.value}
                                    name={`roundUp-${index}`}
                                    id={`roundupRadio-${index}-${idx}`}
                                    onChange={(e) =>
                                      handleRadioChange(e, index)
                                    }
                                    checked={
                                      selectedRadioOption[index] ===
                                      option.value
                                    }
                                  />
                                  <label className="radio-inline text-dark px-2">
                                    {option.label}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                        <div className="col-lg-12">
                          {selectedRadioOption[index] && supplier && (
                            <div className="col-lg-12 text-success">
                              {selectedRadioOption[index] === "decimal" ? (
                                <div>
                                  {supplier.supplierName}e.g. Price = 17.4,
                                  Output = 18
                                </div>
                              ) : (
                                <div>
                                  {supplier.supplierName}e.g. Price = 17, Output
                                  = 20
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = ({ LoadingReducer, loginReducer }) => ({
  loading: LoadingReducer.isLoading,
  user: loginReducer.user,
});
export default connect(mapStateToProps, { onLoading })(PriceCalculation);
