const API_BASE_URL = process.env.REACT_APP_API_URL_SUPPLIER;
const PRODUCT_API_URL = process.env.REACT_APP_API_URL_PRODUCT;
const API_LOG = process.env.REACT_APP_URL_API_LOG;
const API_RETAILER_URL = process.env.REACT_APP_RETAILER_SERVICE;
const API_DASHBOARD = process.env.REACT_APP_URL_DASHBOARD;
const API_USER = process.env.REACT_APP_USER_SERVICE;
const FILE_UPLOAD = process.env.REACT_APP_URL_FILE_UPLOAD;
const NEXT_ORDER = process.env.REACT_APP_API_URL_ORDER;
const PROMPT = process.env.REACT_APP_URL_PROMPT

export const API_PATH = {
  GET_DASHBOARD_DATA: `${API_DASHBOARD}/dashboard/get_dashboard`,
  UPDATE_USER_PROFILE: `${API_USER}/user/update`,
  FETCH_USER_PROFILE_DETAILS: `${API_USER}/user`,
  CHANGE_PASSWORD: `${API_USER}/user/change-password`,

  GET_LIST: `${API_BASE_URL}/integration/getIntegrationInfo`,
  GET_LIST_BY_NAME: `${API_BASE_URL}/integration/getIntegrationName`,
  CREATE_INTEGRATION_INFO: `${API_BASE_URL}/integration/createIntegrationInfo`,
  UPDATE_INTEGRATION_INFO: `${API_BASE_URL}/integration/updateIntegrationInfo`,
  GET_INTEGRATION_INFO_BY_ID: `${API_BASE_URL}/integration/getIntegrationInfoById?supplierId`,
  ADD_CSV_DATA: `${API_BASE_URL}/csv/storeCSVdata`,
  DATA_FILE_MAPPING: `${API_BASE_URL}/integration/createOrUpdateSupplierFields`,
  DELETE_CUSTOM_FIELD: `${API_BASE_URL}/integration/deleteCustomField`,
  IMAGE_RESIZE: `${API_BASE_URL}/integration/createOrUpdateSupplierImageResize`,
  IMPORT_SETTING: `${API_BASE_URL}/integration/createOrUpdateSupplierImprortSetting`,
  BARCODE: `${API_BASE_URL}/integration/barcode`,
  GET_CRON_TIME: `${API_BASE_URL}/general/getCronTime`,
  GET_IMPORT_SETTING_DATA_BY_ID: `${API_BASE_URL}/Integration/getSupplierImprortSetting?supplierId`,
  GET_CATEGORY_MAPPING: `${API_BASE_URL}/integration/getCategoryFields`,
  CREATE_CATEGORY_MAPPING: `${API_BASE_URL}/integration/createOrUpdateAzuraMysaleCategoryMapping`,
  MARKET_PLACE_SYNCSETTING: `${API_BASE_URL}/integration/createOrUpdateMarketplaceIntegratorSyncSetting`,
  GET_SYNC_SETTING: `${API_BASE_URL}/integration/getMarketplaceIntegratorSyncSetting`,
  CHANGE_SUPPLIER_STATUS: `${API_BASE_URL}/integration/changeIntegrationStatus`,
  GET_SUPPLIER_FILE_MAPPING: `${API_BASE_URL}/Integration/getSupplierFields?supplierId`,
  GET_ALL_IMAGE_SIZES: `${API_BASE_URL}/integration/getAllImageSize`,

  GET_PRODUCT_CATALOG: `${PRODUCT_API_URL}/product/getStanderdProductCatalog`,
  GET_FILE_UPLOAD: `${PRODUCT_API_URL}/product/getFileList`,
  GET_PRODUCT_LIST: `${PRODUCT_API_URL}/product/getProductList`,
  GET_VARIANT_LIST: `${PRODUCT_API_URL}/product/getVariantList`,
  GET_PRODUCT_LIST_BY_ID: `${PRODUCT_API_URL}/product/getProductByID`,
  GET_VARIANT_LIST_BY_ID: `${PRODUCT_API_URL}/product/getVariantByID`,
  FILE_UPLOAD: `${FILE_UPLOAD}/api/uploadcsv`,
  UPDATE_PRODUCT_DATA: `${PRODUCT_API_URL}/product/updateProductById`,
  DELETE_PRODUCT_DATA: `${PRODUCT_API_URL}/product/deleteProductVariantCustomField`,

  GET_API_LOG: `${API_LOG}/apiLog/getAPILogs`,
  GET_API_LOG_DETAILS: `${API_LOG}/apiLog/getAPIDetails`,
  GET_ORDER_LOG: `${API_LOG}/apiLog/getOrderLogs`,
  GET_ORDER_LOG_DETAILS: `${API_LOG}/apiLog/getOrderLogDetails`,

  GET_ACCOUNT: `${API_RETAILER_URL}/getRetailerIntegrationById`,
  CREAT_ACCOUNT_CONFIGURATION: `${API_RETAILER_URL}/createOrUpdateRetailerAccountConfig`,
  GET_CURRENCY: `${API_RETAILER_URL}/getCurrency`,
  CREAT_CURRENCY: `${API_RETAILER_URL}/createOrUpdateRetailerIntegrationForCurrency`,
  GET_RETAILER_BY_ID: `${API_RETAILER_URL}/getRetailerIntegrationById`,
  GET_RETAILER_MARKETPLACE: `${API_RETAILER_URL}/getMarketplaceList`,
  CREATE_RETAILER_MARKETPLACE: `${API_RETAILER_URL}/createOrUpdateRetailerMarketplace`,
  CREATE_CSV_CONFIGURATION: `${API_RETAILER_URL}/createOrUpdateRetailerExportCSVConfig`,
  CREATE_NEXT_CSV_CONFIGURATION: `${API_RETAILER_URL}/createOrUpdateRetailerNextConfig`,
  GET_RETAILER_PRICE_LIST: `${API_RETAILER_URL}/getPriceList`,
  CREATE_RETAILER_PRICE: `${API_RETAILER_URL}/createOrUpdateRetailerPriceCalculation`,
  GET_RETAILER_PRODUCT: `${API_RETAILER_URL}/getSupplierProduct`,
  CREATE_RETAILER_CATEGORY: `${API_RETAILER_URL}/createOrUpdateRetailerCategory`,
  CREATE_RETAILER_IMAGE: `${API_RETAILER_URL}/createOrUpdateRetailerImage`,
  GET_RETAILER_IMAGE_LIST: `${API_RETAILER_URL}/getSupplierImageList`,
  GET_RETAILER_SUPPLIER_LIST: `${API_RETAILER_URL}/getSupplierList`,
  CREATE_RETAILER_SUPPLIER: `${API_RETAILER_URL}/createOrUpdateRetailerIntegration`,
  GET_RETAILER_INTEGRATION_LIST: `${API_RETAILER_URL}/getRetailerIntegrationList`,
  CHANGE_STATUS: `${API_RETAILER_URL}/changeRetailerIntegrationStatus`,
  DELETE_PRICE_CALCLATION_ROW: `${API_RETAILER_URL}/deleteRetailerPriceCalculation`,
  CREATE_MIINTO_CURRENCY_DATA: `${API_RETAILER_URL}/createOrUpdateMiintoPriceCalculation`,
  GET_MIINTO_CURRENCY_LIST: `${API_RETAILER_URL}/getAllMiintoCurrency`,
  GET_MIINTO_CURRENCY_DATA: `${API_RETAILER_URL}/getMiintoPriceCalculation`,
  DELETE_MIINTO_CURRENCY_DATA: `${API_RETAILER_URL}/deleteMiintoPriceCalculation`,

  API_LOGS: `${API_RETAILER_URL}/changeRetailerIntegrationStatus`,
  EXPORT_DATA_LOGS: `${API_RETAILER_URL}/getExportLogs`,

  NEXT_SEARCH_ORDER: `${NEXT_ORDER}/v1/orders/searchOrder`,
  NEXT_CANCEL_ORDER: `${NEXT_ORDER}/v1/orders/cancelOrder`,

  // prompt
  ADD_OR_EDIT_PROMPT: `${PROMPT}/prompt/createOrUpdatePromptKey`,
  GET_PROMPT_KEY_LIST: `${PROMPT}/prompt/getAllPromptKey`,
  GET_PROMPT_KEY_BY_ID: `${PROMPT}/prompt/getPromptKeyById`,
  CHANGE_PROMPT_STATUS: `${PROMPT}/prompt/changePromptKeyStatus`,
  GET_PRODUCT_ATTRIBUTES: `${PROMPT}/prompt/getAzuraProductAttribute`,
  GET_ALL_PROMPT_KEY_LIST: `${PROMPT}/prompt/getAllPromptList`,

  // Gemini prompt API's
  VALIDATE_PROMPT: `${PROMPT}/prompt/validateGeminiPrompt`,
  ADD_PROMPT_VALUE: `${PROMPT}/prompt/createOrUpdateBasicGeminiPrompt`,
  GET_PROMTPT_VAL_BY_ID: `${PROMPT}/prompt/getPromptById`,
  GET_ALL_PROMPT_LIST: `${PROMPT}/prompt/getAllPrompt`,
  VLAIDATE_PROMPT: `${PROMPT}/prompt/validateGeminiPrompt`,
  GET_GEMINI_PRODUCT_ATTRIBUTES: `${PROMPT}/prompt/getGeminiProductAttribute`,
  ADD_OR_EDIT_PROMPT_MAPPING: `${PROMPT}/prompt/createOrUpdateMappingGeminiPrompt`,
  CHANGE_ADD_PROMPT_STATUS: `${PROMPT}/prompt/changePromptStatus`,
  GET_PROMPT_HISTORY_LIST: `${PROMPT}/prompt/getAllPromptLog`,
  GET_PROMPT_HISTORY_LOG_BY_ID:`${PROMPT}/prompt/getPromptLogById`,

  //Gpt4 prompt API's
  VALIDATE_GPT_PROMPT: `${PROMPT}/prompt/validateGPT4Prompt`,
  ADD_GPT_PROMPT_VALUE: `${PROMPT}/prompt/createOrUpdateBasicGPT4Prompt`,
  ADD_OR_EDIT_GPT_PROMPT_MAPPING: `${PROMPT}/prompt/createOrUpdateMappingGPT4Prompt`,





  

};
