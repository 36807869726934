import React from 'react';
import image404 from '../../assets/images/404.svg'

class Private404 extends React.Component {
    render() {
        return (
            <div style={{ width: '40%', margin: 'auto' }}>
                <img src={image404} />
            </div >
        );
    }
}

export default Private404;
