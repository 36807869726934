import React, { useState, useEffect } from "react";
import axios from "axios";
import "../PromptSetting/PromptKeysList.css";
import { onLoading } from "../../actions";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import {  useLocation } from "react-router-dom";
import { API_PATH } from "../ApiPath/Apipath";
import formatDateToMMDDYYYYhhmma from "../FormatedDate/FormatedDate";
import GeminiPromptHistory from "../ManagePrompt/GeminiPromptHistory";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const tooltip = (
  <Tooltip id="tooltip">
    <strong>Prompt Log</strong>
  </Tooltip>
);

function PromptHistory(props) {
  const location = useLocation();
  const supplierId = location.state?.supplierId || null;
  const [promptList, setPromptList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [dataLimit, setdataLimit] = useState(10);
  const [showGeminiModal, setShowGeminiModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const getPromptInfo = async () => {
    props.onLoading(true);
    try {
      const response = await axios.post(`${API_PATH.GET_PROMPT_HISTORY_LIST}`, {
        supplierId: supplierId,
        page: currentPage,
        limit: dataLimit,
      });

      if (response.data.success) {
        setPromptList(response.data.data);
        setTotalPages(Math.ceil(response.data.totalRecord / dataLimit));
      } else {
        setPromptList([]);
      }
      props.onLoading(false);
    } catch (error) {
      console.log("error", error);
      props.onLoading(false);
    }
  };

  useEffect(() => {
    getPromptInfo();
  }, [supplierId, currentPage, dataLimit]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const openModal = (id) => {
    setSelectedId(id);
    setShowGeminiModal(true);
  };

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Prompt History"
            Breadcrumb={[
              {
                name: "Prompt List",
                navigate: "/prompt-list",
              },
              { name: "Prompt History", navigate: "#" },
            ]}
            style={{ position: "sticky", top: 0, zIndex: 999 }}
          />
         
          <div className="tab-component">
            <div className="card">
              <div className="body">
              <div className="d-flex justify-content-end align-items-center mb-3">
              
              <Link className="link-btn" to={`/prompt-list`}>
             Back
              </Link>
            </div>
                <div className="data-table">
                  {props.loading ? (
                    <div className="loader-wrapper">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : null}
                  <table className="table w-100 table-responsive-lg">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Prefix</th>
                        <th>Name</th>
                        <th>Prompt Type</th>
                        <th>Updated By</th>
                        <th>Last Update(UTC)</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promptList.map((prompt, index) => (
                        <>
                          <tr className="custom-border-table">
                            <td>{prompt.id}</td>
                            <td>{prompt.prefixName}</td>
                            <td>{prompt.supplierName}</td>
                            <td>{prompt.PromptType}</td>
                            <td>{prompt.email}</td>
                            <td>
                              {prompt.updatedAt
                                ? formatDateToMMDDYYYYhhmma(prompt.updatedAt)
                                : formatDateToMMDDYYYYhhmma(prompt.createdAt)}
                            </td>
                            <td className="action-group">{" "}
                              {"    "}
                              <OverlayTrigger placement="top" overlay={tooltip}>
                              <i
                              data-placement="top"
                              title="Edit"
                              className="fa fa-eye"
                              onClick={() => openModal(prompt.id)}
                              style={{ color: "#49c5b6" }}
                              ></i>
                            </OverlayTrigger>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  {promptList?.length === 0 && (
                    <h4
                      className="no-data"
                      style={{ color: props.loading ? "white" : "#8b8a8a" }}
                    >
                      No Data Found
                    </h4>
                  )}
                  {promptList?.length > 0 && (
                    <div className="pagination-wrapper">
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            &laquo;
                          </button>
                        </li>
                        {[...Array(totalPages)].map((_, index) => (
                          <li
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            key={index + 1}
                          >
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          >
                            &raquo;
                          </button>
                        </li>
                      </ul>
                      <select
                        name="companyOwner"
                        className="form-control"
                        onChange={(e) => {
                          setCurrentPage(1);
                          setdataLimit(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GeminiPromptHistory
        showGeminiModal={showGeminiModal}
        setShowGeminiModal={setShowGeminiModal}
        selectedId={selectedId}
        loading={props.loading}
      />
    </div>
  );
}
const mapStateToProps = ({ LoadingReducer, loginReducer }) => ({
  loading: LoadingReducer.isLoading,
  user: loginReducer.user,
});
export default connect(mapStateToProps, { onLoading })(PromptHistory);
