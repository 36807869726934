import React, { useContext, useEffect, useState } from "react";
import "./PromptKey.css";
import { connect } from "react-redux";
import { onLoading } from "../../actions";
import { Spinner } from "react-bootstrap";
import AddGpt4PromptJsx from "./AddGpt4PromptJsx";
import { API_PATH } from "../ApiPath/Apipath";
import axios from "axios";
import { toast } from "react-toastify";
import { promptGuideLines } from "../utils/constants";
import PromptGuidelineMode from "./PromptGuidelineMode";
import { useHistory } from "react-router-dom"

function Gpt4Prompt(props) {
  const { setPage } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [systemPromptValue, setSystemPromptValue] = useState("");
  const [oldSystemPromptValue, setOldSystemPromptValue] = useState("");
  const [promptValue, setPromptValue] = useState("");
  const [oldPromptValue, setOldPromptValue] = useState("");
  const [isPromptValid, setIsPromptValid] = useState(false);
  const [productAttributes, setProductAttributes] = useState([]);
  const [registeredPromptKeys, setRegisteredPromptKeys] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isAdditionalKeysChecked, setIsAdditionalKeysChecked] = useState(false);
  const history = useHistory()

  useEffect(() => {
    fetchProductAttributes();
    fetchRegisteredPromptKeys();
    getPromptDataById()
  }, []);

  const handleCheckboxChange = (e) => {
    setIsAdditionalKeysChecked(e.target.checked);
    // Reset validation state if checkbox is unchecked
    if (!e.target.checked) {
      setIsPromptValid(false);
    }
  };

  const handlePromptChange = (e) => {
    setPromptValue(e.target.value);
  };
  const handleSystemPromptChange = (e) => {
    setSystemPromptValue(e.target.value);
  };

  const getPromptDataById = () => {
    const promptKeyId = localStorage.getItem("addPromptId");
    setFormLoader(true);
    axios
      .post(`${API_PATH.GET_PROMTPT_VAL_BY_ID}`, {
        id: promptKeyId,
      })
      .then((response) => {
        const promptData = response.data.data;
        if (promptData) {

          // Set system and prompt values only if they exist
          if (promptData.GPT4oPrompt) {
            setPromptValue(promptData.GPT4oPrompt);
            setOldPromptValue(promptData.GPT4oPrompt);
          }

          if (promptData.GPT4oSystemPrompt) {
            setSystemPromptValue(promptData.GPT4oSystemPrompt);  // Set system prompt
            setOldSystemPromptValue(promptData.GPT4oSystemPrompt);
          }

          // Set the state based on the presence of valid prompt values
          const isNonEmptyPrompt = promptData.GPT4oPrompt?.trim() !== "";
          setIsAdditionalKeysChecked(isNonEmptyPrompt);
          setIsPromptValid(isNonEmptyPrompt && isAdditionalKeysChecked);
        } else {
          console.error("Prompt data is undefined or null");
        }
      })
      .catch((error) => {
        console.error("error", error);
      })
      .finally(() => {
        setFormLoader(false);
      });
  };

  const fetchProductAttributes = () => {
    axios
      .post(API_PATH.GET_PRODUCT_ATTRIBUTES)
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          setProductAttributes(data);
        } else {
          console.error("Failed to fetch product attributes");
        }
      })
      .catch((error) => {
        console.error("Error fetching product attributes:", error);
      });
  };

  const fetchRegisteredPromptKeys = () => {
    axios
      .get(API_PATH.GET_ALL_PROMPT_KEY_LIST)
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          setRegisteredPromptKeys(data.map((item) => item.prompt_key));
        } else {
          toast.error("Failed to fetch registered prompt keys");
        }
      })
      .catch((error) => {
        console.error("Error fetching registered prompt keys:", error);
      });
  };

  const parsePromptValue = (text) => {
    // Normalize line breaks
    text = text.replace(/\r\n/g, "\n");
    // Split text by lines and commas
    const lines = text.split(/[\n,-]/);
    const attributes = {};
    const attributeRegex = /^\s*([\w\s\/,&-]+):/;

    lines.forEach((line) => {
      // Trim and clean each line
      const cleanLine = line.trim();
      const match = cleanLine.match(attributeRegex);
      if (match) {
        // Normalize the attribute name
        let originalName = match[1].trim();
        let normalizedName = originalName
          .replace(/\s+/g, "_") // Replace spaces with underscores
          .replace(/[\/,&-]/g, "") // Remove /, &, and -
          .toLowerCase();

        normalizedName = normalizedName
          .split("_")
          .map(
            (word) => capitalizeTerm(word) // Capitalize certain terms
          )
          .join("_");
        attributes[normalizedName] = originalName;
      }
    });

    return attributes;
  };

  const normalizeString = (str) => {
    let normalizedName = str
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .replace(/[\/,&-]/g, "") // Remove /, &, and -
      .replace(/__+/g, "_") // Replace multiple underscores with a single underscore
      .replace(/_+$/, "") // Remove trailing underscores
      .toLowerCase();

    normalizedName = normalizedName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("_");

    return normalizedName;
  };

  const validatePromptValue = async () => {
    const parsedAttributes = parsePromptValue(promptValue);

    const hasColon = promptValue.includes(":");

    if (!hasColon) {
      setErrorMessage(
        "When entering prompts, please ensure to include a colon after each attribute, for example color:red"
      );
      // clearErrorMessageAfterDelay();
      return;
    }

    const missingAttributes = [];
    const normalizedRegisteredKeys = registeredPromptKeys.map(normalizeString);

    // Check for missing attributes
    for (const key in parsedAttributes) {
      const normalizedKey = normalizeString(key);
      if (!normalizedRegisteredKeys.includes(normalizedKey)) {
        missingAttributes.push(key); // Add original attribute name
      }
    }

    if (missingAttributes.length > 0) {
      const normalizedMissingAttributes =
        missingAttributes.map(normalizeString); // Normalize missing attribute names
      const formattedMissingAttributes = normalizedMissingAttributes.join(", "); // Concatenate missing attributes with commas

      const message = `In this prompt, the attribute${missingAttributes.length > 1 ? "s" : ""
        } ${formattedMissingAttributes} ${missingAttributes.length > 1 ? "are" : "is"
        } missing. Please register this/these attribute(s) in the Prompt Key section. <a href="/prompt-keys"><u>Click Here</u></a>`;
      setErrorMessage(message);
      // clearErrorMessageAfterDelay();
    } else {
      setIsLoading(true);
      // Prepare the Gpt 4o prompt string
      const gptPrompt = Object.entries(parsedAttributes)
        .map(([key, value]) => `${key}:${value}`)
        .join(", ");
      try {
        // Make a POST request to the API for validation
        const response = await axios.post(API_PATH.VALIDATE_GPT_PROMPT, {
          gpt4_prompt: gptPrompt,
        });

        if (response.data.success) {
          setIsPromptValid(true);
          toast.success(response.data.message || "Prompt is valid!");
          setIsLoading(false);
        } else {
          setIsPromptValid(false);
          toast.error(response.data.message || "Failed to validate prompt");
          setIsLoading(false);
        }
      } catch (error) {
        setIsPromptValid(false);
        setIsLoading(false);
        toast.error("Failed to validate prompt");
        console.error("Error validating prompt:", error);
      }
    }
  };
  function capitalizeTerm(term) {
    const standardTerms = ["SKU", "ASIN", "GTIN", "ISBN", "EAN", "UPC", "SEO"];
    const termParts = term.split("_");
    return termParts
      .map((part) => {
        const upperPart = part.toUpperCase();
        if (standardTerms.includes(upperPart)) {
          return upperPart;
        }
        // Capitalize the first letter and lowercase the rest
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
      })
      .join("_");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isAdditionalKeysChecked && promptValue.trim() === "") {
      toast.error("Prompt data is required."); // Show error toast
      return; // Prevent form submission if empty
    }
    try {
      setIsLoadingExit(true);

      const payload = {
        id: localStorage.getItem("addPromptId") || "",
        supplierId: localStorage.getItem("supplierId"),
        email: localStorage.getItem("email"),
        old_gpt4_prompt: oldPromptValue,
        gpt4_prompt: promptValue,
        old_gpt4_system_prompt: oldSystemPromptValue,
        gpt4_system_prompt: systemPromptValue,
      };

      const response = await axios.post(API_PATH.ADD_GPT_PROMPT_VALUE, payload);
      if (response.data.success === true) {
        if (isAdditionalKeysChecked) {
          setPage("4");
          setIsLoadingExit(false);
          toast.success(response.data.message);
        } else {
          history.push("/prompt-list");
          localStorage.removeItem("supplierId");
          localStorage.removeItem("addPromptId");
          localStorage.removeItem("currentPage");
        }
      } else {
        setIsLoadingExit(false);
        if (isAdditionalKeysChecked) {
          toast.error("Prompt data is required.");
        } else {
          history.push("/prompt-list");
          localStorage.removeItem("supplierId");
          localStorage.removeItem("addPromptId");
          localStorage.removeItem("currentPage");
        }
      }
    } catch (error) {
      setIsLoadingExit(false);
      if (isAdditionalKeysChecked) {
        toast.error("Failed to save prompt details");
      }
      console.error("Error saving prompt details:", error);
    }
  };


  const handleCopyToClipboard = async (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(`{${text}}`);
      // toast.success("Copied to clipboard!");
    } else {
      const tempInput = document.createElement("input");
      const textWithCurlyBraces = `{${text}}`;
      tempInput.value = textWithCurlyBraces;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      // toast.success("Copied to clipboard!");
    }
  };
  const calculateTextAreaHeight = () => {
    const minHeightPerRow = 40;
    const keyCount = registeredPromptKeys.length;
    let height = Math.max(minHeightPerRow * keyCount, minHeightPerRow * 12);
    return `${height}px`;
  };
  const handleCloseErrorMessage = () => {
    setErrorMessage("");
  };
  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <form name="myForm" encType="multipart/form-data" onSubmit={handleSubmit}>
        <div style={{ marginTop: "15px" }}>
          <div
            className="alert alert-primary col-12 mt-3 d-flex justify-content-between align-items-center"
            role="alert"
          >
            <div>
              <strong>INFO:</strong> &nbsp; {"    "}
              {promptGuideLines}<br /><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              1. If you want to pass any additional attributes in the GT4o prompt that are not mentioned in the Gemini prompt. <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Enable the checkbox and add the new attribute.<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              2. If the attribute is already passed in the Gemini prompt, you do not need to pass it here.<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              3. If you do not want to pass any additional attributes, click Save & Exit.
            </div>
            <button
              className="btn btn-primary w-auto btn-lg button-class"
              type="button"
              onClick={() => openModal()}
            >
              Prompt Guidelines
            </button>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 button-class">
              <div className="d-flex">
                <button
                  className="btn btn-primary w-auto btn-lg mr-2"
                  type="button"
                  onClick={validatePromptValue}
                  disabled={!isAdditionalKeysChecked}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    "Validate Prompt"
                  )}
                </button>
                <button
                  className="btn btn-primary w-auto btn-lg mr-2"
                  type="submit"
                  disabled={!isPromptValid && isAdditionalKeysChecked}
                >
                  {isLoadingExit ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    isAdditionalKeysChecked ? "Save & Next" : "Save & Exit"
                  )}
                </button>
              </div>
            </div>
          </div>
          {formLoader && (
            <div className="loader-wrapper">
              <i className="fa fa-refresh fa-spin"></i>
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger col-12 mt-3" role="alert">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseErrorMessage}
                style={{
                  position: "absolute",
                  top: "0",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  fontSize: "1.5rem",
                  lineHeight: "1",
                  color: "#000",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
              <strong>Please Verify It: </strong>
              <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
            </div>
          )}
          <div className="form-check mb-3 mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              checked={isAdditionalKeysChecked}
              onChange={handleCheckboxChange}
              id="additionalAttributeKeys"
            />
            <label className="form-check-label " htmlFor="additionalAttributeKeys" style={{ color: "#49c5b6" }}>
              Would you like to add any additional attribute keys for GPT-4, aside from those already used in the Gemini prompt?
            </label>
          </div>
          <AddGpt4PromptJsx
            props={props}
            productAttributes={productAttributes}
            promptValue={promptValue}
            systemPromptValue={systemPromptValue}
            handlePromptChange={handlePromptChange}
            handleSystemPromptChange={handleSystemPromptChange}
            calculateTextAreaHeight={calculateTextAreaHeight}
            registeredPromptKeys={registeredPromptKeys}
            handleCopyToClipboard={handleCopyToClipboard}
            isTableDisabled={!isAdditionalKeysChecked}

          />
        </div>
        <PromptGuidelineMode
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </form>
    </div>
  );
}
const mapStateToProps = ({ LoadingReducer }) => ({
  isLoading: LoadingReducer.isLoading,
});
export default connect(mapStateToProps, { onLoading })(Gpt4Prompt);
