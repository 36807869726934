import React from 'react';
import { Alert } from 'react-bootstrap';


const CompanyChangelog = () => {

    return (
        <div>
            <Alert variant='secondary'>Change Log</Alert>
        </div>
    );
}

export default CompanyChangelog;
