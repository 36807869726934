import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { connect } from "react-redux";
import { onLoading } from "../../actions";
import "./ExportCsv.css";
import axios from "axios";
import { API_PATH } from "../ApiPath/Apipath";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function ExportCsv(props) {
  const [search, setSearch] = useState("");
  const [userRole, setUserRole] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isParentCheckboxChecked, setIsParentCheckboxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (search) => {
    setSearch(search);
  };

  useEffect(() => {
    const user = props.user.data;
    setUserRole(user.role);
    if (search === "") {
      setSearchResults([]);
    }
  }, [search]);

  const handleParentCheckboxChange = () => {
    if (isParentCheckboxChecked) {
      setSelectedItems([]);
    } else {
      const allItemIds = searchResults?.map((item) => item.id) || [];
      setSelectedItems(allItemIds);
    }
    setIsParentCheckboxChecked((prevState) => !prevState);
  };

  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item.id)) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((itemId) => itemId !== item.id)
      );
    } else {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item.id]);
    }
  };

  const handleSearchClick = async () => {
    if (search === "") {
      return toast.error("Please enter OrderId or ItemId.");
    }
    try {
      props.onLoading(true);
      const response = await axios.post(`${API_PATH.NEXT_SEARCH_ORDER}`, {
        searchId: search,
      });

      if (response.data.status === true) {
        if (response.data.data.Items) {
          // If searching by OrderID
          setSearchResults(response.data.data.Items);
          props.onLoading(false);
        } else if (response.data.data.Item) {
          // If searching by ItemID
          setSearchResults([response.data.data.Item]);
          props.onLoading(false);
        } else {
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      props.onLoading(false);
      setSearchResults([]);
    }
  };

  // const handleCancelOrder = async (e) => {
  //   e.preventDefault();
  //   if (searchResults === null || selectedItems.length === 0) {
  //     return toast.error("Please select any Order first");
  //   }
  //   try {
  //     setIsLoading(true);
  //     if (e.target.innerText === "Submit") {
  //       document.getElementById("btn").style.width = "150px";
  //     }
  //     for (const itemId of selectedItems) {
  //       const item = searchResults.find((item) => item.id === itemId);
  //       if (item) {
  //         const { OrderId, ItemID } = item;
  //         const response = await axios.post(`${API_PATH.NEXT_CANCEL_ORDER}`, {
  //           OrderId,
  //           ItemID,
  //         });

  //         const successMessage = response.data.message;
  //         toast.success(successMessage);
  //       }
  //     }
  //     setSearchResults((prevSearchResults) =>
  //       prevSearchResults.filter((item) => !selectedItems.includes(item.id))
  //     );

  //     setSelectedItems([]);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     document.getElementById("btn").style.width = "100px";
  //     setIsLoading(false);
  //   }
  // };

  const handleCancelOrder = async (e) => {
    e.preventDefault();
    if (searchResults === null || selectedItems.length === 0) {
      return toast.error("Please select any Order first");
    }
    try {
      setIsLoading(true);
      if (e.target.innerText === "Submit") {
        document.getElementById("btn").style.width = "150px";
      }

      const itemIds = selectedItems
        .map((itemId) => {
          const item = searchResults.find((item) => item.id === itemId);
          return item ? item.ItemID : null;
        })
        .filter((itemId) => itemId !== null)
        .join(",");

      const { OrderId } = searchResults.find(
        (item) => item.id === selectedItems[0]
      );

      const response = await axios.post(`${API_PATH.NEXT_CANCEL_ORDER}`, {
        OrderId,
        ItemID: itemIds,
      });

      const successMessage = response.data.message;
      toast.success(successMessage);

      setSearchResults((prevSearchResults) =>
        prevSearchResults.filter((item) => !selectedItems.includes(item.id))
      );

      setSelectedItems([]);
    } catch (error) {
      console.error(error);
    } finally {
      document.getElementById("btn").style.width = "100px";
      setIsLoading(false);
    }
  };

  const hasSearchResults = searchResults && searchResults.length > 0;

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Cancel Order"
            Breadcrumb={[{ name: "Cancel Order", navigate: "#" }]}
          />
          <div className="tab-component">
            <div className="card">
              <div className="body">
                <div className="mb-3 csv__header">
                  <span>Cancel Item</span>
                  <input
                    type="search"
                    placeholder="Search by Order Id, Item Id..."
                    className="search__input"
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchClick();
                      }
                    }}
                  />
                  <button onClick={handleSearchClick} className="search__btn">
                    Search
                  </button>
                </div>
                {/*  {hasSearchResults ? (
              <> */}
                <div className="d-flex justify-content-end">
                  {" "}
                  <button
                    className="submit__btn btn-primary rounded-lg"
                    type="submit"
                    id="btn"
                    onClick={handleCancelOrder}
                  >
                    {isLoading ? (
                      <>
                        <Spinner animation="border" size="sm" /> Please wait...
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
                <div>
                  <div className="data-table">
                    {props.loading ? (
                      <div className="loader-wrapper">
                        <i className="fa fa-refresh fa-spin"></i>
                      </div>
                    ) : null}
                    <table>
                      <thead>
                        {props.user.permissions.cancel_order ||
                        userRole === "SUPER_ADMIN" ? (
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                checked={isParentCheckboxChecked}
                                onChange={handleParentCheckboxChange}
                              />
                            </th>
                            <th>Order Id</th>
                            <th>Item Id</th>
                            <th>Quantity</th>
                            <th>Title</th>
                            <th>Azura EAN</th>
                            <th>Variant SKU</th>
                          </tr>
                        ) : null}
                      </thead>
                      <tbody>
                        {props.user.permissions.cancel_order ||
                        userRole === "SUPER_ADMIN" ? (
                          <>
                            {searchResults?.map((item) => (
                              <>
                                <tr key={item.id}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.includes(item.id)}
                                      onChange={() =>
                                        handleCheckboxChange(item)
                                      }
                                    />
                                  </td>
                                  <td>{item.OrderId}</td>
                                  <td>{item.ItemID}</td>
                                  <td>{item.Quantity}</td>
                                  <td>
                                    {item.title
                                      ? item.title?.replace(/"/g, "")
                                      : ""}
                                  </td>
                                  <td>{item.EAN}</td>
                                  <td>{item.Variant_SKU}</td>
                                </tr>
                              </>
                            ))}
                          </>
                        ) : null}
                      </tbody>
                    </table>
                    {searchResults?.length === 0 && (
                      <h4
                        className="no-data"
                        style={{
                          color: props.loading ? "white" : "#8b8a8a",
                        }}
                      >
                        No Data Found
                      </h4>
                    )}
                  </div>
                </div>
                {/*
                  </>
                ) : (
                  ""
                )}*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ LoadingReducer, loginReducer }) => ({
  loading: LoadingReducer.isLoading,
  user: loginReducer.user,
});
export default connect(mapStateToProps, { onLoading })(ExportCsv);
