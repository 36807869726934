import React, { useState } from "react";
import { connect } from "react-redux";
import { onLoading } from "../../actions";
import { useHistory } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { Spinner } from "react-bootstrap";
import "./PromptKey.css";
import axios from "axios";
import { toast } from "react-toastify";
import { testPromptGuideLine } from "../utils/constants";

function TestPrompt(props) {
  const [formData, setFormData] = useState({
    image_urls: "",
    prompt: "",
  });
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [promptResponse, setPromptResponse] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State to manage error message
  const [showError, setShowError] = useState(false); // State to manage error alert visibility
  const [fieldErrors, setFieldErrors] = useState({});

  const handleCopy = () => {
    const tempInput = document.createElement("input");
    const textWithCurlyBraces = `${formData.prompt}`;
    tempInput.value = textWithCurlyBraces;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setFieldErrors({
      ...fieldErrors,
      [e.target.name]: "", // Clear error message for the field
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formData.image_urls) {
      errors.image_urls = "URL is required";
    }

    if (!formData.prompt) {
      errors.prompt = "Prompt is required";
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    setIsLoadingExit(true);

    const payload = {
      image_urls: [formData.image_urls],
      prompt: formData.prompt,
    };

    try {
      const response = await axios.post(
        "https://azuratransformer.com/gemini/api/gemini_sample_api",
        payload
      );

      if (response.data[0].status === true) {
        const promptResponse = response.data[0].prompt_response.replace( /\n/g, "<br/>");
        setPromptResponse(promptResponse);
        toast.success("Data sent successfully");
      } else {
        setErrorMessage(response.data.message || "Error in sending data");
        setShowError(true);
        setTimeout(() => setShowError(false), 10000); // Hide alert after 10 seconds
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Error in sending data");
      setShowError(true);
      setTimeout(() => setShowError(false), 10000); // Hide alert after 10 seconds
      console.error("Error:", error);
    } finally {
      setIsLoadingExit(false);
    }
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText={"Test Prompt"}
              Breadcrumb={[
                {
                  name: "Prompt List",
                  navigate: "/prompt-list",
                  items: ["promptKeyId"],
                },
                {
                  name: "Test Prompt",
                  navigate: "#",
                },
              ]}
              className="page-header"
            />

            <div className="tab-component">
              <div className="card">
                <div className="body">
                  {props.updateFormLoading ? (
                    <div className="loader-wrapper">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : null}
                  <p className="supplier-onboarding">Test Prompt</p>
                  <form
                    name="myForm"
                    encType="multipart/form-data"
                    onSubmit={handleSubmit}
                  >
                    <div style={{ marginTop: "35px" }}>
                      <div
                        className="alert alert-primary col-12 mt-3 d-flex justify-content-between align-items-center"
                        role="alert"
                      >
                        <div>
                          <strong>INFO:</strong> <br />
                          After hitting the <b>Test</b> button, please wait a
                          few seconds. It will take 10-20 seconds to get a
                          response.
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 button-class">
                          <div className="d-flex">
                            <button
                              className="btn btn-primary w-auto btn-lg mr-2"
                              type="submit"
                            >
                              {isLoadingExit ? (
                                <>
                                  <Spinner animation="border" size="sm" />{" "}
                                  Please wait...
                                </>
                              ) : (
                                "Test"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      {showError && (
                        <div
                          className="alert alert-danger col-12 mt-3 d-flex justify-content-between align-items-center"
                          role="alert"
                        >
                          <div>{errorMessage}</div>
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseError}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )}
                      {formLoader && (
                        <div className="loader-wrapper">
                          <i className="fa fa-refresh fa-spin"></i>
                        </div>
                      )}

                      <div className="row mt-3 mt-lg-0">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>
                              URL <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="image_urls"
                              placeholder="Enter URL"
                              value={formData.image_urls}
                              onChange={handleChange}
                              style={{ borderColor: "#49c5b6" }}
                            />
                            {fieldErrors.image_urls && (
                              <div className="text-danger">{fieldErrors.image_urls}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="label-container">
                              <label>
                                Prompt <span style={{ color: "red" }}>*</span>
                              </label>
                              <i
                                className="fa fa-copy copy-icon"
                                onClick={handleCopy}
                                title="Copy Prompt"
                              ></i>
                            </div>
                            <div className="textarea-container">
                              <textarea
                                className="ai-description-textarea test-prompt"
                                type="text"
                                name="prompt"
                                placeholder="Enter Prompt"
                                value={formData.prompt}
                                onChange={handleChange}
                              ></textarea>
                              {fieldErrors.prompt && (
                                <div className="text-danger">{fieldErrors.prompt}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  {promptResponse && (
                    <div className="mt-3">
                      <h4 style={{color:"#49C5B6"}}>Response:</h4>
                      <p
                        className="prompt-response"
                        dangerouslySetInnerHTML={{ __html: promptResponse }}
                        style={{ border: "1px solid #49c5b6", padding: "10px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ LoadingReducer }) => ({
  isLoading: LoadingReducer.isLoading,
});
export default connect(mapStateToProps, { onLoading })(TestPrompt);
