import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PageHeader from "../../components/PageHeader";
import { onUpdateFormLoading, onLoading } from "../../actions";
import "./user.css";

const ManageUserPermission = (props) => {
  const [permissionValue, setPermissionValue] = useState({
    update_company: true,
    add_retailer: true,
    update_retailer: true,
    add_user: true,
    update_user: true,
    add_supplier: true,
    update_supplier: true,
    view_supplier: true,
    view_product: true,
    view_file_upload: true,
    cancel_order: true,
  });

  const history = useHistory();

  const [userId, setuserId] = useState("");

  useEffect(() => {
    let id = localStorage.getItem("idForUserPermission");
    if (id) {
      setuserId(id);
      getPermissionDetailForUpdate(id);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_USER_SERVICE}/permission/update-permission`,
        {
          userid: userId,
          route_permission: permissionValue,
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        props.onLoading(false);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        props.onLoading(false);
      });
  };

  const onChangeHandler = (e) => {
    const value = e.target.value === "true";
    const name = e.target.name;

    setPermissionValue({ ...permissionValue, [name]: value });
  };

  const getPermissionDetailForUpdate = (id) => {
    props.onUpdateFormLoading(true);
    axios
      .post(`${process.env.REACT_APP_USER_SERVICE}/permission/get-permission`, {
        userid: id,
      })
      .then((res) => {
        const data = res.data.permission;
        setPermissionValue(data.route_permission);
        props.onUpdateFormLoading(false);
      })
      .catch((e) => {
        console.log(e);
        props.onUpdateFormLoading(false);
      });
  };

  useEffect(
    () => () => {
      setuserId("");
      localStorage.removeItem("idForUserPermission");
    },
    []
  );

  const processCancel = () => {
    setuserId("");
    localStorage.removeItem("idForUserPermission");
    history.push("/user");
  };

  return (
    <div style={{ flex: 1 }}>
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="User Permission"
            Breadcrumb={[
              { name: "User List", navigate: "/user" },
              { name: "User Permission", navigate: "" },
            ]}
          />
          <div className="tab-component">
            <div className="card">
              <div className="body">
                {props.updateFormLoading ? (
                  <div className="loader-wrapper">
                    <i className="fa fa-refresh fa-spin"></i>
                  </div>
                ) : null}
                <Row style={{ marginBottom: "-15px" }}>
                  <Col>
                    <Accordion  className="accordian__main">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            style={{ textDecoration: "none" }}
                            className="btn btn-link collapsed"
                          >
                            Permission Table
                          </Accordion.Toggle>
                        </Card.Header>
                        <Card.Body className="custom-padding-card">
                          <form onSubmit={handleSubmit}>
                            <table className="table table-bordered table-striped permission-table">
                            <div className="td-padding">
                              <thead>
                                <tr>
                                  <th>Permission</th>
                                  <th>Allow</th>
                                  <th>Restrict</th>
                                </tr>
                              </thead>
                              <tr className="custom-border-table">
                                <td>Update Company</td>
                                <td>
                                  <input
                                    name="update_company"
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.update_company}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="update_company"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.update_company}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>Add Retailer</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="add_retailer"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.add_retailer}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="add_retailer"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.add_retailer}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>Update Retailer</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="update_retailer"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.update_retailer}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="update_retailer"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.update_retailer}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>Add User</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="add_user"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.add_user}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="add_user"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.add_user}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>Update User</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="update_user"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.update_user}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="update_user"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.update_user}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>Add Supplier</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="add_supplier"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.add_supplier}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="add_supplier"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.add_supplier}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>Update Supplier</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="update_supplier"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.update_supplier}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="update_supplier"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.update_supplier}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>View Supplier</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="view_supplier"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.view_supplier}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="view_supplier"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.view_supplier}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>View Product List</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="view_product"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.view_product}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="view_product"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.view_product}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>View File Uploads</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="view_file_upload"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.view_file_upload}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="view_file_upload"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.view_file_upload}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              <tr className="custom-border-table">
                                <td>Cancel Order</td>
                                <td>
                                  <input
                                    data-parsley-errors-container="#error-radio"
                                    name="cancel_order"
                                    required=""
                                    type="radio"
                                    value={true}
                                    checked={permissionValue.cancel_order}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                                <td>
                                  <input
                                    name="cancel_order"
                                    type="radio"
                                    value={false}
                                    checked={!permissionValue.cancel_order}
                                    onChange={onChangeHandler}
                                  />
                                </td>
                              </tr>
                              </div>
                            </table>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-12">
                                <div className="d-flex">
                                  <button
                                    className="btn btn-primary w-auto btn-lg mr-2"
                                    type="submit"
                                    disabled={props.loading}
                                  >
                                    {props.loading ? (
                                      <>
                                        <Spinner animation="border" size="sm" />{" "}
                                        Please wait...
                                      </>
                                    ) : (
                                      "Update"
                                    )}
                                  </button>
                                  <button
                                    className="btn btn-secondary w-auto btn-lg"
                                    type="submit"
                                    onClick={processCancel}
                                    disabled={props.loading}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </Card.Body>
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ LoadingReducer }) => ({
  updateFormLoading: LoadingReducer.updateFormLoading,
  loading: LoadingReducer.isLoading,
});
export default connect(mapStateToProps, { onUpdateFormLoading, onLoading })(
  ManageUserPermission
);
