import React, { useState, useEffect } from "react";
import axios from "axios";
import "../PromptSetting/PromptKeysList.css";
import { onLoading } from "../../actions";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { API_PATH } from "../ApiPath/Apipath";
import formatDateToMMDDYYYYhhmma from "../FormatedDate/FormatedDate";
import Pagination from "react-responsive-pagination";


function PromptKeysList(props) {
  const [promptList, setPromptList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [dataLimit, setdataLimit] = useState(10);
  const [status, setStatus] = useState("active");
  const [type, setType] = useState("prompt");
  const [search, setSearch] = useState("");
  const [searchPage, setSearchPage] = useState(1);
  const [tempSearch, setTempSearch] = useState("");

  const history = useHistory();

  const getPromptList = async () => {
    props.onLoading(true);
    try {
      const response = await axios.post(`${API_PATH.GET_PROMPT_KEY_LIST}`, {
        page: currentPage,
        limit: dataLimit,
        type,
        status: status !== "all" ? (status === "active" ? 1 : 0) : null,
        search: search
      });

      if (response.data.success) {
        setPromptList(response.data.data);
        setTotalPages(Math.ceil(response.data.totalRecord / dataLimit));
      } else {
        setPromptList([]);
      }
      props.onLoading(false);
    } catch (error) {
      console.log("error", error);
      props.onLoading(false);
    }
  };
  useEffect(() => {
    getPromptList();
  }, [currentPage, dataLimit, status, search]);


  const activateDeactivate = (event, promptId) => {
    const status = event.target.checked;
    Swal.fire({
      title: `${status ? "Activate" : "Deactivate"} Prompt Key?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${status ? "Activate" : "Deactivate"} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onLoading(true);
        axios
          .post(`${API_PATH.CHANGE_PROMPT_STATUS}`, {
            id: promptId,
            status: status,
          })
          .then((res) => {
            toast.success(res.data.message);

            // const index = promptList.findIndex(
            //   (supplier) => supplier.id === supplierId
            // );

            // setPromptList((prevState) => [
            //   ...prevState.slice(0, index),
            //   {
            //     ...prevState[index],
            //     status: status,
            //   },
            //   ...prevState.slice(index + 1),
            // ]);
            getPromptList();
            setTimeout(() => {
              props.onLoading(false);
            }, 2000);
          })
          .catch((e) => {
            toast.error("Something Went Wrong");

            props.onLoading(false);
          });
      }
    });
  };


  let filterList = [
    { label: "Activate", value: "active" },
    { label: "Deactivate", value: "deactive" },
    { label: "All", value: "all" },
  ];


  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Prompt Keys List"
            Breadcrumb={[
              //   { name: "Integration", navigate: "/integration" },
              { name: "Prompt Keys List", navigate: "#" },
            ]}
            style={{ position: "sticky", top: 0, zIndex: 999 }}
          />
          <div className="tab-component">
            <div className="card">
              <div className="body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div style={{ minWidth: "110px" }}>
                    <Select
                      options={filterList}
                      onChange={(data) => {
                        setStatus(data.value);
                        setCurrentPage(1);
                      }}
                      defaultValue={filterList[0]}
                    />
                  </div>
                  <Link className="link-btn" to={`/add-prompt-key`}>
                    Add New Key
                  </Link>
                </div>
                <div className="mb-3 top__header">
                  <InputGroup>
                    <FormControl
                      type="search"
                      className=""
                      placeholder="Search Prompt Key"
                      value={tempSearch}
                      onChange={(e) => {
                        const value = e.target.value;
                        setTempSearch(value);
                        if (value.length >= 3 || value.length === 0) {
                          setSearch(value);
                          setSearchPage(1);
                        }
                      }}
                    />

                  </InputGroup>
                </div>

                <div className="data-table">
                  {props.loading ? (
                    <div className="loader-wrapper">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : null}
                  <table className="table w-100 table-responsive-lg">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promptList.map((prompt, index) => (
                        <>
                          <tr className="custom-border-table">
                            <td>{prompt.id}</td>
                            <td>{prompt.prompt_key}</td>
                            <td>
                              {prompt.updated
                                ? formatDateToMMDDYYYYhhmma(prompt.updated)
                                : formatDateToMMDDYYYYhhmma(prompt.created)}
                            </td>
                            <td>
                              <Form.Check
                                type="switch"
                                id={`${prompt.id}`}
                                checked={prompt.status}
                                onChange={(e) =>
                                  activateDeactivate(e, prompt.id)
                                }
                              />
                            </td>
                            <td className="action-group">
                              <i
                                data-placement="top"
                                title="Edit"
                                className="fa fa-edit edit"
                                onClick={() => {
                                  localStorage.setItem(
                                    "promptKeyId",
                                    prompt.id
                                  );

                                  history.push(`/add-prompt-key`);
                                }}
                              ></i>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  {promptList?.length === 0 && (
                    <h4
                      className="no-data"
                      style={{ color: props.loading ? "white" : "#8b8a8a" }}
                    >
                      No Data Found
                    </h4>
                  )}
                  {promptList?.length > 0 && (
                    <div className="pagination-wrapper">
                      <Pagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={
                          search ? setSearchPage : setCurrentPage
                        }
                        maxWidth={400}
                      />
                      <select
                        name="companyOwner"
                        className="form-control"
                        onChange={(e) => {
                          setCurrentPage(1);
                          setdataLimit(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ LoadingReducer, loginReducer }) => ({
  loading: LoadingReducer.isLoading,
  user: loginReducer.user,
});
export default connect(mapStateToProps, { onLoading })(PromptKeysList);
