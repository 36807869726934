import React from "react";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
class Echart extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText="E-Charts"
              Breadcrumb={[
                { name: "Cahrts", navigate: "" },
                { name: "E-Charts", navigate: "" },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ioTReducer }) => ({});

export default connect(mapStateToProps, {})(Echart);
