import React from "react";
import {
  dataFileMappingInfo1,
  dataFileMappingInfo2,
  dataFileMappingRadioMsg1,
  dataFileMappingRadioMsg2,
} from "../utils/constants";
import Select from "react-select";

const DataFileMappingJsx = ({
  formErrors,
  productRadio,
  selectedRadioPreference,
  handleProductRadioChange,
  selectedPreference,
  setSelectedPreference,
  csvOption,
}) => {
  return (
    <>
      {formErrors.map((error, index) => (
        <div key={index} className="form-error">
          <span className="text-danger"> {error}</span>
        </div>
      ))}
      <div className="mt-3 mt-lg-0">
        <div className="alert alert-primary col-12 mt-3" role="alert">
          <strong>INFO:</strong> <br />
          {dataFileMappingInfo1}
          <br />
          {dataFileMappingInfo2}
        </div>
        <div className="col-12">
          <label>{dataFileMappingRadioMsg1}</label>
        </div>
        <div className="col-12 mt-2">
          {productRadio.map((radio) => (
            <div key={radio.value} className="radio-label mr-3">
              <input
                type="radio"
                name="Preference"
                value={radio.value ? radio.value : ""}
                checked={selectedRadioPreference === radio.value}
                onChange={() => handleProductRadioChange(radio.value)}
              />
              {radio.label}
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6">
          <label className="text-success"> {dataFileMappingRadioMsg2}</label>
          {selectedRadioPreference === "multiple_row" ? (
            <div>
              <Select
                options={csvOption}
                value={csvOption.find(
                  (option) => option.value === selectedPreference
                )}
                onChange={(selectedOption) =>
                  setSelectedPreference(selectedOption)
                }
              />
            </div>
          ) : null}
        </div>
      </div>
      <label className="text-success">e.g. Product Type</label>
    </>
  );
};

export default DataFileMappingJsx;
