import React, { useEffect, useState } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "../ManageRetailer/MultiStpepProgressBar.css";


const styles = {
  Active: {
    color: "black",
  },
  Inactive: {
    color: "grey",
  },
  Completed: {
    color: "black",
    fontWeight: "bold",
  },
};


const MultiStepProgressBar = ({ page, onPageNumberClick, setPage }) => {
  const [supplierId, setSupplierId] = useState(localStorage.getItem("supplierSettingId"));

  useEffect(() => {
    // Check if step 1 is completed, and update the supplierId accordingly
    if (page >= 1) {
      setSupplierId(localStorage.getItem("supplierSettingId"));
    }
  }, [page]);


  const stepNames = [
    "Select Supplier",
    "Product To Export",
    "Currency Conversion",
    "Export Image Sizes",
    "Price Calculation",
    // supplierId && supplierId?.length === 2 ? "Miinto Pricing" : "",
    "Product Export Channel",
    "Account Configuration",
  ];
  var stepPercentage = 0;
  if (page == "1") {
    stepPercentage = 10;
  } else if (page == "2") {
    stepPercentage = 30;
    stepPercentage = 25;
  } else if (page == "3") {
    stepPercentage = 50;
    stepPercentage = 40;
  } else if (page == "4") {
    stepPercentage = 70;
    stepPercentage = 56;
  } else if (page == "5") {
    stepPercentage = 82;
    stepPercentage = 70;
  }
  //  else if (page == "6") {
  //   stepPercentage = 85;
  //   // Check if supplierId exists and has length 2
  //   // if (supplierId && supplierId.length === 2) {
  //   //   stepPercentage = 80;
  //   // } else {
  //   //   // If length is not 2, directly move to page 7
  //   //   onPageNumberClick("7");
  //   //   return null; // Skip rendering the progress bar for this step
  //   // }
  //   stepPercentage = 75;
  // }
   else if (page == "6") {
    stepPercentage = 88;
  } else if (page == "7") {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }


  return (
    <div>
      <p className="stepNamesContainer">
        {stepNames.map((name, index) => (
               // Only render stepName if it's not an empty string
            name && (
              <div
                className="stepName"
                style={page === index + 1 ? styles.Active : styles.Inactive}
                key={index}
                onClick={() => (page > index + 1 ? setPage(index + 1) : "")}
              >
                {name}
              </div>
            )
        ))}
      </p>
      <div>
        <ProgressBar percent={stepPercentage} disabled>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => onPageNumberClick("1")}
              ></div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => onPageNumberClick("2")}
              ></div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => onPageNumberClick("3")}
              ></div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => onPageNumberClick("4")}
              ></div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => onPageNumberClick("5")}
              ></div>
            )}
          </Step>
        {/* <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => {
                  // Check if supplierId exists and the current step is 6
                  if (supplierId && page === 6) {
                    // Display step 6
                    onPageNumberClick("6");
                  } else if (page > 6) {
                    // Skip step 6 and proceed to step 7
                    onPageNumberClick("7");
                  }
                }}
              ></div>
            )}
              </Step>*/}
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => onPageNumberClick("6")}
              ></div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                onClick={() => onPageNumberClick("7")}
              ></div>
            )}
          </Step>
        </ProgressBar>
        <hr/>
      </div>
    </div>
  );
};
export default MultiStepProgressBar;
