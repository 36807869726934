export const productSelectOptions = [
  {
    value: "do_nothing",
    label: "Do Nothing",
  },
  {
    value: "hardcode_value",
    label: "Hardcode Value",
    textbox: true,
  },
  {
    value: "use_AI",
    label: "Use AI",
    textbox: true,
    message: "{Parent_Title}",
  },
  {
    value: "extract",
    label: "Extract",
    textbox: true,
  },
];

export const productRadioOptions = [
  {
    value: "single_row",
    label: " Single Row ( Parent Child In Same Row)",
  },
  {
    value: "multiple_row",
    label: " Different Rows ( Parent Child In Different Row)",
  },
];

export const dataFileMappingInfo1 =
  "Please ensure to select the appropriate field mapping based on the supplier field.";
export const dataFileMappingInfo2 =
  "If a value is not available, please set it to the do nothing or leave it blank.";
export const dataFileMappingRadioMsg1 =
  "What is the standard format used by the supplier for sharing the product data?";
export const dataFileMappingRadioMsg2 =
  "Please select the field where the parent-child relation is specified.";
export const dataFileMappingImageMsg =
  "Please enter the image/photo column name(same as csv name)";
export const dataFileMappingExtractMsg =
  "Note: Please select the field from where you want to extract.";
export const dataFileMappingAIMsg =
  "Please use the mentioned tag for data generation";
export const parentPreference="PARENT";
export const productPreference="PRODUCT";

export const validatePrompt= "Please validate prompt here to compare with registered prompt key."
export const validatePromptVal= "When entering prompts, please ensure to include a colon after each attribute, for example color:red"
export const promptGuideLines="Before submitting a new prompt, please review the validation rules and prompt guidelines to ensure it meets our standards."
export const testPromptGuideLine="After hitting the Test button, please wait a few seconds. It will take 10-20 seconds to get a response."

