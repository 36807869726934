import React from "react";
import Select from "react-select";
const MiinotPricingMsrpField=({
    mainIndex,
    price,
    defaultPrices,
    initFormData,
    handleInputChange,
    handleSelectChange,
    validationErrors,
})=>{
    return(
        <>
        <div className="row ml-1 mt-3">
        <div className="d-flex" style={{ color: "#00c07fc4" }}>
          <label>MSRP Price</label>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-4 col-lg-2">
            <div>
              <label htmlFor={`price-$`}>Select Price</label>
              <Select
                options={price}
                placeholder="Select"
                name="costPriceField"
                className="temp"
                menuPlacement="top"
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, mainIndex)
                }
                value={
                  initFormData?.allCurrency[mainIndex] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice &&
                  initFormData?.allCurrency[mainIndex].msrpPrice[0] &&
                  initFormData?.allCurrency[mainIndex].msrpPrice[0].costPriceField
                    ? {
                        label:
                          initFormData?.allCurrency[mainIndex].msrpPrice[0].costPriceField,
                        value:
                          initFormData?.allCurrency[mainIndex].msrpPrice[0].costPriceField,
                      }
                    : defaultPrices
                    ? {
                        label: defaultPrices.msrpPrice,
                        value: defaultPrices.msrpPrice,
                      }
                    : null
                }
                styles={{
                  option: (styles) => {
                    return {
                      ...styles,
                      color: "#545454",
                      fontSize: "14px",
                    };
                  },
                }}
              />

              {validationErrors[mainIndex]?.costPriceField && (
                <div className="text-danger">
                  {validationErrors[mainIndex].costPriceField}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-3">
            <div className="form-group">
              <label htmlFor="value2">Exchange Rate Factor</label>
              <input
                id={`value3-$`}
                className="form-control price__input__height"
                placeholder="Enter Value"
                type="number"
                step="any"
                name="exchangeRateFactor"
                onChange={(e) => handleInputChange(e, mainIndex)}
                value={
                  initFormData?.allCurrency[mainIndex] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice &&
                  initFormData?.allCurrency[mainIndex]
                    .msrpPrice[0] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice[0]
                    ?.exchangeRateFactor
                }
              />
              {validationErrors[mainIndex]?.exchangeRateFactor && (
                <div className="text-danger">
                  {validationErrors[mainIndex].exchangeRateFactor}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
            <div className="form-group">
              <label htmlFor="value2">Landed Cost factor</label>
              <input
                id={`value3-$`}
                className="form-control price__input__height"
                placeholder="Enter Value"
                type="number"
                step="any"
                name="landedCostFactor"
                onChange={(e) => handleInputChange(e, mainIndex)}
                value={
                  initFormData?.allCurrency[mainIndex] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice &&
                  initFormData?.allCurrency[mainIndex]
                    .msrpPrice[0] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice[0]
                    ?.landedCostFactor
                }
              />
              {validationErrors[mainIndex]?.landedCostFactor && (
                <div className="text-danger">
                  {validationErrors[mainIndex].landedCostFactor}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
            <div className="form-group">
              <label htmlFor="value2">Shipping </label>
              <input
                id={`value3-$`}
                className="form-control price__input__height"
                placeholder="Enter Value"
                type="number"
                step="any"
                name="shipping"
                onChange={(e) => handleInputChange(e, mainIndex)}
                value={
                  initFormData?.allCurrency[mainIndex] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice &&
                  initFormData?.allCurrency[mainIndex]
                    .msrpPrice[0] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice[0]
                    ?.shipping
                }
              />
              {validationErrors[mainIndex]?.shipping && (
                <div className="text-danger">
                  {validationErrors[mainIndex].shipping}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
            <div className="form-group">
              <label htmlFor="value2">Pricing Factor</label>
              <input
                id={`value3-$`}
                className="form-control price__input__height"
                placeholder="Enter Value"
                type="number"
                step="any"
                name="pricingFactor"
                onChange={(e) => handleInputChange(e, mainIndex)}
                value={
                  initFormData?.allCurrency[mainIndex] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice &&
                  initFormData?.allCurrency[mainIndex]
                    .msrpPrice[0] &&
                  initFormData?.allCurrency[mainIndex]?.msrpPrice[0]
                    ?.pricingFactor
                }
              />
              {validationErrors[mainIndex]?.pricingFactor && (
                <div className="text-danger">
                  {validationErrors[mainIndex].pricingFactor}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
        </>
    )
}
export default MiinotPricingMsrpField