import React from 'react';
import { Alert } from 'react-bootstrap';

const CompanyGeneralInfo = () => {

    return (
        <div>
            <Alert variant='secondary'>General Info</Alert>
        </div>
    );
}

export default CompanyGeneralInfo;
