import React from "react";
import Select from "react-select";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
const MiintoPricingFillUpSubFields = ({
  mainIndex,
  data,
  subIndex,
  price,
  defaultPrices,
  initFormData,
  handleInputChange,
  handleSelectChange,
  handleRemoveSubField,
  handleRemoveSubField1,
  handleSubAddField,
  validationErrors,
}) => {
  return (
    <>
      <div key={`${mainIndex}-${subIndex}`} className="row mt-4">
        <div className="col-sm-3 col-md-4 col-lg-2">
          <div>
            <label htmlFor={`price-$`}>Select Price</label>
            <Select
              options={price}
              placeholder="Select"
              name="costPriceField"
              className="temp"
              menuPlacement="top"
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, mainIndex, subIndex)
              }
              value={
                data.costPriceField
                  ? {
                      label: data.costPriceField,
                      value: data.costPriceField,
                    }
                  : defaultPrices
                  ? {
                      label: defaultPrices.tierPrice,
                      value: defaultPrices.tierPrice,
                    }
                  : null
              }
              styles={{
                option: (styles) => {
                  return {
                    ...styles,
                    color: "#545454",
                    fontSize: "14px",
                  };
                },
              }}
            />
            {validationErrors[mainIndex]?.[subIndex]?.costPriceField && (
              <div className="text-danger">
                {validationErrors[mainIndex][subIndex].costPriceField}
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-3 col-md-4 col-lg-1">
          <div className="form-group">
            <label htmlFor="value2">Min</label>
            <input
              id={`value3-$`}
              className="form-control price__input__height"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="minimumValue"
              onChange={(e) => handleInputChange(e, mainIndex, subIndex)}
              value={data.minimumValue || ""}
            />
            {validationErrors[mainIndex]?.[subIndex]?.minimumValue && (
              <div className="text-danger">
                {validationErrors[mainIndex][subIndex].minimumValue}
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-3 col-md-4 col-lg-1">
          <div className="form-group">
            <label htmlFor="value2">Max</label>
            <input
              id={`value3-$`}
              className="form-control price__input__height"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="maximumValue"
              onChange={(e) => handleInputChange(e, mainIndex, subIndex)}
              value={data.maximumValue || ""}
            />
            {validationErrors[mainIndex]?.[subIndex]?.maximumValue && (
              <div className="text-danger">
                {validationErrors[mainIndex][subIndex].maximumValue}
              </div>
            )}
          </div>
        </div>

        <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
          <div className="form-group">
            <label htmlFor="value2" style={{ marginTop: "-20px" }}>
              Exchange Rate Factor
            </label>
            <input
              id={`value3-$`}
              className="form-control price__input__height"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="exchangeRateFactor"
              onChange={(e) => handleInputChange(e, mainIndex, subIndex)}
              value={data.exchangeRateFactor || ""}
            />
            {validationErrors[mainIndex]?.[subIndex]?.exchangeRateFactor && (
              <div className="text-danger">
                {validationErrors[mainIndex][subIndex].exchangeRateFactor}
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
          <div className="form-group">
            <label htmlFor="value2" className="landed_factor">
              Landed Cost factor
            </label>
            <input
              id={`value3-$`}
              className="form-control price__input__height"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="landedCostFactor"
              onChange={(e) => handleInputChange(e, mainIndex, subIndex)}
              value={data.landedCostFactor || ""}
            />
            {validationErrors[mainIndex]?.[subIndex]?.landedCostFactor && (
              <div className="text-danger">
                {validationErrors[mainIndex][subIndex].landedCostFactor}
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-1">
          <div className="form-group">
            <label htmlFor="value2">Shipping </label>
            <input
              id={`value3-$`}
              className="form-control price__input__height"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="shipping"
              onChange={(e) => handleInputChange(e, mainIndex, subIndex)}
              value={data.shipping || ""}
            />
            {validationErrors[mainIndex]?.[subIndex]?.shipping && (
              <div className="text-danger">
                {validationErrors[mainIndex][subIndex].shipping}
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-6 mt-3 mt-sm-0 col-md-4 col-lg-2">
          <div className="form-group">
            <label htmlFor="value2">Pricing Factor</label>
            <input
              id={`value3-$`}
              className="form-control price__input__height"
              placeholder="Enter Value"
              type="number"
              step="any"
              name="pricingFactor"
              onChange={(e) => handleInputChange(e, mainIndex, subIndex)}
              value={data.pricingFactor || ""}
            />
            {validationErrors[mainIndex]?.[subIndex]?.pricingFactor && (
              <div className="text-danger">
                {validationErrors[mainIndex][subIndex].pricingFactor}
              </div>
            )}
          </div>
        </div>
        <div
          className="col-sm-2 mt-3 mt-sm-0 col-md-4 col-lg-1"
          style={{ marginLeft: "-20px" }}
        >
          {subIndex === 0 ? (
            <AddCircleOutlineOutlinedIcon
              onClick={() => {
                handleSubAddField(mainIndex);
              }}
              style={{
                cursor: "pointer",
                color: "#49c5b6",
                fontSize: "20px",
                marginTop: "30px",
              }}
            />
          ) : (
            <HighlightOffOutlinedIcon
              className="delete-idx-icon"
              onClick={() => {
                if (
                  initFormData.allCurrency[mainIndex]?.tierPrice[subIndex]?.id
                ) {
                  handleRemoveSubField(mainIndex, subIndex);
                } else {
                  handleRemoveSubField1(mainIndex, subIndex);
                }
              }}
              style={{
                cursor: "pointer",
                color: "#b41717",
                fontSize: "20px",
                marginTop: "30px",
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default MiintoPricingFillUpSubFields;
