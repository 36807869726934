import React, { useState, useEffect } from "react";
import moment from "moment";
import Pagination from "react-responsive-pagination";
import axios from "axios";
import { onLoading } from "../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormControl, InputGroup } from "react-bootstrap";
import { API_PATH } from "../ApiPath/Apipath";
import "./ProductsList.css";

function VariantList(props) {
  const [search, setSearch] = useState("");
  const [autoId, setAutoId] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [variantList, setVariantsList] = useState([]);
  const [currentVariantPage, setCurrentVariantPage] = useState(1);
  const [totalVariantPages, setTotalVariantPages] = useState(2);
  const [dataVariantLimit, setdataVariantLimit] = useState(10);
  const [showFullText, setShowFullText] = useState(false);
  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const startIndex = (currentVariantPage - 1) * dataVariantLimit + 1;
  console.log("variantList---", variantList?.length);

  const history = useHistory();

  const getVariantList = async (
    currentVariantPage,
    dataVariantLimit,
    search
  ) => {
    props.onLoading(true);

    try {
      const response = await axios.post(`${API_PATH.GET_VARIANT_LIST}`, {
        page: currentVariantPage,
        limit: dataVariantLimit,
        search: search,
      });

      return response.data;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  useEffect(() => {
    const getVariantData = async () => {
      const pageToFetch = search ? searchPage : currentVariantPage;
      const response = await getVariantList(
        pageToFetch,
        dataVariantLimit,
        search
      );
      if (response) {
        let totalPage = Math.ceil(response.totalRecord / response.limit);
        setTotalVariantPages(totalPage);

        setVariantsList(response.data);

        if (!search && currentVariantPage === 1) {
          setAutoId(1);
        }
      }
      props.onLoading(false);
    };

    getVariantData();
  }, [search, searchPage, currentVariantPage, dataVariantLimit]);

  const setProductAiTitle = (product) => {
    const parentTitle = product?.Parent_Title
      ? product?.Parent_Title
      : product?.Variant_Title;
    const aiTitle = product?.AI_TITLE
      ? product?.AI_TITLE.replace(/["-]/g, "")
      : parentTitle;
    return aiTitle?.includes("AI Generated") ? aiTitle.slice(13) : aiTitle;
  };

  return (
    <>
      <div className="alert alert-primary col-12 mt-3" role="alert">
        <strong>INFO:</strong> <br />
        In this tab, we are not displaying all variants. We are only showing
        those variants for which the supplier has provided the Parent-Child
        relation. Therefore, only child (variant) data is displayed here.
      </div>
      <div className="tab-component">
        <div className="card">
          <div className="body">
            <div className="mb-3 top__header">
              <InputGroup>
                <InputGroup.Text id="search">Search</InputGroup.Text>
                <FormControl
                  type="search"
                  className=""
                  placeholder="Search Products by Grand Parent SKU, Parent SKU, Variant SKU, Azura EAN, Brand,  AI Title or Supplier..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setSearchPage(1);
                  }}
                />
              </InputGroup>
            </div>

            <div className="data-table">
              {props.loading ? (
                <div className="loader-wrapper">
                  <i className="fa fa-refresh fa-spin"></i>
                </div>
              ) : null}
              <table className="table-responsive">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Unique Id</th>
                    <th>Supplier Name</th>
                    <th>Product Image</th>
                    <th>Grand Parent SKU</th>
                    <th>Parent SKU</th>
                    <th>Variant SKU</th>
                    <th>Azura EAN</th>
                    <th>Brand</th>
                    <th>Category</th>
                    <th>AI Title</th>
                    <th>Cost Price</th>
                    <th>Retail Price</th>
                    <th>Last Update(UTC)</th>
                    <th>Is Enhanced</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {variantList?.map((variant, idx) => (
                    <tr key={variant.id}>
                      <td>{startIndex + idx}</td>
                      <td className="txt-size">{variant.uniqueId}</td>
                      <td>{variant.Supplier}</td>
                      <td>
                        {variant.Image_Variant_1_original ? (
                          <img
                            src={variant.Image_Variant_1_original}
                            className="list-logo prod-img"
                          />
                        ) : (
                          <div className="list-logo placeholder">N/A</div>
                        )}
                      </td>
                      <td className="txt-size">{variant.Grandparent_SKU}</td>
                      <td className="txt-size">{variant.Parent_SKU}</td>
                      <td className="category-cell">
                      {showFullText ? (
                        <div className="category-text">{variant.Variant_SKU}</div>
                      ) : (
                        <div className="category-text">
                          {variant.Variant_SKU?.length > 10 ? (
                            <>
                              {variant.Variant_SKU.substring(0, 10)}
                              <span
                                className="ellipsis clickable"
                                onClick={toggleText}
                              >
                                ...
                              </span>
                            </>
                          ) : (
                            variant.Variant_SKU
                          )}
                        </div>
                      )}
                    </td>
                      <td className="txt-size">{variant.AzuraEAN}</td>
                      <td className="category-cell">
                        {showFullText ? (
                          <div className="category-text">{variant.Brand}</div>
                        ) : (
                          <div className="category-text">
                            {variant.Brand?.length > 10 ? (
                              <>
                                {variant.Brand.substring(0, 10)}
                                <span
                                  className="ellipsis clickable"
                                  onClick={toggleText}
                                >
                                  ...
                                </span>
                              </>
                            ) : (
                              variant.Brand
                            )}
                          </div>
                        )}
                      </td>

                      <td className="category-cell">
                        {showFullText ? (
                          <div className="category-text">
                            {variant.Azura_Category_Tree}
                          </div>
                        ) : (
                          <div className="category-text">
                            {variant.Azura_Category_Tree?.length > 10 ? (
                              <>
                                {variant.Azura_Category_Tree.substring(0, 10)}
                                <span
                                  className="ellipsis clickable"
                                  onClick={toggleText}
                                >
                                  ...
                                </span>
                              </>
                            ) : (
                              variant.Azura_Category_Tree
                            )}
                          </div>
                        )}
                      </td>
                      <td>
                        {showFullText ? (
                          setProductAiTitle(variant)
                        ) : (
                          <>
                            {setProductAiTitle(variant)?.slice(0, 8)}
                            {setProductAiTitle(variant)?.length > 8 && (
                              <span
                                className="ellipsis clickable"
                                onClick={toggleText}
                              >
                                ...
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td className="category-cell">
                        {showFullText ? (
                          <div className="category-text">
                            {variant.Cost_Price}
                          </div>
                        ) : (
                          <div className="category-text">
                            {variant.Cost_Price?.length > 10 ? (
                              <>
                                {variant.Cost_Price.substring(0, 10)}
                                <span
                                  className="ellipsis clickable"
                                  onClick={toggleText}
                                >
                                  ...
                                </span>
                              </>
                            ) : (
                              variant.Cost_Price
                            )}
                          </div>
                        )}
                      </td>
                      <td>{variant.Retail_Price}</td>

                      <td>
                        {variant.updatedAt
                          ? moment(variant.updatedAt).format(
                              "MM/DD/YYYY hh:mm a"
                            )
                          : moment(variant.createdAt).format(
                              "MM/DD/YYYY hh:mm a"
                            )}
                      </td>

                      <>
                        <td>
                          {variant.isEnhanced === 1 ? (
                            <label className="text-success">Done</label>
                          ) : (
                            <label className="text-danger">Pending</label>
                          )}
                        </td>
                        <td className="action-group">
                          <i
                            style={{ color: "#49c5b6" }}
                            data-placement="top"
                            title="Edit"
                            className="fa fa-eye"
                            onClick={() => {
                              history.push(`/variants-details/${variant.id}`);
                            }}
                          ></i>
                        </td>
                      </>
                    </tr>
                  ))}
                </tbody>
              </table>
              {variantList?.length === 0 && (
                <h4
                  className="no-data"
                  style={{
                    color: props.loading ? "white" : "#8b8a8a",
                  }}
                >
                  No Data Found
                </h4>
              )}
              {variantList?.length > 0 && (
                <div className="pagination-wrapper">
                  <Pagination
                    current={currentVariantPage}
                    total={totalVariantPages}
                    onPageChange={
                      search ? setSearchPage : setCurrentVariantPage
                    }
                    maxWidth={400}
                  />
                  <select
                    name="companyOwner"
                    className="form-control"
                    onChange={(e) => {
                      setCurrentVariantPage(1);
                      setdataVariantLimit(e.target.value);
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = ({ LoadingReducer, loginReducer }) => ({
  loading: LoadingReducer.isLoading,
  user: loginReducer.user,
});
export default connect(mapStateToProps, { onLoading })(VariantList);
