import React from 'react';
import { Alert } from 'react-bootstrap';

const CompanyIntegration = () => {
    return (
        <div>
            <Alert variant='secondary'>Integration</Alert>
        </div>
    );
}

export default CompanyIntegration;
