import React, { useState, useEffect } from "react";
import moment from "moment";
import Pagination from "react-responsive-pagination";
import axios from "axios";
import { onLoading } from "../../actions";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { useHistory } from "react-router-dom";
import { FormControl, InputGroup, Tab, Tabs } from "react-bootstrap";
import { API_PATH } from "../ApiPath/Apipath";
import "./ProductsList.css";
import VariantList from "./VariantList";

function ProductsList(props) {
  const [productList, setProductList] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [dataLimit, setdataLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [autoId, setAutoId] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [activeTab, setActiveTab] = useState("parent");
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const startIndex = (currentPage - 1) * dataLimit + 1;
  console.log("productList---", productList?.length);

  const history = useHistory();

  const getProductList = async (currentPage, dataLimit, search) => {
    props.onLoading(true);

    try {
      const response = await axios.post(`${API_PATH.GET_PRODUCT_LIST}`, {
        page: currentPage,
        limit: dataLimit,
        search: search,
      });

      return response.data;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  useEffect(() => {
    const user = props.user.data;
    setUserRole(user.role);
    const getProductData = async () => {
      const pageToFetch = search ? searchPage : currentPage;
      const response = await getProductList(pageToFetch, dataLimit, search);
      if (response) {
        let totalPage = Math.ceil(response.totalRecord / response.limit);
        setTotalPages(totalPage);

        setProductList(response.data);

        if (!search && currentPage === 1) {
          setAutoId(1);
        }
      }
      props.onLoading(false);
    };

    getProductData();
  }, [search, searchPage, currentPage, dataLimit]);

  const setProductAiTitle = (product) => {
    const parentTitle = product?.Parent_Title
      ? product?.Parent_Title
      : product?.Variant_Title;
    const aiTitle = product?.AI_TITLE
      ? product?.AI_TITLE.replace(/["-]/g, "")
      : parentTitle;
    return aiTitle?.includes("AI Generated") ? aiTitle.slice(13) : aiTitle;
  };

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Products List"
            Breadcrumb={[{ name: "Products List", navigate: "#" }]}
          />

          <div className="tab-component">
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
            >
              <Tab eventKey="parent" title="PARENT"></Tab>
              <Tab eventKey="variants" title="VARIANTS"></Tab>
            </Tabs>
          </div>

          {activeTab === "parent" ? (
            <>
              <div className="tab-component">
                <div className="card">
                  <div className="body">
                    <div className="mb-3 top__header">
                      <InputGroup>
                        <InputGroup.Text id="search">Search</InputGroup.Text>
                        <FormControl
                          type="search"
                          className=""
                          placeholder="Search Products by Grand Parent SKU, Parent SKU, Variant SKU, Azura EAN, Brand,  AI Title or Supplier..."
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setSearchPage(1);
                          }}
                        />
                      </InputGroup>
                    </div>

                    <div className="data-table">
                      {props.loading ? (
                        <div className="loader-wrapper">
                          <i className="fa fa-refresh fa-spin"></i>
                        </div>
                      ) : null}
                      <table className="table-responsive">
                        <thead>
                          {props.user.permissions.view_product ||
                          userRole === "SUPER_ADMIN" ? (
                            <>
                              <tr>
                                <th>#</th>
                                <th>Unique Id</th>
                                <th>Supplier Name</th>
                                <th>Product Image</th>
                                <th>Grand Parent SKU</th>
                                <th>Parent SKU</th>
                                <th>Variant SKU</th>
                                <th>Azura EAN</th>
                                <th>Brand</th>
                                <th>Category</th>
                                <th>AI Title</th>
                                <th>Cost Price</th>
                                <th>Retail Price</th>
                                <th>Last Update(UTC)</th>
                                <th>Is Enhanced</th>
                                <th>Action</th>
                              </tr>
                            </>
                          ) : null}
                        </thead>
                        <tbody>
                          {props.user.permissions.view_product ||
                          userRole === "SUPER_ADMIN" ? (
                            <>
                              {productList?.map((product, idx) => (
                                <tr key={product.id}>
                                  <td>{startIndex + idx}</td>
                                  <td className="txt-size">{product.uniqueId}</td>
                                  <td>{product.Supplier}</td>
                                  <td>
                                    {product.Image_Parent_1_original ? (
                                      <img
                                        src={product.Image_Parent_1_original}
                                        className="list-logo prod-img"
                                      />
                                    ) : (
                                      <div className="list-logo placeholder">
                                        N/A
                                      </div>
                                    )}
                                  </td>

                                  <td className="txt-size">{product.Grandparent_SKU}</td>
                                  <td className="txt-size">{product.Parent_SKU}</td>
                                  <td className="txt-size">{product.Variant_SKU}</td>
                                  <td className="txt-size">{product.AzuraEAN}</td>
                                  <td>{product.Brand}</td>

                                  <td className="category-cell">
                                  {showFullText ? (
                                    <div className="category-text">
                                      {product.Azura_Category_Tree}
                                    </div>
                                  ) : (
                                    <div className="category-text">
                                      {product.Azura_Category_Tree?.length > 10 ? (
                                        <>
                                          {product.Azura_Category_Tree.substring(0, 10)}
                                          <span
                                            className="ellipsis clickable"
                                            onClick={toggleText}
                                          >
                                            ...
                                          </span>
                                        </>
                                      ) : (
                                        product.Azura_Category_Tree
                                      )}
                                    </div>
                                  )}
                                </td>

                                <td>
                                {showFullText ? (
                                  setProductAiTitle(product)
                                ) : (
                                  <>
                                    {setProductAiTitle(product)?.slice(0, 8)}
                                    {setProductAiTitle(product)?.length > 8 && (
                                      <span className="ellipsis clickable" onClick={toggleText}>
                                        ...
                                      </span>
                                    )}
                                  </>
                                )}
                              </td>
                              
                                  <td>{product.Cost_Price}</td>
                                  <td>{product.Retail_Price}</td>

                                  <td>
                                    {product.updatedAt
                                      ? moment(product.updatedAt).format(
                                          "MM/DD/YYYY hh:mm a"
                                        )
                                      : moment(product.createdAt).format(
                                          "MM/DD/YYYY hh:mm a"
                                        )}
                                  </td>

                                  <>
                                    <td>
                                      {product.isEnhanced === 1 ? (
                                        <label className="text-success">
                                          Done
                                        </label>
                                      ) : (
                                        <label className="text-danger">
                                          Pending
                                        </label>
                                      )}
                                    </td>
                                    <td className="action-group">
                                      <i
                                        style={{ color: "#49c5b6" }}
                                        data-placement="top"
                                        title="Edit"
                                        className="fa fa-eye"
                                        onClick={() => {
                                          history.push(
                                            `/product-details/${product.id}`
                                          );
                                        }}
                                      ></i>
                                    </td>
                                  </>
                                </tr>
                              ))}
                            </>
                          ) : null}
                        </tbody>
                      </table>
                      {productList?.length === 0 && (
                        <h4
                          className="no-data"
                          style={{ color: props.loading ? "white" : "#8b8a8a" }}
                        >
                          No Data Found
                        </h4>
                      )}
                      {productList?.length > 0 && (
                        <div className="pagination-wrapper">
                          <Pagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={
                              search ? setSearchPage : setCurrentPage
                            }
                            maxWidth={400}
                          />
                          <select
                            name="companyOwner"
                            className="form-control"
                            onChange={(e) => {
                              setCurrentPage(1);
                              setdataLimit(e.target.value);
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {activeTab === "variants" && (
                <>
                  <VariantList />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ LoadingReducer, loginReducer }) => ({
  loading: LoadingReducer.isLoading,
  user: loginReducer.user,
});
export default connect(mapStateToProps, { onLoading })(ProductsList);
