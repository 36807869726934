import React from "react";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import SparkleCard from "../../components/SparkleCard";
import {
  onPressSecuritySystem,
  onPressMainGate,
  onPressSwitchBordButton,
  onPressOutSwitchBordButton,
  onPressAppliencesSwitchBordButton,
  onPressSwitchBordDropDown,
  onPressOutdoorDropDown,
  onPressSwithOnAllOut,
  onPressAllOffLightOut,
  onPressSwithOnAllIn,
  onPressAllOffLightIn
} from "../../actions";

class IoT extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div >
          <div className="container-fluid">
            <PageHeader HeaderText="IoT Dashboard" Breadcrumb={[{ name: "Dashboard", navigate: "" }, { name: "IoT Dashboard", navigate: "" }]} />
            
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ioTReducer }) => ({
  isSecuritySystem: ioTReducer.isSecuritySystem,
  isMaingate: ioTReducer.isMaingate,
  switchBoardSwitch: ioTReducer.switchBoardSwitch,
  switchOutBoardSwitch: ioTReducer.switchOutBoardSwitch,
  switchAppliencesBoardSwitch: ioTReducer.switchAppliencesBoardSwitch,
  isIndoorDropdown: ioTReducer.isIndoorDropdown,
  isOutdoorDropdown: ioTReducer.isOutdoorDropdown
});

export default connect(mapStateToProps, {
  onPressSecuritySystem,
  onPressMainGate,
  onPressSwitchBordButton,
  onPressOutSwitchBordButton,
  onPressAppliencesSwitchBordButton,
  onPressSwitchBordDropDown,
  onPressOutdoorDropDown,
  onPressSwithOnAllOut,
  onPressAllOffLightOut,
  onPressSwithOnAllIn,
  onPressAllOffLightIn
})(IoT);
