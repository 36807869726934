import React, { useEffect, useState } from "react";
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { API_PATH } from "../ApiPath/Apipath";
import axios from "axios";

const stepNames = ["Gemini Prompts", "Gemini Prompt Mapping", "GPT 4o Prompt", "GPT 4o Prompt Mapping"];

const styles = {
  Active: {
    color: "black",
  },
  Inactive: {
    color: "grey",
  },
  Completed: {
    color: "black",
    fontWeight: "bold",
  },
};

const MultiStepProgressBar = ({ page, onPageNumberClick, setPage }) => {
  const totalSteps = stepNames.length;
  const stepPercentage = ((page - 1) / (totalSteps - 1)) * 112;
  const promptId = localStorage.getItem("addPromptId")
  const [supplierApiData, setSupplierApiData] = useState({});

  useEffect(() => {
    const supplierId = localStorage.getItem("supplierId");

    if (supplierId) {
      getSupplierDataById(supplierId);
    } else {
      setSupplierApiData("");
    }
  }, []);

  const getSupplierDataById = (supplierId) => {
    axios
      .get(`${API_PATH.GET_INTEGRATION_INFO_BY_ID}=${supplierId}`)
      .then((response) => {
        const supplierData = response.data.data;
        setSupplierApiData(supplierData);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div>
      <p className="supplier-onboarding">
        {supplierApiData.name ? supplierApiData.name + " - " : ""}
        {promptId ? "Edit Prompt" : "Add Prompt"}
      </p>
      <div className="stepNamesContainer1">
        {stepNames.map((name, index) => (
          <div
            // className={`promptStep ${index === 1 ? "endStep" : ""}`}
            className="promptStep"
            style={
              page >= index + 1
                ? styles.Active
                : page > index + 1
                  ? styles.Completed
                  : styles.Inactive
            }
            key={index}
            onClick={() => {
              if (page > index + 1) {
                setPage(index + 1);
              }
            }}
          >
            {name}
          </div>
        ))}
      </div>

      <div className="progressPrompt">
        <ProgressBar percent={stepPercentage} disabled >
          {stepNames.map((name, index) => (
            <Step key={index}>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStepPrompt ${accomplished ? "accomplished" : null
                    }`}
                  onClick={() => {
                    if (page > index + 1) {
                      setPage(index + 1);
                    }
                  }}
                ></div>
              )}
            </Step>
          ))}
        </ProgressBar>
      </div>
      <hr className="hr" />


    </div>
  );
};

export default MultiStepProgressBar;

