import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { onLoading } from "../../actions";
import axios from "axios";
import "./SupplierPage.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FormContext } from "./ManageSuppiler";
import { API_PATH } from "../ApiPath/Apipath";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { debounce } from "lodash";
import { productRadioOptions, productSelectOptions } from "../utils/constants";
import DataFileMappingJsx from "./DataFileMappingJsx";
import ProductFieldsMappingJsx from "./ProductFieldsMappingJsx";
import { validateProductFieldForm } from "../Validations/Validation";

const defaultAIDescription = `Please generate the detailed and factual 'DESCRIPTION' and 'DESCRIPTION' as a 'BULLET POINTS' of the product, from the product features list mentioned below. \n {gpt_features_list}, Don't use Introducing word in start of description \n and If any features is NONE, 0, empty or not-specified in gpt_features_list then don't include that feature sentence and in bullet points. \n For 'BULLET POINTS' Just give BULLET POINTS not anything else and use hyphen (-) for bullet points and there should not be a empty line between bullet points. \n Give output of both in JSON only, as 'description' and 'bullet_description' as a key.`;
const defaultAITitle =
  "Please generate the search engine optimized, short and factual `TITLE` of the product less than 9 to 12 words without SIZES, COLOURS and GENDER from the product features list mentioned below \n {gpt_features_list}, Dont use that features which is NONE, 0, empty or not-specified in gpt_features_list, just give title don't give any other things in response, Also please don't include SIZES, COLOURS, GENDER and BRAND in title.";
const defaultSystemPrompt =
  "You are Digital visual merchandiser for a luxury designer ecommerce store and generate enhanced description and title for product such that it doesn't sounds robotics or flowery and I can put that in E-commerce website, make it read like a product on farfetch.";

const tooltip = (
  <Tooltip id="tooltip">
    <strong>
      system prompt is like how ChatGpt will behave to generate the response
    </strong>
  </Tooltip>
);

function SuppilerPage3(props) {
  const { setPage } = props;
  const [initFormData, setInitFormData] = useState({
    AITitlePrompt: "",
    SystemPrompt: "",
    AIDescriptionAndBulletDescriptionPrompt: "",
  });
  const { setFormData, processCancel } = useContext(FormContext);

  const [options, setOptions] = useState(productSelectOptions);

  const [productRadio, setProductRadio] = useState(productRadioOptions);

  const [productFields, setProductFields] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [productFieldErrors, setProductFieldErrors] = useState({});
  const [selectedRadio, setSelectedRadio] = useState({});
  const [additionalTextValue, setAdditionalTextValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [mappingData, setMappingData] = useState([]);
  const [csvOption, setCsvOption] = useState([]);
  const [selectedPreference, setSelectedPreference] = useState(null);
  const [selectedRadioPreference, setSlectedRadioPreference] = useState(null);
  const [customFieldsData, setCustomFieldsData] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.onLoading(true);
    getProductField();
    getSupplierDataById();
    getMappingData();
  }, []);

  useEffect(() => {
    setIsFormValid(Object.keys(formErrors).length === 0);
  }, [formErrors]);

  const handleFieldChange = (index, key, selectedOption) => {
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      if (!newSelectedOptions[index]) {
        newSelectedOptions[index] = {};
      }
      if (selectedOption) {
        newSelectedOptions[index][key] = selectedOption;
        if (
          selectedOption &&
          selectedOption.textbox &&
          !newSelectedOptions[index][key].additionalValue
        ) {
          newSelectedOptions[index][key].additionalValue = "";
        }

        const selectedValue = selectedOption.value;
        const selectedOptionIndex = options.findIndex(
          (option) => option.value === selectedValue
        );
        const option = options[selectedOptionIndex];
        const count = option?.count || 0;
        let color = "gray";

        if (count === 0) {
          color = "green";
        } else if (count === 1) {
          color = "orange";
        } else if (count >= 2) {
          color = "red";
        }

        const newCount = count + 1;
        const newOptions = [...options];
        newOptions[selectedOptionIndex] = {
          ...newOptions[selectedOptionIndex],
          count: newCount,
          color: color,
        };
        setOptions(newOptions);

        setFormErrors([]);
      }
      return newSelectedOptions;
    });
  };

  const handleChange = (key, value) => {
    const formData = new FormData(document.forms.prouctFieldForm);
    formData.set(key, value);
    const errors = validateProductFieldForm(formData);
    setProductFieldErrors(errors);
    setIsFormValid(Object.keys(errors).length === 0);
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const trimmedValue = type === "text" ? value.trim() : value;

    setInitFormData((prevState) => ({
      ...prevState,
      [name]: trimmedValue,
    }));
    handleChange(name, value);
  };

  const handleAdditionalValueChange = (index, key, additionalValue) => {
    debouncedUpdateAdditionalValue(index, key, additionalValue);
  };

  const debouncedUpdateAdditionalValue = debounce(
    (index, key, additionalValue) => {
      setSelectedOptions((prevSelectedOptions) => {
        const newSelectedOptions = [...prevSelectedOptions];
        if (!newSelectedOptions[index]) {
          newSelectedOptions[index] = {};
        }
        newSelectedOptions[index][key] = {
          ...newSelectedOptions[index][key],
          additionalValue,
        };

        return newSelectedOptions;
      });
    }
  );

  const debouncedRadioTextChange = debounce((index, key, e) => {
    setAdditionalTextValue((prevTextVals) => {
      const newTextVals = [...prevTextVals];
      if (!newTextVals[index]) {
        newTextVals[index] = {};
      }
      newTextVals[index][key] = {
        ...newTextVals[index][key],
        e,
      };

      return newTextVals;
    });
  });

  const handleRadioTextChange = (index, key, e) => {
    debouncedRadioTextChange(index, key, e);
  };

  const handleRadioChange = (index, key, value) => {
    setSelectedRadio((prevSelectedRadio) => ({
      ...prevSelectedRadio,
      [`${index}-${key}`]: {
        value,
        showTextbox: value,
      },
    }));
  };

  const handleExtractChange = (index = 0, key, value) => {
    const updatedState = [...selectedOptions];
    updatedState[index][key].supplierExtract = value;
    setSelectedOptions(updatedState);
  };

  const handleProductRadioChange = (value) => {
    setSlectedRadioPreference(value);
  };

  const getProductField = async () => {
    try {
      props.onLoading(true);
      const response = await axios.get(`${API_PATH.GET_PRODUCT_CATALOG}`);
      if (response.data.success) {
        setProductFields(response.data.data);
        props.onLoading(false);
      }
    } catch (error) {
      console.error(error);
      props.onLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const errors = validateProductFieldForm(formData);
    setProductFieldErrors(errors);
    const isValid = validateForm();
    if (isValid && Object.keys(errors).length === 0) {
      const mappingArray = [];
      selectedOptions.forEach((selectedOption, index) => {
        const keys = Object.keys(selectedOption);
        keys.forEach((key) => {
          const option = selectedOption[key];
          if (option) {
            let additionalValue = "";
            let imageType = "";
            let imageList = "";

            const mapping =
              mappingData.find((item) => item.standardField === key) || {};
            const filteredImageType = mappingData.filter((data) =>
              data.standardField.includes("Image")
            );
            const filteredImageList = filteredImageType
              ?.map((data) => ({
                imageList: data.imageList,
                imageType: data.imageType,
                standardField: data.standardField,
              }))
              .filter((data) => data.imageList !== "");
            const radioValue =
              selectedRadio[`0-${key}`] && selectedRadio[`0-${key}`].value
                ? selectedRadio[`0-${key}`].value
                : mapping.imageType || null;
            if (
              key.startsWith("Image") &&
              option.value !== "do_nothing" &&
              option.value !== "hardcode_value" &&
              option.value !== "use_AI" &&
              option.value !== "extract"
            ) {
              additionalValue = selectedRadio[`${index}-${key}`]?.value || "";
              imageType = radioValue || "";

              if (additionalTextValue.length === 0) {
                imageList = filteredImageList[index]?.imageList;
                index++;
              } else {
                const existingImageList = filteredImageList[index]?.imageList;
                const updatedImageList = additionalTextValue[0]?.[key]?.e;
                imageList =
                  updatedImageList !== undefined
                    ? updatedImageList
                    : existingImageList;
                index++;
              }
              if (imageType !== "folder_only") {
                imageList = "";
              }
            } else if (
              key === "Cost_Price" ||
              key === "Retail_Price" ||
              key === "Suggested_Sell_Price"
            ) {
              additionalValue = selectedOption[key]?.additionalValue
                ? selectedOption[key].additionalValue
                : "";
            } else if (
              option.value === "hardcode_value" ||
              option.value === "use_AI" ||
              option.value === "extract"
            ) {
              additionalValue =
                selectedOption[key] && selectedOption[key].additionalValue
                  ? selectedOption[key].additionalValue
                  : "";
            } else {
              imageType = radioValue || "";
            }

            let supplierExtract = "";
            if (option.value === "extract") {
              if (
                typeof selectedOptions === "object" &&
                selectedOptions !== null
              ) {
                supplierExtract = selectedOptions[0]?.[key]?.supplierExtract;
              } else {
                supplierExtract = selectedOptions;
              }
            }
            const mappingObject = {
              supplierId: localStorage.getItem("supplierId"),
              supplierName: localStorage.getItem("supplierName"),
              updatedBy: localStorage.getItem("email"),
              standardField: key,
              standardValue: "",
              supplierField: option.value,
              additionalValue: additionalValue,
              imageType: imageType,
              imageList: imageList,
              supplierExtract: supplierExtract,
            };
            mappingArray.push(mappingObject);
          }
        });
      });

      // if (mappingArray.length === 0) {
      //   return;
      // }

      productRadio.forEach((product) => {
        const additionalValue = selectedRadioPreference || "";
        const additionalValueString =
          typeof additionalValue === "object" ? "" : additionalValue;
        const mappingObject = {
          supplierId: localStorage.getItem("supplierId"),
          supplierName: localStorage.getItem("supplierName"),
          updatedBy: localStorage.getItem("email"),
          standardField: "Preference",
          standardValue: "",
          supplierField: selectedPreference ? selectedPreference.value : "",
          additionalValue: additionalValueString,
        };
        mappingArray.push(mappingObject);
      });

      const supplierName = localStorage.getItem("supplierName");

      const staticMappingObj = {
        supplierId: localStorage.getItem("supplierId"),
        updatedBy: localStorage.getItem("email"),
        supplierName,
        standardField: "Supplier",
        standardValue: "",
        supplierField: "hardcode_value",
        additionalValue: supplierName,
      };

      mappingArray.push(staticMappingObj);

      const AIObject = {
        supplierId: localStorage.getItem("supplierId"),
        updatedBy: localStorage.getItem("email"),
        ...initFormData,
      };
      const payload = {
        AIObject,
        mappingArray,
      };
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${API_PATH.DATA_FILE_MAPPING}`,
          payload
        );
        const { success, message } = response.data;
        if (success) {
          toast.success(message);
          setPage("4");
        } else {
          toast.error(message);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOnClick = async (e) => {
    e.preventDefault();
    const form = e.currentTarget.closest("form");
    if (!form) {
      return;
    }

    const formData = new FormData(form);
    const errors = validateProductFieldForm(formData);
    setProductFieldErrors(errors);
    const isValid = validateForm();

    if (isValid && Object.keys(errors).length === 0) {
      const mappingArray = [];
      selectedOptions.forEach((selectedOption, index) => {
        const keys = Object.keys(selectedOption);
        keys.forEach((key) => {
          const option = selectedOption[key];
          if (option) {
            let additionalValue = "";
            let imageType = "";
            let imageList = "";

            const mapping =
              mappingData.find((item) => item.standardField === key) || {};
            const filteredImageType = mappingData.filter((data) =>
              data.standardField.includes("Image")
            );
            const filteredImageList = filteredImageType
              ?.map((data) => ({
                imageList: data.imageList,
                imageType: data.imageType,
                standardField: data.standardField,
              }))
              .filter((data) => data.imageList !== "");
            const radioValue =
              selectedRadio[`0-${key}`] && selectedRadio[`0-${key}`].value
                ? selectedRadio[`0-${key}`].value
                : mapping.imageType || null;
            if (
              key.startsWith("Image") &&
              option.value !== "do_nothing" &&
              option.value !== "hardcode_value" &&
              option.value !== "use_AI" &&
              option.value !== "extract"
            ) {
              additionalValue = selectedRadio[`${index}-${key}`]?.value || "";
              imageType = radioValue || "";

              if (additionalTextValue.length === 0) {
                imageList = filteredImageList[index]?.imageList;
                index++;
              } else {
                const existingImageList = filteredImageList[index]?.imageList;
                const updatedImageList = additionalTextValue[0]?.[key]?.e;
                imageList =
                  updatedImageList !== undefined
                    ? updatedImageList
                    : existingImageList;
                index++;
              }
              if (imageType !== "folder_only") {
                imageList = "";
              }
            } else if (
              key === "Cost_Price" ||
              key === "Retail_Price" ||
              key === "Suggested_Sell_Price"
            ) {
              additionalValue = selectedOption[key]?.additionalValue
                ? selectedOption[key].additionalValue
                : "";
            } else if (
              option.value === "hardcode_value" ||
              option.value === "use_AI" ||
              option.value === "extract"
            ) {
              additionalValue =
                selectedOption[key] && selectedOption[key].additionalValue
                  ? selectedOption[key].additionalValue
                  : "";
            } else {
              imageType = radioValue || "";
            }
            let supplierExtract = "";
            if (option.value === "extract") {
              if (
                typeof selectedOptions === "object" &&
                selectedOptions !== null
              ) {
                supplierExtract = selectedOptions[0]?.[key]?.supplierExtract;
              } else {
                supplierExtract = selectedOptions;
              }
            }
            const mappingObject = {
              supplierId: localStorage.getItem("supplierId"),
              supplierName: localStorage.getItem("supplierName"),
              updatedBy: localStorage.getItem("email"),
              standardField: key,
              standardValue: "",
              supplierField: option.value,
              additionalValue: additionalValue,
              imageType: imageType,
              imageList: imageList,
              supplierExtract: supplierExtract,
            };
            mappingArray.push(mappingObject);
          }
        });
      });

      {
        /*customFieldsData.forEach((customField) => {
        const mappingObject = {
          supplierId: localStorage.getItem("supplierId"),
          supplierName: localStorage.getItem("supplierName"),
          customFieldName: customField.customFieldName,
          customValue: customField.customValue,
          isCustomField: true,
        };
        mappingArray.push(mappingObject);
      });*/
      }

      productRadio.forEach((product) => {
        const additionalValue = selectedRadioPreference || "";
        const additionalValueString =
          typeof additionalValue === "object" ? "" : additionalValue;
        const mappingObject = {
          supplierId: localStorage.getItem("supplierId"),
          supplierName: localStorage.getItem("supplierName"),
        updatedBy: localStorage.getItem("email"),
          standardField: "Preference",
          standardValue: "",
          supplierField: selectedPreference ? selectedPreference.value : "",
          additionalValue: additionalValueString,
        };

        mappingArray.push(mappingObject);
      });

      const supplierName = localStorage.getItem("supplierName");

      const staticMappingObj = {
        supplierId: localStorage.getItem("supplierId"),
        updatedBy: localStorage.getItem("email"),
        supplierName,
        standardField: "supplierName",
        standardValue: "",
        supplierField: "hardcode_value",
        additionalValue: supplierName,
      };

      mappingArray.push(staticMappingObj);
      const AIObject = {
        supplierId: localStorage.getItem("supplierId"),
        updatedBy: localStorage.getItem("email"),
        ...initFormData,
      };
      const payload = {
        AIObject,
        mappingArray,
      };

      setIsLoadingExit(true);
      try {
        const response = await axios.post(
          `${API_PATH.DATA_FILE_MAPPING}`,
          payload
        );
        const { success, message } = response.data;
        if (success) {
          toast.success(message);
          localStorage.removeItem("supplierId");
          localStorage.removeItem("supplierName");
          localStorage.removeItem("currentPage");

          history.push("/supplier");
        } else {
          toast.error(message);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingExit(false);
      }
    }
  };

  const validateForm = () => {
    const errors = [];
    let isValid = true;

    if (selectedOptions.length === 0) {
      errors.push("Please do at least one mapping.");
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const getSupplierDataById = () => {
    const supplierId = localStorage.getItem("supplierId");
    axios
      .get(`${API_PATH.GET_INTEGRATION_INFO_BY_ID}=${supplierId}`)
      .then((response) => {
        const supplierData = response.data.data;
        setFormData(supplierData);

        const csvJSON = supplierData.csvJSON || [];
        const newOptions = [
          ...options,
          ...csvJSON.map((option) => ({
            value: option,
            label: option,
            // disabled: false,
          })),
        ];
        const csvJSON1 = supplierData.csvJSON || [];
        const newOptions1 = [
          ...csvJSON1.map((option) => ({
            value: option,
            label: option,
          })),
        ];

        setOptions(newOptions);
        setCsvOption(newOptions1);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getMappingData = () => {
    const supplierId = localStorage.getItem("supplierId");
    axios
      .get(`${API_PATH.GET_SUPPLIER_FILE_MAPPING}=${supplierId}`)
      .then((response) => {
        if (response.data.data === undefined) {
          setInitFormData((prev) => ({
            ...prev,
            AIDescriptionAndBulletDescriptionPrompt: defaultAIDescription,
            AITitlePrompt: defaultAITitle,
            SystemPrompt: defaultSystemPrompt,
          }));
        }
        const supplierData = response.data.data;
        const productFieldData = supplierData?.product_field_data;
        setFormData(supplierData.supplier_product_field);
        setInitFormData({
          AIDescriptionAndBulletDescriptionPrompt:
            productFieldData.AIDescriptionAndBulletDescriptionPrompt
              ? productFieldData.AIDescriptionAndBulletDescriptionPrompt
              : defaultAIDescription,
          AITitlePrompt: productFieldData.AITitlePrompt
            ? productFieldData.AITitlePrompt
            : defaultAITitle,
          SystemPrompt: productFieldData.SystemPrompt
            ? productFieldData.SystemPrompt
            : defaultSystemPrompt,
        });

        const supplierMapping = supplierData.supplier_product_field;

        // const supplierCustomFields = supplierData.supplier_custom_field;

        const additionalValue = supplierMapping.find(
          (field) => field.standardField === "Preference"
        )?.additionalValue;

        setSlectedRadioPreference(additionalValue);

        const options = {};
        supplierMapping.forEach((field) => {
          const {
            standardField,
            supplierField,
            imageType,
            additionalValue,
            supplierExtract,
            imageList,
          } = field;

          options[standardField] = {
            label: supplierField,
            value: supplierField,
            imageType: imageType,
            additionalValue: additionalValue !== "" ? additionalValue : "",
            supplierExtract: supplierExtract !== "" ? supplierExtract : "",
            imageList,
          };
        });
        setSelectedOptions([options]);
        setMappingData(supplierMapping);
        // setCustomFieldsData(supplierCustomFields);
        const selectedRadioState = {};
        supplierMapping.forEach((field, index) => {
          const { standardField, imageType } = field;
          const radioValue = imageType || null;
          selectedRadioState[`${index}-${standardField}`] = {
            value: radioValue,
          };
        });
        setSelectedRadio(selectedRadioState);

        const additionalPrefillValue = supplierMapping.find(
          (field) => field.standardField === "Preference"
        )?.supplierField;
        setSelectedPreference(additionalPrefillValue);
      })

      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} name="prouctFieldForm">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 button-class">
            <div className="d-flex">
              <button
                className="btn btn-primary w-auto btn-lg mr-2"
                type="submit"
              >
                {isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Please wait...
                  </>
                ) : (
                  "Save & Next"
                )}
              </button>

              <button
                className="btn btn-primary w-auto btn-lg mr-2"
                type="submit"
                onClick={(e) => handleOnClick(e)}
              >
                {isLoadingExit ? (
                  <>
                    <Spinner animation="border" size="sm" /> Please wait...
                  </>
                ) : (
                  "Save & Exit"
                )}
              </button>

              <button
                className="btn btn-secondary w-auto btn-lg"
                type="button"
                onClick={processCancel}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
        <div className="table-container" style={{ position: "relative" }}>
          <DataFileMappingJsx
            formErrors={formErrors}
            productRadio={productRadio}
            selectedRadioPreference={selectedRadioPreference}
            handleProductRadioChange={handleProductRadioChange}
            selectedPreference={selectedPreference}
            setSelectedPreference={setSelectedPreference}
            csvOption={csvOption}
          />

          <table className="w-100 table-responsive-md">
            <thead>
              <tr>
                <th>Product Field</th>
                <th>Value</th>
              </tr>
            </thead>

            {props.loading ? (
              <tbody>
                <tr>
                  <td
                    colSpan="3"
                    className="loader-wrapper"
                    style={{ padding: "2.3rem", width: "100%" }}
                  >
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ padding: "2rem" }}
                    ></i>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                <tr>
                  <td className="w-25">
                    AI Title Prompt{" "}
                    <span className="font-weight-bold text-danger">*</span>
                  </td>
                  <td className="w-75">
                    <textarea
                      className="ai-description-textarea rounded"
                      name="AITitlePrompt"
                      placeholder="Enter AI Title"
                      onChange={handleInputChange}
                      defaultValue={
                        initFormData && initFormData.AITitlePrompt
                          ? initFormData.AITitlePrompt
                          : ""
                      }
                    ></textarea>
                    {productFieldErrors && productFieldErrors.AITitlePrompt && (
                      <span className="text-danger">
                        {productFieldErrors.AITitlePrompt}
                      </span>
                    )}
                    <br />
                    <label className="ml-1 mt-1 text-success">
                      FYI: gpt_features_list -{" "}
                      {`Parent_Title or variant_title, description`}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td className="w-25">
                    AI and Bullet Description Prompt{" "}
                    <span className="font-weight-bold text-danger">*</span>
                  </td>
                  <td className="w-75">
                    <textarea
                      placeholder="Enter AI and Bullet description"
                      className="ai-description-textarea rounded"
                      name="AIDescriptionAndBulletDescriptionPrompt"
                      onChange={handleInputChange}
                      defaultValue={
                        initFormData &&
                        initFormData.AIDescriptionAndBulletDescriptionPrompt
                          ? initFormData.AIDescriptionAndBulletDescriptionPrompt
                          : ""
                      }
                    ></textarea>
                    {productFieldErrors &&
                      productFieldErrors.AIDescriptionAndBulletDescriptionPrompt && (
                        <span className="text-danger">
                          {
                            productFieldErrors.AIDescriptionAndBulletDescriptionPrompt
                          }
                        </span>
                      )}
                    <br />
                    <label className="ml-1 mt-2 text-success">
                      FYI: gpt_features_list -{" "}
                      {`Parent_Title or variant_title, description`}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td className="w-25">
                    <div className="d-flex align-items-center">
                      <div>
                        System Prompt
                        <span className="font-weight-bold text-danger">*</span>
                      </div>
                      <OverlayTrigger placement="left" overlay={tooltip}>
                        <i
                          className="ml-2 fa fa-info-circle"
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            fontSize: "18px",
                            marginTop: "2px",
                          }}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td className="w-75">
                    <textarea
                      type="text"
                      className="ai-description-textarea rounded"
                      name="SystemPrompt"
                      placeholder="Enter System Prompt"
                      onChange={handleInputChange}
                      defaultValue={
                        initFormData && initFormData.SystemPrompt
                          ? initFormData.SystemPrompt
                          : ""
                      }
                    ></textarea>
                    {productFieldErrors && productFieldErrors.SystemPrompt && (
                      <span className="text-danger">
                        {productFieldErrors.SystemPrompt}
                      </span>
                    )}
                  </td>
                </tr>
              </>
            )}
          </table>

          <table className="w-100 table-responsive-md">
            <thead>
              <tr>
                <th>Product Field</th>
                <th>Value</th>
                <th>Additional Info</th>
                <th>Extract Value</th>
              </tr>
            </thead>

            {props.loading ? (
              <tbody>
                <tr>
                  <td
                    colSpan="3"
                    className="loader-wrapper"
                    style={{ padding: "2.3rem", width: "100%" }}
                  >
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ padding: "2rem" }}
                    ></i>
                  </td>
                </tr>
              </tbody>
            ) : (
              <ProductFieldsMappingJsx
                productFields={productFields}
                selectedOptions={selectedOptions}
                mappingData={mappingData}
                selectedRadio={selectedRadio}
                handleRadioChange={handleRadioChange}
                handleFieldChange={handleFieldChange}
                handleAdditionalValueChange={handleAdditionalValueChange}
                options={options}
                handleRadioTextChange={handleRadioTextChange}
                additionalTextValue={additionalTextValue}
                csvOption={csvOption}
                handleExtractChange={handleExtractChange}
              />
            )}
          </table>
          {/* <CustomFields
            customFieldsData={customFieldsData}
            setCustomFieldsData={setCustomFieldsData}
              />*/}
        </div>
      </form>
    </>
  );
}

const mapStateToProps = ({ LoadingReducer }) => ({
  loading: LoadingReducer.isLoading,
});
export default connect(mapStateToProps, { onLoading })(SuppilerPage3);
