import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import NavbarMenu from "./components/NavbarMenu";
import Page404 from "./screens/Auth/Page404";
import { onLoggedin, getUser } from "./actions";
import Private404 from "./screens/private404/Private404";
import Protected from "./routers/RouteSecurity";
import Dashbord from "./screens/Dashbord/Dashbord";
import Login from "./screens/Login";
import ioT from "./screens/Dashbord/IoT";
import appChat from "./screens/App/Chat";
import appCalendar from "./screens/App/Calendar";
import appContact from "./screens/App/Contact";
import filemanagerdashboard from "./screens/FileManager/Dashboard";
import filedocuments from "./screens/FileManager/Documents";
import filemedia from "./screens/FileManager/Media";
import fileimages from "./screens/FileManager/Images";
import lockscreen from "./screens/Auth/Lockscreen";
import forgotpassword from "./screens/Auth/ForgotPassword";
import page404 from "./screens/Auth/Page404";
import page403 from "./screens/Auth/Page403";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import echart from "./screens/Charts/Echart";
import ManageCompany from "./screens/ManageCompany/ManageCompany";
import ResetPassword from "./screens/Auth/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import CompanyList from "./screens/CompanyList/CompanyList";
import RetailerList from "./screens/RetailerList/RetailerList";
import ManageRetailer from "./screens/ManageRetailer/ManageRetailer";
import UserList from "./screens/userList/UserList";
import ManageUser from "./screens/ManageUser/ManageUser";
import ManageUserPermission from "./screens/ManageUser/ManageUserPermission";
import PublicRoute from "./routers/PublicRoute";
import UpdateProfile from "./screens/UpdateProfile/UpdateProfile";
import RetailerProfile from "./screens/RetailerProfile/RetailerProfile";
import SupplierList from "./screens/SuppilerList/SuppilerList";
import ManageSuppiler from "./screens/ManageSuppiler/ManageSuppiler";
import IntegrationType from "./screens/Integrations/IntegrationType";
import ManageMarketPlace from "./screens/ManageMarketPlace/ManageMarketPlace";
import MarketPlaceList from "./screens/ManageMarketPlace/MarketPlaceList";
import IntegratorList from "./screens/ManageIntegrator/IntegratorList";
import ManageIntegrator from "./screens/ManageIntegrator/ManageIntegrator";
import ManageRetailerSetting from "./screens/ManageRetailer/ManageRetailerSetting";
import ProductsList from "./screens/products/ProductsList";
import FileUpload from "./screens/Logs/FileUpload";
import RetailerSettingList from "./screens/ManageRetailer/RetailerSettingList";
import ManageProduct from "./screens/ManageProduct/ManageProduct";
import ApiLogs from "./screens/Logs/ApiLogs";
import ExportCSV from "./screens/exportCSV/ExportCsv";
import OrderLogs from "./screens/Logs/OrderLogs";
import Profile from "./screens/Profile/Profile";
import { UserProvider } from "./context/UserContext";
import UploadFailedData from "./screens/Logs/UploadFailedData";
import ExportLogs from "./screens/Logs/ExportLogs";
import ManageVariant from "./screens/ManageVariants/ManageVariant";
import ChangePassword from "./screens/Profile/ChangePassword";
import PromptKeysList from "./screens/PromptSetting/PromptKeysList"
import AddPromptKey from "./screens/ManagePrompt/AddPromptKey"
import AddPromptList from "./screens/PromptSetting/AddPromptList"
import ManagePrompt from "./screens/ManagePrompt/ManagePrompt"
import TestPrompt from "./screens/ManagePrompt/TestPrompt"
import GeminiPromptHistory from "./screens/ManagePrompt/GeminiPromptHistory";
import PromptHistory from "./screens/PromptSetting/PromptHistory";
window.__DEV__ = true;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
    };
    const token = localStorage.getItem("token");
    if (token) {
      this.props.onLoggedin(true);
      this.props.getUser(token);
    } else {
      this.props.onLoggedin(false);
    }
  }
  render() {
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";
    const activeKey1 = res;

    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div
          className="page-loader-wrapper"
          style={{ display: this.props.systemLoading ? "block" : "none" }}
        >
          <div className="loader">
            <div className="m-t-30">
              <img
                src={require("./assets/images/refreshLogo.png")}
                width="48"
                height="48"
                alt="Refresh"
              />
            </div>
            <p>Please wait...</p>
          </div>
        </div>
        <PublicRoute>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Login} />
            <Route exact path={`/forgotpassword`} component={forgotpassword} />
            <Route path={`/reset-password`} component={ResetPassword} />
            <Route exact path={`/page404`} component={page404} />
            <Route exact path={`/page403`} component={page403} />
            <Route exact path={`/page500`} component={page500} />
            <Route exact path={`/page503`} component={page503} />
            <Route exact path={`/lockscreen`} component={lockscreen} />
            <Route exact path="*" component={page404} />

            {/* <Route exact path={`/registration`} component={registration} /> */}
          </Switch>
        </PublicRoute>

        <Switch>
          <Protected>
            <UserProvider>
              <NavbarMenu />
              <div id="wrapper">
                {/* <Redirect exact from="/" to={'/dashboard'} /> */}
                {this.props.user?.data.role == "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route exact path={`/dashboard`} component={Dashbord} />
                    <Route exact path={`/`} component={Dashbord} />
                  </>
                ) : null}
                <Route exact path={`/ioT`} component={ioT} />
                <Route exact path={`/appchat`} component={appChat} />
                <Route exact path={`/appcalendar`} component={appCalendar} />
                <Route exact path={`/appcontact`} component={appContact} />
                <Route
                  exact
                  path={`/filemanagerdashboard`}
                  component={filemanagerdashboard}
                />
                <Route
                  exact
                  path={`/filedocuments`}
                  component={filedocuments}
                />
                <Route exact path={`/filemedia`} component={filemedia} />
                <Route exact path={`/fileimages`} component={fileimages} />
                
                <Route exact path={`/echart`} component={echart} />

                <Route exact path={`/profile`} component={Profile} />
                <Route
                  exact
                  path={`/change-password`}
                  component={ChangePassword}
                />
                <Route
                  exact
                  path={`/company-profile`}
                  component={UpdateProfile}
                />
                <Route
                  exact
                  path={`/retailer-profile`}
                  component={RetailerProfile}
                />
                {this.props.user?.data.role == "SUPER_ADMIN" ? (
                  <>
                    <Route exact path={`/company`} component={CompanyList} />
                    <Route
                      exact
                      path={`/manage-company`}
                      component={ManageCompany}
                    />
                  </>
                ) : null}

                {this.props.user?.data.role == "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN"  ? (
                  <>
                    <Route exact path={`/retailer`} component={RetailerList} />
                    {this.props.user?.permissions.update_retailer ||
                    this.props.user?.permissions.add_retailer ? (
                      <>
                        <Route
                          exact
                          path={`/manage-retailer`}
                          component={ManageRetailer}
                        />
                        <Route
                          exact
                          path={`/setting-retailer-list`}
                          component={RetailerSettingList}
                        />
                        <Route
                          exact
                          path={`/setting-retailer`}
                          component={ManageRetailerSetting}
                        />
                      </>
                    ) : null}
                  </>
                ) : null}

                {this.props.user?.data.role == "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN"  ? (
                  <>
                    <Route exact path={`/user`} component={UserList} />
                    
                      <Route
                        exact
                        path={`/manage-user`}
                        component={ManageUser}
                      />
                      <Route
                        exact
                        path={`/user-permission`}
                        component={ManageUserPermission}
                      />
                  </>
                ) : null}
                {this.props.user?.data.role == "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route
                      exact
                      path={`/integration`}
                      component={IntegrationType}
                    />
                  </>
                ) : null}
                {this.props.user?.data.role == "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route exact path={`/supplier`} component={SupplierList} />
                    <Route
                      exact
                      path={`/manage-supplier`}
                      component={ManageSuppiler}
                    />
                  </>
                ) : null}

                {this.props.user?.data.role == "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route
                      exact
                      path={`/market-place`}
                      component={MarketPlaceList}
                    />
                    <Route
                      exact
                      path={`/manage-marketPlace`}
                      component={ManageMarketPlace}
                    />
                  </>
                ) : null}
                {this.props.user?.data.role == "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route
                      exact
                      path={`/integrator`}
                      component={IntegratorList}
                    />
                    <Route
                      exact
                      path={`/manage-integrator`}
                      component={ManageIntegrator}
                    />
                  </>
                ) : null}

                {this.props.user?.data.role === "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route exact path={`/products`} component={ProductsList} />
                    <Route
                      exact
                      path={`/product-details/:id`}
                      component={ManageProduct}
                    />
                    <Route
                      exact
                      path={`/variants-details/:id`}
                      component={ManageVariant}
                    />
                  
                  </>
                ) : null}

                {this.props.user?.data.role === "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route exact path={`/apilogs`} component={ApiLogs} />
                    <Route exact path={`/orderlogs`} component={OrderLogs} />
                    <Route
                      exact
                      path={`/exportdatalogs`}
                      component={ExportLogs}
                    />
                    <Route exact path={`/file-upload`} component={FileUpload} />
                    <Route
                      exact
                      path={`/upload-failed-data`}
                      component={UploadFailedData}
                    />
                  </>
                ) : null}
                {this.props.user?.data.role === "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route exact path={`/cancelOrder`} component={ExportCSV} />
                  </>
                ) : null}

                {this.props.user?.data.role === "SUPER_ADMIN" ||
                this.props.user?.data.role == "COMPANY_ADMIN" ||
                this.props.user?.data.role == "RETAILER_ADMIN" ||
                this.props.user?.data.role == "RETAILER_USER" ? (
                  <>
                    <Route exact path={`/prompt-keys`} component={PromptKeysList} />
                    <Route exact path={`/add-prompt-key`} component={AddPromptKey} />
                    <Route exact path={`/prompt-list`} component={AddPromptList} />
                    <Route exact path={`/manage-prompt`} component={ManagePrompt} />
                    <Route exact path={`/test-prompt`} component={TestPrompt} /> 
                    <Route exact path ={`/error-prompt`} component= {GeminiPromptHistory}/>
                    <Route exact path ={`/prompt-history`} component={PromptHistory}/>
                  </>
                ) : null}
              </div>
            </UserProvider>
          </Protected>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = ({ loginReducer, LoadingReducer }) => ({
  isLoggedin: loginReducer.isLoggedin,
  user: loginReducer.user,
  systemLoading: LoadingReducer.systemLoading,
});

export default withRouter(
  connect(mapStateToProps, { onLoggedin, getUser })(App)
);
