import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_PATH } from "../ApiPath/Apipath";
import { toast } from "react-toastify";
import "./PromptKey.css";
import { connect } from "react-redux";
import { onLoading } from "../../actions";
import { promptGuideLines } from "../utils/constants";
import AddBasicPropmptJsx from "./AddBasicPropmptJsx";
import PromptGuidelineMode from "./PromptGuidelineMode";

function AddPromptBasicDetails(props) {
  const { setPage } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [promptValue, setPromptValue] = useState("");
  const [oldPromptValue, setOldPromptValue] = useState("");
  const [isPromptValid, setIsPromptValid] = useState(false);
  const [productAttributes, setProductAttributes] = useState([]);
  const [registeredPromptKeys, setRegisteredPromptKeys] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getSupplierData();
    fetchProductAttributes();
    fetchRegisteredPromptKeys();
  }, []);

  useEffect(() => {
    if (supplierList.length > 0) {
      getPromptDataById();
    }
  }, [supplierList]);


  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const getSupplierData = () => {
    try {
      axios
        .get(`${API_PATH.GET_RETAILER_SUPPLIER_LIST}`)
        .then((response) => {
          const { success, message, data } = response.data;
          if (success) {
            const options = Object.keys(data).map((supplierName) => ({
              value: data[supplierName],
              label: supplierName,
            }));
            setSupplierList(options);
          } else {
            toast.error(message);
          }
        })
        .catch((error) => {
          console.error("Failed to retrieve supplier list:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getPromptDataById = () => {
    const promptKeyId = localStorage.getItem("addPromptId");
    setFormLoader(true);
    axios
      .post(`${API_PATH.GET_PROMTPT_VAL_BY_ID}`, {
        id: promptKeyId,
      })
      .then((response) => {
        const promptData = response.data.data;
        if (promptData) {
          if (promptData.GeminiPrompt) {
            setPromptValue(promptData.GeminiPrompt);
            setOldPromptValue(promptData.GeminiPrompt)
          }
          const selectedSupplier = supplierList.find(
            (option) => option.value === promptData.supplierId
          );
          if (selectedSupplier) {
            setSelectedOptions(selectedSupplier);
          } else {
            console.error("Supplier not found for ID:", promptData.supplierId);
          }
        } else {
          console.error("Prompt data is undefined or null");
        }
      })
      .catch((error) => {
        console.error("error", error);
      })
      .finally(() => {
        setFormLoader(false);
      });
  };

  const fetchProductAttributes = () => {
    axios
      .post(API_PATH.GET_PRODUCT_ATTRIBUTES)
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          setProductAttributes(data);
        } else {
          console.error("Failed to fetch product attributes");
        }
      })
      .catch((error) => {
        console.error("Error fetching product attributes:", error);
      });
  };

  const fetchRegisteredPromptKeys = () => {
    axios
      .get(API_PATH.GET_ALL_PROMPT_KEY_LIST)
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          setRegisteredPromptKeys(data.map((item) => item.prompt_key));
        } else {
          toast.error("Failed to fetch registered prompt keys");
        }
      })
      .catch((error) => {
        console.error("Error fetching registered prompt keys:", error);
      });
  };

  const handlePromptChange = (e) => {
    setPromptValue(e.target.value);
  };

  function capitalizeTerm(term) {
    const standardTerms = ["SKU", "ASIN", "GTIN", "ISBN", "EAN", "UPC", "SEO"];
    const termParts = term.split("_");
    return termParts
      .map((part) => {
        const upperPart = part.toUpperCase();
        if (standardTerms.includes(upperPart)) {
          return upperPart;
        }
        // Capitalize the first letter and lowercase the rest
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
      })
      .join("_");
  }

  const parsePromptValue = (text) => {
    // Normalize line breaks
    text = text.replace(/\r\n/g, "\n");
    // Split text by lines and commas
    const lines = text.split(/[\n,-]/);
    const attributes = {};
    const attributeRegex = /^\s*([\w\s\/,&-]+):/;

    lines.forEach((line) => {
      // Trim and clean each line
      const cleanLine = line.trim();
      const match = cleanLine.match(attributeRegex);
      if (match) {
        // Normalize the attribute name
        let originalName = match[1].trim();
        let normalizedName = originalName
          .replace(/\s+/g, "_") // Replace spaces with underscores
          .replace(/[\/,&-]/g, "") // Remove /, &, and -
          .toLowerCase();

        normalizedName = normalizedName
          .split("_")
          .map(
            (word) => capitalizeTerm(word) // Capitalize certain terms
          )
          .join("_");
        attributes[normalizedName] = originalName;
      }
    });

    return attributes;
  };

  const normalizeString = (str) => {
    let normalizedName = str
    .replace(/\s+/g, "_") // Replace spaces with underscores
    .replace(/[\/,&-]/g, "") // Remove /, &, and -
    .replace(/__+/g, "_") // Replace multiple underscores with a single underscore
    .replace(/_+$/, "") // Remove trailing underscores
    .toLowerCase();

    normalizedName = normalizedName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("_");

    return normalizedName;
  };

  const validatePromptValue = async () => {
    const parsedAttributes = parsePromptValue(promptValue);
    const hasColon = promptValue.includes(":");
  
    if (!hasColon) {
      setErrorMessage(
        "When entering prompts, please ensure to include a colon after each attribute, for example color:red"
      );
      // clearErrorMessageAfterDelay();
      return;
    }
  
    const missingAttributes = [];
    const normalizedRegisteredKeys = registeredPromptKeys.map(normalizeString);
  
    // Check for missing attributes
    for (const key in parsedAttributes) {
      const normalizedKey = normalizeString(key);
      if (!normalizedRegisteredKeys.includes(normalizedKey)) {
        missingAttributes.push(key); // Add original attribute name
      }
    }
  
    if (missingAttributes.length > 0) {
      const normalizedMissingAttributes = missingAttributes.map(normalizeString); // Normalize missing attribute names
      const formattedMissingAttributes = normalizedMissingAttributes.join(", "); // Concatenate missing attributes with commas
 
      const message = `In this prompt, the attribute${missingAttributes.length > 1 ? 's' : ''} ${formattedMissingAttributes} ${missingAttributes.length > 1 ? 'are' : 'is'} missing. Please register this/these attribute(s) in the Prompt Key section. <a href="/prompt-keys"><u>Click Here</u></a>`;
      setErrorMessage(message);
      // clearErrorMessageAfterDelay();
    } else {
      setIsLoading(true);
      // Prepare the Gemini prompt string
      const geminiPrompt = Object.entries(parsedAttributes)
        .map(([key, value]) => `${key}:${value}`)
        .join(", ");
  
      try {
        // Make a POST request to the API for validation
        const response = await axios.post(API_PATH.VALIDATE_PROMPT, {
          gemini_prompt: geminiPrompt,
        });
  
        if (response.data.success) {
          setIsPromptValid(true);
          toast.success(response.data.message || "Prompt is valid!");
          setIsLoading(false);
        } else {
          setIsPromptValid(false);
          toast.error(response.data.message || "Failed to validate prompt");
          setIsLoading(false);
        }
      } catch (error) {
        setIsPromptValid(false);
        setIsLoading(false);
        toast.error("Failed to validate prompt");
        console.error("Error validating prompt:", error);
      }
    }
  };
  
  const clearErrorMessageAfterDelay = () => {
    // setTimeout(() => {
    //   setErrorMessage("");
    // }, 10000); // 10 seconds delay
  };
  const handleCopyToClipboard = async (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(`{${text}}`);
      // toast.success("Copied to clipboard!");
    } else {
      const tempInput = document.createElement("input");
      const textWithCurlyBraces = `{${text}}`;
      tempInput.value = textWithCurlyBraces;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      // toast.success("Copied to clipboard!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingExit(true);

      const payload = {
        id: localStorage.getItem("addPromptId") || "",
        supplierId: selectedOptions.value,
        email:localStorage.getItem("email"),
        old_gemini_prompt: oldPromptValue,
        gemini_prompt: promptValue, 
      };
      const response = await axios.post(API_PATH.ADD_PROMPT_VALUE, payload);
      if (response.data.success) {
        localStorage.setItem("addPromptId", response.data?.id);
        localStorage.setItem("supplierId", payload.supplierId);
        setPage("2");
        setIsLoadingExit(false);
        toast.success(response.data.message);
      } else {
        setIsLoadingExit(false);
        toast.error(response.data.message || "Failed to save prompt details");
      }
    } catch (error) {
      setIsLoadingExit(false);
      toast.error("Failed to save prompt details");
      console.error("Error saving prompt details:", error);
    }
  };

  const calculateTextAreaHeight = () => {
    const minHeightPerRow = 40;
    const keyCount = registeredPromptKeys.length;
    let height = Math.max(minHeightPerRow * keyCount, minHeightPerRow * 12);
    return `${height}px`;
  };
  const handleCloseErrorMessage = () => {
    setErrorMessage("");
  };
  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <form name="myForm" encType="multipart/form-data" onSubmit={handleSubmit}>
        <div style={{ marginTop: "15px" }}>
          <div
            className="alert alert-primary col-12 mt-3 d-flex justify-content-between align-items-center"
            role="alert"
          >
            <div>
              <strong>INFO:</strong> {"  "}
              {promptGuideLines}
            </div>
            <button
              className="btn btn-primary w-auto btn-lg button-class"
              type="button"
              onClick={() => openModal()}
            >
              Prompt Guidelines
            </button>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 button-class">
              <div className="d-flex">
                <button
                  className="btn btn-primary w-auto btn-lg mr-2"
                  type="button"
                  onClick={validatePromptValue}
                  
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    "Validate Prompt"
                  )}
                </button>
                <button
                  className="btn btn-primary w-auto btn-lg mr-2"
                  type="submit"
                  disabled={!isPromptValid}
                >
                  {isLoadingExit ? (
                    <>
                      <Spinner animation="border" size="sm" /> Please wait...
                    </>
                  ) : (
                    "Save & Next"
                  )}
                </button>
              </div>
            </div>
          </div>

          {formLoader && (
            <div className="loader-wrapper">
              <i className="fa fa-refresh fa-spin"></i>
            </div>
          )}
          <div className="row mt-3 mt-lg-0">
            {/*<div className="alert alert-primary col-12 mt-3" role="alert">
              <strong>INFO:</strong> <br />
              {validatePromptVal}
        </div>*/}
            <div className="col-sm-12">
              <div className="form-group align-items-center">
                <label htmlFor="combo-box-demo">Select Supplier </label>
                <div style={{ flex: 1 }}>
                  <Select
                    options={supplierList}
                    onChange={handleSelectChange}
                    value={selectedOptions}
                    placeholder="Select Supplier"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="">
            {errorMessage && (
              <div className="alert alert-danger col-12 mt-3" role="alert">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseErrorMessage}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "10px",
                    background: "transparent",
                    border: "none",
                    fontSize: "1.5rem",
                    lineHeight: "1",
                    color: "#000",
                    cursor: "pointer",
                  }}
                >
                  &times;
                </button>
                <strong>Please Verify It: </strong>
                <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
              </div>
            )}
           

            <AddBasicPropmptJsx
              props={props}
              productAttributes={productAttributes}
              promptValue={promptValue}
              handlePromptChange={handlePromptChange}
              calculateTextAreaHeight={calculateTextAreaHeight}
              registeredPromptKeys={registeredPromptKeys}
              handleCopyToClipboard={handleCopyToClipboard}
            />
            <PromptGuidelineMode
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
const mapStateToProps = ({ LoadingReducer }) => ({
  isLoading: LoadingReducer.isLoading,
});
export default connect(mapStateToProps, { onLoading })(AddPromptBasicDetails);
