const MintoPricingValidation = ({
  rowCount,
  selectedCurrencies,
  initFormData,
  setValidationErrors,
}) => {
  let isValid = true;

  for (let mainIndex = 0; mainIndex < rowCount; mainIndex++) {
    if (!selectedCurrencies[mainIndex]) {
      setValidationErrors((prevValidationErrors) => {
        const errors = [...prevValidationErrors];
        errors[mainIndex] = { currency: "Please select Currency." };
        return errors;
      });
      isValid = false;
    } else {
      // Clear currency validation error when valid
      setValidationErrors((prevValidationErrors) => {
        const errors = [...prevValidationErrors];
        errors[mainIndex] = { currency: "" };
        return errors;
      });
    }
    const msrpPrices = initFormData?.allCurrency?.[mainIndex]?.msrpPrice;
    const costPriceField =
      msrpPrices && msrpPrices.length > 0 ? msrpPrices[0].costPriceField : null;
    if (!costPriceField) {
      setValidationErrors((prevValidationErrors) => {
        const errors = [...prevValidationErrors];
        errors[mainIndex] = {
          ...errors[mainIndex],
          costPriceField: "Please select Price.",
        };
        return errors;
      });
      isValid = false;
    } else {
      // Clear costPriceField validation error when valid
      setValidationErrors((prevValidationErrors) => {
        const errors = [...prevValidationErrors];
        errors[mainIndex] = {
          ...errors[mainIndex],
          costPriceField: null,
        };
        return errors;
      });
    }

    // const tierPrices = initFormData?.allCurrency?.[mainIndex]?.tierPrice;
    // if (tierPrices) {
    //   tierPrices.forEach((tierPrice, subIndex) => {
    //     Object.keys(tierPrice).forEach((fieldName) => {
    //       const value = tierPrice[fieldName];
    //       if (!value || (typeof value === "string" && value.trim() === "")) {
    //         setValidationErrors((prevValidationErrors) => {
    //           const errors = [...prevValidationErrors];
    //           if (!errors[mainIndex]) {
    //             errors[mainIndex] = {};
    //           }
    //           if (!errors[mainIndex][subIndex]) {
    //             errors[mainIndex][subIndex] = {};
    //           }
    //           errors[mainIndex][subIndex][fieldName] =
    //             "This field is required.";
    //           return errors;
    //         });
    //         isValid = false;
    //       } else {
    //         // Clear tierPrice validation error for the specific field when valid
    //         setValidationErrors((prevValidationErrors) => {
    //           const errors = [...prevValidationErrors];
    //           if (!errors[mainIndex]) {
    //             errors[mainIndex] = {};
    //           }
    //           if (!errors[mainIndex][subIndex]) {
    //             errors[mainIndex][subIndex] = {};
    //           }
    //           errors[mainIndex][subIndex][fieldName] = null;
    //           return errors;
    //         });
    //       }
    //     });
    //   });
    // }

    const msrpFieldNames = [
      "exchangeRateFactor",
      "landedCostFactor",
      "shipping",
      "pricingFactor",
    ];
    msrpFieldNames.forEach((fieldName) => {
      const msrpPrice = initFormData?.allCurrency?.[mainIndex]?.msrpPrice;
      const value = msrpPrice && msrpPrice[0]?.[fieldName];
      if (!value || (typeof value === "string" && value.trim() === "")) {
        setValidationErrors((prevValidationErrors) => {
          const errors = [...prevValidationErrors];
          errors[mainIndex] = {
            ...errors[mainIndex],
            [fieldName]: "This field is required.",
          };
          return errors;
        });
        isValid = false;
      } else {
        // Clear msrpPrice validation error for the specific field when valid
        setValidationErrors((prevValidationErrors) => {
          const errors = [...prevValidationErrors];
          errors[mainIndex] = {
            ...errors[mainIndex],
            [fieldName]: null,
          };
          return errors;
        });
      }
    });
  }
  return isValid;
};

export default MintoPricingValidation;
