import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userName, setUserName] = useState(localStorage.getItem('name') || '');
  const [userLogo, setUserLogo] = useState(localStorage.getItem('userLogo') || '');

  const updateUserProfileName = (name) => {
    setUserName(name);
    localStorage.setItem('name', name);
  };

  const updateUserProfileLogo = (logo) => {
    setUserLogo(logo);
    localStorage.setItem('userLogo', logo);
  };

  return (
    <UserContext.Provider value={{ userName, userLogo, updateUserProfileName, updateUserProfileLogo }}>
      {children}
    </UserContext.Provider>
  );
};
