import React, { useContext, useEffect, useState } from "react";
import { Accordion, Card, Spinner } from "react-bootstrap";
import { FormContext } from "./ManageRetailerSetting";
import Select from "react-select";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { API_PATH } from "../ApiPath/Apipath";
import axios from "axios";
import { connect } from "react-redux";
import { onLoading } from "../../actions";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Retailer.css";
import MintoPricingValidation from "./MintoPricingValidation";
import Swal from "sweetalert2";
import MiintoPricingSubField from "./MiintoPricingSubField";
import MiintoPricingFillUpSubFields from "./MiintoPricingFillUpSubFields";
import MiinotPricingMsrpField from "./MiinotPricingMsrpField";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function MintoPricing(props) {
  const { setPage } = props;
  const { processCancel } = useContext(FormContext);
  const [initFormData, setInitFormData] = useState([]);
  const [rowCount, setRowCount] = useState(1);
  const [selectedCurrencies, setSelectedCurrencies] = useState({});
  const [price, setPrice] = useState([]);
  const [defaultPrices, setDefaultPrices] = useState(null);
  const [subRowCount, setSubRowCount] = useState([1]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [validationErrors, setValidationErrors] = useState([{}]);
  const [isChecked, setIsChecked] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [isPlusIconEnabled, setIsPlusIconEnabled] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  console.log("open----------", isAccordionOpen);
  const history = useHistory();

  useEffect(() => {
    getPrice();
    getCurrency();
    getCurrencyData();
  }, []);
  useEffect(() => {
    // Check if initFormData has data
    if (initFormData && initFormData.allCurrency) {
      const currencies = {};
      initFormData.allCurrency.forEach((currencyData, index) => {
        if (currencyData.currency) {
          currencies[index] = {
            label: currencyData.currency,
            value: currencyData.currency,
          };
        }
      });
      setSelectedCurrencies(currencies);
    }
  }, [initFormData]);
  useEffect(() => {
    // Check if initFormData.allCurrency.currency is available
    if (initFormData?.allCurrency && initFormData?.allCurrency.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [initFormData]);

  // const getPrice = () => {
  //   try {
  //     axios
  //       .get(`${API_PATH.GET_RETAILER_PRICE_LIST}`)
  //       .then((response) => {
  //         const { success, message, data } = response.data;
  //         if (success) {
  //           const options = Object.keys(data).map((price) => ({
  //             value: data[price],
  //             label: price,
  //           }));
  //           setPrice(options);
  //         } else {
  //           toast.error(message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Failed to retrieve supplier list:", error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getPrice = () => {
    try {
      axios
        .get(`${API_PATH.GET_RETAILER_PRICE_LIST}`)
        .then((response) => {
          const { success, message, data } = response.data;
          console.log("data---", data);
          if (success) {
            // Set default prices for tierPrice and msrpPrice
            setDefaultPrices({
              tierPrice: "Cost_Price",
              msrpPrice: "Retail_Price",
            });
            const options = Object.keys(data).map((price) => ({
              value: data[price],
              label: price,
            }));
            setPrice(options);
          } else {
            toast.error(message);
          }
        })
        .catch((error) => {
          console.error("Failed to retrieve supplier list:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const getCurrency = async () => {
    try {
      const response = await axios.get(`${API_PATH.GET_MIINTO_CURRENCY_LIST}`);

      if (response.data.success) {
        setCurrencyList(response.data.data);
      } else {
        // Handle error if needed
        console.error("API request failed:", response.data.error);
      }
    } catch (error) {
      // Handle network error or other issues
      console.error("Error during API request:", error.message);
    }
  };
  const getCurrencyData = async () => {
    try {
      // Get retailerIntegrationId and supplierId from localStorage
      const retailerIntegrationId = localStorage.getItem(
        "retailerIntegrationId"
      );
      const supplierId = localStorage.getItem("supplierSettingId");

      if (!retailerIntegrationId || !supplierId) {
        // Handle the case where the IDs are not available in local storage
        return;
      }
      const requestBody = {
        retailerIntegrationId,
        supplierId,
      };
      const response = await axios.post(
        `${API_PATH.GET_MIINTO_CURRENCY_DATA}`,
        requestBody
      );
      setInitFormData(response.data.data);
      setRowCount(response.data.data.allCurrency.length);
    } catch (error) {}
  };
  const handleSelectChangeCurrency = (selectedOptions, mainIndex) => {
    setInitFormData((prevInitFormData) => {
      const updatedFormData = { ...prevInitFormData }; // Copy the previous state

      // Ensure the existence of the main field
      if (!updatedFormData.allCurrency) {
        updatedFormData.allCurrency = [];
      }

      if (!selectedOptions) {
        // Handle the case where no currency is selected
        setValidationErrors((prevValidationErrors) => {
          const errors = [...prevValidationErrors];
          errors[mainIndex] = { currency: "Please select Currency." };
          return errors;
        });
      } else {
        // Clear the validation error when a currency is selected
        setValidationErrors((prevValidationErrors) => {
          const errors = [...prevValidationErrors];
          errors[mainIndex] = { currency: null };
          return errors;
        });

        if (!updatedFormData.allCurrency[mainIndex]) {
          updatedFormData.allCurrency[mainIndex] = {
            currency: selectedOptions.value,
          };
        } else {
          // Ensure the existence of tierPrice array
          if (!updatedFormData.allCurrency[mainIndex].tierPrice) {
            updatedFormData.allCurrency[mainIndex].tierPrice = [];
          }

          // Update the selected currency for the current row
          updatedFormData.allCurrency[mainIndex].currency =
            selectedOptions.value;
        }

        // Update the state with the modified data
        setSelectedCurrencies((prevSelectedCurrencies) => {
          const updatedSelectedCurrencies = { ...prevSelectedCurrencies };
          updatedSelectedCurrencies[mainIndex] = selectedOptions;
          return updatedSelectedCurrencies;
        });
      }

      return updatedFormData;
    });
  };
  const selectOptions = currencyList.map((currency) => ({
    label: currency,
    value: currency,
  }));
  const handleSelectChange = (selectedOption, mainIndex, subIndex) => {
    setInitFormData((prevInitFormData) => {
      const updatedFormData = {
        allCurrency: (prevInitFormData?.allCurrency || []).map((currency) => ({
          ...currency,
        })),
      };

      if (!updatedFormData.allCurrency[mainIndex]) {
        updatedFormData.allCurrency[mainIndex] = {
          tierPrice: [],
          msrpPrice: [],
        };
      }

      if (typeof subIndex !== "undefined") {
        if (!updatedFormData.allCurrency[mainIndex].tierPrice) {
          updatedFormData.allCurrency[mainIndex].tierPrice = [];
        }

        if (!updatedFormData.allCurrency[mainIndex].tierPrice[subIndex]) {
          updatedFormData.allCurrency[mainIndex].tierPrice[subIndex] = {};
        }

        const { label } = selectedOption;
        updatedFormData.allCurrency[mainIndex].tierPrice[
          subIndex
        ].costPriceField = label;

        // Clear validation error for tierPrice costPriceField when valid
        setValidationErrors((prevValidationErrors) => {
          const errors = [...prevValidationErrors];
          errors[mainIndex] = {
            ...errors[mainIndex],
            tierPrice: errors[mainIndex]?.tierPrice || [],
          };
          errors[mainIndex].tierPrice[subIndex] = {
            ...errors[mainIndex]?.tierPrice?.[subIndex],
            costPriceField: null,
          };
          return errors;
        });
      } else {
        updatedFormData.allCurrency[mainIndex].msrpPrice = [];

        const { label } = selectedOption;
        const msrpPriceObj = {
          costPriceField: label,
          // Add other properties as needed
        };
        updatedFormData.allCurrency[mainIndex].msrpPrice.push(msrpPriceObj);

        // Clear validation error for msrpPrice costPriceField when valid
        setValidationErrors((prevValidationErrors) => {
          const errors = [...prevValidationErrors];
          errors[mainIndex] = {
            ...errors[mainIndex],
            costPriceField: null,
          };
          return errors;
        });
      }

      return updatedFormData;
    });
  };
  const handleInputChange = (e, mainIndex, subIndex) => {
    const { name, value } = e.target;

    setInitFormData((prevInitFormData) => {
      const updatedFormData = { allCurrency: [] };

      if (
        prevInitFormData?.allCurrency &&
        prevInitFormData?.allCurrency.length > 0
      ) {
        updatedFormData.allCurrency = [...prevInitFormData?.allCurrency];
      }

      // Ensure the existence of the main field
      if (!updatedFormData.allCurrency[mainIndex]) {
        updatedFormData.allCurrency[mainIndex] = {
          tierPrice: [],
          msrpPrice: [],
        };
      }

      if (typeof subIndex !== "undefined") {
        // Ensure the existence of the tierPrice array
        if (!updatedFormData.allCurrency[mainIndex].tierPrice) {
          updatedFormData.allCurrency[mainIndex].tierPrice = [];
        }

        // Ensure the existence of the subFields array
        if (!updatedFormData.allCurrency[mainIndex].tierPrice[subIndex]) {
          updatedFormData.allCurrency[mainIndex].tierPrice[subIndex] = {};
        }

        // Handle regular tierPrice input change
        updatedFormData.allCurrency[mainIndex].tierPrice[subIndex][name] =
          value;

        // Validation for tierPrice fields
        if (!value) {
          setValidationErrors((prevErrors) => {
            const errorsCopy = [...prevErrors];
            errorsCopy[mainIndex] = errorsCopy[mainIndex] || {};
            errorsCopy[mainIndex][subIndex] =
              errorsCopy[mainIndex][subIndex] || {};
            errorsCopy[mainIndex][subIndex][name] = "This field is required";
            return errorsCopy;
          });
        } else if (parseFloat(value) < 0) {
          setValidationErrors((prevErrors) => {
            const errorsCopy = [...prevErrors];
            errorsCopy[mainIndex] = errorsCopy[mainIndex] || {};
            errorsCopy[mainIndex][subIndex] =
              errorsCopy[mainIndex][subIndex] || {};
            errorsCopy[mainIndex][subIndex][name] =
              "Please enter a valid value";
            return errorsCopy;
          });
        } else {
          setValidationErrors((prevErrors) => {
            const errorsCopy = [...prevErrors];
            errorsCopy[mainIndex] = errorsCopy[mainIndex] || {};
            errorsCopy[mainIndex][subIndex] =
              errorsCopy[mainIndex][subIndex] || {};
            errorsCopy[mainIndex][subIndex][name] = undefined;
            return errorsCopy;
          });
        }
      } else {
        // Ensure the existence of the msrpPrice array
        if (!updatedFormData.allCurrency[mainIndex].msrpPrice) {
          updatedFormData.allCurrency[mainIndex].msrpPrice = [];
        }

        updatedFormData.allCurrency[mainIndex].msrpPrice[0] = {
          ...updatedFormData.allCurrency[mainIndex].msrpPrice[0],
          [name]: value,
        };

        // Validation for msrpPrice fields
        if (!value) {
          setValidationErrors((prevErrors) => {
            const errorsCopy = [...prevErrors];
            errorsCopy[mainIndex] = errorsCopy[mainIndex] || {};
            errorsCopy[mainIndex][name] = "This field is required";
            return errorsCopy;
          });
        } else if (parseFloat(value) < 0) {
          setValidationErrors((prevErrors) => {
            const errorsCopy = [...prevErrors];
            errorsCopy[mainIndex] = errorsCopy[mainIndex] || {};
            errorsCopy[mainIndex][name] = "Please enter a valid value";
            return errorsCopy;
          });
        } else {
          setValidationErrors((prevErrors) => {
            const errorsCopy = [...prevErrors];
            errorsCopy[mainIndex] = errorsCopy[mainIndex] || {};
            errorsCopy[mainIndex][name] = undefined;
            return errorsCopy;
          });
        }
      }
      return updatedFormData;
    });
    // Check if any input field has a value
    const hasValue = Object.values(
      initFormData?.allCurrency[mainIndex]?.tierPrice[subIndex] || {}
    ).some(Boolean);
    setIsPlusIconEnabled(hasValue);
  };
  const handleAddField = (mainIndex) => {
    setRowCount((prevRowCount) => prevRowCount + 1);

    // Clear the selected currency for the new row
    setSelectedCurrencies((prevSelectedCurrencies) => ({
      ...prevSelectedCurrencies,
      [rowCount]: null,
    }));
    // Initialize validation errors for the new mainIndex
    setValidationErrors((prevValidationErrors) => [
      ...prevValidationErrors,
      {},
    ]);
  };
  const handleSubAddField = (mainIndex) => {
    const newTierPrice = {
      costPriceField: "",
      exchangeRateFactor: "",
      landedCostFactor: "",
      shipping: "",
      pricingFactor: "",
    };

    const updatedData = [
      ...initFormData?.allCurrency[mainIndex]?.tierPrice,
      newTierPrice,
    ];

    setInitFormData((prevData) => ({
      ...prevData,
      allCurrency: [
        ...prevData.allCurrency.slice(0, mainIndex),
        {
          ...prevData.allCurrency[mainIndex],
          tierPrice: updatedData,
        },
        ...prevData.allCurrency.slice(mainIndex + 1),
      ],
    }));
    const hasValues = initFormData?.allCurrency[mainIndex]?.tierPrice.some(
      (price) =>
        price.costPriceField ||
        price.minimummValue ||
        price.maximumValue ||
        price.exchangeRateFactor ||
        price.landedCostFactor ||
        price.shipping ||
        price.pricingFactor
    );

    // Conditionally update the state based on whether values are entered
    setIsPlusIconEnabled(hasValues);
  };
  const handleDeleteField1 = (index) => {
    setRowCount((prevRowCount) => prevRowCount - 1);
  };
  const handleRemoveSubField1 = (mainIndex, subIndex) => {
    const updatedData = initFormData.allCurrency.map((currency, index) => {
      if (index === mainIndex) {
        const updatedTierPrice = currency.tierPrice.filter(
          (item, i) => i !== subIndex
        );
        return { ...currency, tierPrice: updatedTierPrice };
      }
      return currency;
    });

    setInitFormData((prevData) => ({
      ...prevData,
      allCurrency: updatedData,
    }));
  };
  const handleDeleteField = async (index, currencyName) => {
    try {
      const retailerIntegrationId = localStorage.getItem(
        "retailerIntegrationId"
      );
      const supplierId = localStorage.getItem("supplierSettingId");

      // Display a confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await axios.post(
          `${API_PATH.DELETE_MIINTO_CURRENCY_DATA}`,
          {
            type: "currency_section",
            retailerIntegrationId: retailerIntegrationId,
            supplierId: supplierId,
            currencyName,
          }
        );

        if (response.data.success) {
          // Display success message
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          getCurrencyData();
        } else {
          // Handle error from the API call
          console.error("Error deleting row:", response.data.message);

          // Display error message
          Swal.fire("Error!", "Failed to delete the row.", "error");
        }
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error deleting row:", error.message);

      // Display error message
      Swal.fire("Error!", "Failed to delete the row.", "error");
    }
  };
  const handleRemoveSubField = async (mainIndex, subIndex) => {
    const tierPriceToDeleteId =
      initFormData.allCurrency[mainIndex].tierPrice[subIndex].id;

    // Make API call to delete tierPrice
    const payload = {
      type: "single",
      id: tierPriceToDeleteId.toString(),
    };
    try {
      // Display a confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "you want to delete this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const response = await axios.post(
          `${API_PATH.DELETE_MIINTO_CURRENCY_DATA}`,
          payload
        );
        const { success, message } = response.data;
        if (success) {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          getCurrencyData();
        } else {
          console.error("Error deleting row:", message);
        }
      }
    } catch (error) {
      console.error("Error deleting tierPrice:", error.message);
    }
  };
  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      // If isChecked is false, move to the next page without validation or API call
      setPage(7);
      return;
    }
    // Perform form validation
    // if (isChecked) {
    //   const isValid = MintoPricingValidation({
    //     rowCount,
    //     selectedCurrencies,
    //     initFormData,
    //     setValidationErrors,
    //   });

    //   // If the form is not valid, display validation messages and return
    //   if (!isValid) {
    //     return;
    //   }
    // }
    try {
      // Now, 'payload' contains the data in the required format
      const retailerIntegrationId = localStorage.getItem(
        "retailerIntegrationId"
      );
      const supplierId = localStorage.getItem("supplierSettingId");

      // const finalPayload = {
      //   retailerIntegrationId: retailerIntegrationId,
      //   supplierId: supplierId,
      //   allCurrency: initFormData.allCurrency,
      // };

      const finalPayload = {
        retailerIntegrationId: retailerIntegrationId,
        supplierId: supplierId,
        allCurrency: initFormData.allCurrency.map((currency) => {
          // Check and set default costPriceField in tierPrice
          if (currency.tierPrice && currency.tierPrice.length > 0) {
            currency.tierPrice.forEach((tier) => {
              if (!tier.costPriceField) {
                tier.costPriceField = "Cost_Price";
              }
            });
          }

          // Check and set default costPriceField in msrpPrice
          if (currency.msrpPrice && currency.msrpPrice.length > 0) {
            currency.msrpPrice.forEach((msrp) => {
              if (!msrp.costPriceField) {
                msrp.costPriceField = "Retail_Price";
              }
            });
          }

          return currency;
        }),
      };
      console.log("payload---", finalPayload);
      setIsLoading(true);
      const response = await axios.post(
        `${API_PATH.CREATE_MIINTO_CURRENCY_DATA}`,
        finalPayload
      );
      console.log("res---", response);

      const { success, message } = response.data;
      if (success) {
        toast.success(message);
        setIsLoading(false);
        setPage(7);
      } else {
        toast.error(message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleOnClick = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      // If isChecked is false, move to the next page without validation or API call
      localStorage.removeItem("supplierSettingId");
      localStorage.removeItem("selectedSupplierName");
      localStorage.removeItem("retailerIntegrationId");
      localStorage.removeItem("currentPage");
      history.push("/setting-retailer-list");
      return;
    }
    // Perform form validation
    // if (isChecked) {
    //   const isValid = MintoPricingValidation({
    //     rowCount,
    //     selectedCurrencies,
    //     initFormData,
    //     setValidationErrors,
    //   });

    //   // If the form is not valid, display validation messages and return
    //   if (!isValid) {
    //     return;
    //   }
    // }
    try {
      // Now, 'payload' contains the data in the required format
      const retailerIntegrationId = localStorage.getItem(
        "retailerIntegrationId"
      );
      const supplierId = localStorage.getItem("supplierSettingId");

      // const finalPayload = {
      //   retailerIntegrationId: retailerIntegrationId,
      //   supplierId: supplierId,
      //   allCurrency: initFormData.allCurrency,
      // };
      const finalPayload = {
        retailerIntegrationId: retailerIntegrationId,
        supplierId: supplierId,
        allCurrency: initFormData.allCurrency.map((currency) => {
          // Check and set default costPriceField in tierPrice
          if (currency.tierPrice && currency.tierPrice.length > 0) {
            currency.tierPrice.forEach((tier) => {
              if (!tier.costPriceField) {
                tier.costPriceField = "Cost_Price";
              }
            });
          }

          // Check and set default costPriceField in msrpPrice
          if (currency.msrpPrice && currency.msrpPrice.length > 0) {
            currency.msrpPrice.forEach((msrp) => {
              if (!msrp.costPriceField) {
                msrp.costPriceField = "Retail_Price";
              }
            });
          }

          return currency;
        }),
      };
      setIsLoadingExit(true);
      const response = await axios.post(
        `${API_PATH.CREATE_MIINTO_CURRENCY_DATA}`,
        finalPayload
      );
      const { success, message } = response.data;
      if (success) {
        localStorage.removeItem("supplierSettingId");
        localStorage.removeItem("selectedSupplierName");
        localStorage.removeItem("retailerIntegrationId");
        localStorage.removeItem("currentPage");
        setIsLoadingExit(true);
        toast.success(message);
        history.push("/setting-retailer-list");
      } else {
        toast.error(message);
        setIsLoadingExit(true);
      }
    } catch (error) {
      setIsLoadingExit(true);
    }
  };
  const supplierName = localStorage.getItem("selectedSupplierName");

  return (
    <form name="priceForm" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12 button-class">
          <div className="d-flex">
            <button
              className="btn btn-primary w-auto btn-lg mr-2"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Save & Next"
              )}
            </button>

            <button
              className="btn btn-primary w-auto btn-lg mr-2"
              type="submit"
              onClick={handleOnClick}
            >
              {isLoadingExit ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                "Save & Exit"
              )}
            </button>
            <button
              className="btn btn-secondary w-auto btn-lg"
              type="button"
              onClick={processCancel}
            >
              Exit
            </button>
          </div>
        </div>
      </div>
      <div className="alert alert-primary col-12 mt-3 ml-2" role="alert">
        <strong>INFO:</strong> <br />
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            checked={isChecked}
            onChange={handleCheckChange}
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            Do you want to add the Miinto Pricing?
          </label>
        </div>
      </div>

      {isChecked && (
        <>
          <Accordion activeKey={0}>
            <Card key={0}>
              <Card.Header>
                <Accordion.Toggle
                  eventKey={0}
                  className="btn btn-link collapsed text-decoration-none"
                  style={{ borderBottom: "2px solid #49c5b6" }}
                >
                  {supplierName}
                </Accordion.Toggle>
              </Card.Header>
              <div className="row mt-5 mt-sm-0">
                <Card.Body>
                  {[...Array(rowCount)].map((_, mainIndex) => (
                    <div key={mainIndex} className="col-12">
                      <div
                        className="row ml-2"
                        style={{
                          marginTop: mainIndex > 0 ? "15px" : "0",
                          // border: mainIndex > 0 ? "1px solid #c3c2c2" : "none",
                          background: mainIndex > 0 ? "#f4f7f6" : "white",

                          padding: "20px",
                          width: "97%",
                        }}
                        key={mainIndex}
                      >
                        <div className="d-flex col-12">
                          <div key={mainIndex} style={{ flex: "1" }}>
                            <label>Select Currency</label>
                            <Select
                              options={selectOptions.filter(
                                (opt) =>
                                  !Object.values(selectedCurrencies).some(
                                    (selected, index) =>
                                      selected &&
                                      selected.value === opt.value &&
                                      index !== mainIndex
                                  )
                              )}
                              onChange={(selectedOptions) =>
                                handleSelectChangeCurrency(
                                  selectedOptions,
                                  mainIndex
                                )
                              }
                              value={
                                initFormData?.allCurrency[mainIndex]?.currency
                                  ? {
                                      label:
                                        initFormData?.allCurrency[mainIndex]
                                          ?.currency,
                                      value:
                                        initFormData?.allCurrency[mainIndex]
                                          ?.currency,
                                    }
                                  : null
                              }
                              placeholder="Select Currency"
                            />

                            {validationErrors[mainIndex]?.currency && (
                              <div className="text-danger">
                                {validationErrors[mainIndex].currency}
                              </div>
                            )}
                          </div>
                          <div className="ml-3" style={{ flex: "0.1" }}>
                            {mainIndex > 0 ? (
                              <>
                                <RemoveCircleOutlineIcon
                                  className="delete-idx-icon"
                                  style={{
                                    cursor: "pointer",
                                    color: "#c90e0e",
                                    fontSize: "23px",
                                    marginTop: "32px",
                                  }}
                                  onClick={() => {
                                    const currencyNameToDelete =
                                      initFormData?.allCurrency[mainIndex]
                                        ?.currency;

                                    if (currencyNameToDelete) {
                                      handleDeleteField(
                                        mainIndex,
                                        currencyNameToDelete
                                      );
                                    } else {
                                      handleDeleteField1(mainIndex);
                                    }
                                  }}
                                />
                              </>
                            ) : null}
                            {mainIndex === rowCount - 1 ? (
                              <AddCircleOutlineOutlinedIcon
                                style={{
                                  cursor: "pointer",
                                  color: "#49c5b6",
                                  fontSize: "23px",
                                  marginTop: "32px",
                                }}
                                onClick={() => {
                                  handleAddField(mainIndex);
                                  // Additional logic to set "minus" icon on the previous row
                                  if (mainIndex > 0) {
                                    setSubRowCount((prevSubRowCount) => ({
                                      ...prevSubRowCount,
                                      [mainIndex - 1]: 1,
                                    }));
                                  }
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="row ml-3 mt-4">
                          <div style={{ color: "#00c07fc4" }}>
                            {" "}
                            <label>Tier Price:</label>
                          </div>
                          {initFormData?.allCurrency[mainIndex]?.tierPrice ===
                            undefined ||
                          initFormData?.allCurrency[mainIndex]?.tierPrice
                            .length === 0 ? (
                            <>
                              {[...Array(subRowCount[mainIndex] || 1)].map(
                                (_, subIndex) => (
                                  <>
                                    <MiintoPricingSubField
                                      key={`${mainIndex}-${subIndex}`}
                                      mainIndex={mainIndex}
                                      subIndex={subIndex}
                                      price={price}
                                      defaultPrices={defaultPrices}
                                      handleInputChange={handleInputChange}
                                      handleSelectChange={handleSelectChange}
                                      handleRemoveSubField={
                                        handleRemoveSubField
                                      }
                                      handleSubAddField={handleSubAddField}
                                      validationErrors={validationErrors}
                                      subRowCount={subRowCount}
                                    />
                                  </>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              {initFormData?.allCurrency[
                                mainIndex
                              ]?.tierPrice?.map((data, subIndex) => (
                                <>
                                  <MiintoPricingFillUpSubFields
                                    key={`${mainIndex}-${subIndex}`}
                                    mainIndex={mainIndex}
                                    data={data}
                                    subIndex={subIndex}
                                    price={price}
                                    defaultPrices={defaultPrices}
                                    initFormData={initFormData}
                                    handleInputChange={handleInputChange}
                                    handleSelectChange={handleSelectChange}
                                    handleRemoveSubField={handleRemoveSubField}
                                    handleRemoveSubField1={
                                      handleRemoveSubField1
                                    }
                                    handleSubAddField={handleSubAddField}
                                    validationErrors={validationErrors}
                                    isPlusIconEnabled={isPlusIconEnabled}
                                  />
                                </>
                              ))}
                            </>
                          )}

                          <MiinotPricingMsrpField
                            mainIndex={mainIndex}
                            price={price}
                            defaultPrices={defaultPrices}
                            initFormData={initFormData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            validationErrors={validationErrors}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Card.Body>
              </div>
            </Card>
          </Accordion>
        </>
      )}
    </form>
  );
}

const mapStateToProps = ({ LoadingReducer, loginReducer }) => ({
  loading: LoadingReducer.isLoading,
  user: loginReducer.user,
});
export default connect(mapStateToProps, { onLoading })(MintoPricing);
