import React from "react";
import Select from "react-select";
import {
  dataFileMappingAIMsg,
  dataFileMappingExtractMsg,
  dataFileMappingImageMsg,
} from "../utils/constants";
import { Autocomplete, TextField } from "@mui/material";

const ProductFieldsMappingJsx = ({
  productFields,
  selectedOptions,
  mappingData,
  selectedRadio,
  handleRadioChange,
  handleFieldChange,
  handleAdditionalValueChange,
  options,
  handleRadioTextChange,
  additionalTextValue,
  csvOption,
  handleExtractChange,
}) => {
  return (
    <tbody>
      {productFields.map((productField, index) => {
        const keys = Object.keys(productField);
        return (
          <>
            {keys.map((key) => {
              const selectedOption =
                selectedOptions[index] && selectedOptions[index][key]
                  ? selectedOptions[index][key]
                  : null;
              const mapping =
                mappingData.find((item) => item.standardField === key) || {};
              const radioValue =
                selectedRadio[`${index}-${key}`] &&
                selectedRadio[`${index}-${key}`].value
                  ? selectedRadio[`${index}-${key}`].value
                  : mapping.imageType || null;
              const imageList = mapping?.imageList ? mapping.imageList : "";
              let additionalInfo = null;
              if (
                key.startsWith("Image") &&
                selectedOption &&
                !selectedOption.textbox &&
                selectedOption.value !== "do_nothing" &&
                selectedOption.value !== "hardcode_value" &&
                selectedOption.value !== "use_AI" &&
                selectedOption.value !== "extract"
              ) {
                additionalInfo = (
                  <>
                    <div className="radio-container">
                      <input
                        type="radio"
                        name={`image-${index}-${key}`}
                        value="folder_only"
                        onChange={(e) =>
                          handleRadioChange(index, key, e.target.value)
                        }
                        checked={radioValue === "folder_only"}
                      />{" "}
                      <label
                        htmlFor={`image-${index}-${key}`}
                        className="image-label text-success"
                      >
                        Is it folder name?
                      </label>
                      <br />
                      <input
                        type="radio"
                        name={`image-${index}-${key}`}
                        value="folder_images"
                        onChange={(e) =>
                          handleRadioChange(index, key, e.target.value)
                        }
                        checked={radioValue === "folder_images"}
                      />{" "}
                      <label
                        htmlFor={`image-${index}-${key}`}
                        className="image-label text-success"
                      >
                        Folder name with Image
                      </label>
                      <br />
                      <input
                        type="radio"
                        name={`image-${index}-${key}`}
                        value="single_image"
                        onChange={(e) =>
                          handleRadioChange(index, key, e.target.value)
                        }
                        checked={radioValue === "single_image"}
                      />{" "}
                      <label
                        htmlFor={`image-${index}-${key}`}
                        className="image-label text-success"
                      >
                        Single Image
                      </label>
                    </div>
                  </>
                );
              } else if (key === "Dimension_Units") {
                additionalInfo = (
                  <div className="select-container">
                    <Select
                      key={`${index}-${key}-additional`}
                      options={[
                        { label: "cm", value: "cm", count: 0 },
                        { label: "in", value: "in", count: 0 },
                        { label: "mm", value: "mm", count: 0 },
                      ]}
                      value={selectedOption}
                      onChange={(selectedOption) =>
                        handleFieldChange(index, key, selectedOption)
                      }
                      isSearchable={true}
                      className="select"
                    />
                  </div>
                );
              } else if (key === "Weight_Unit") {
                additionalInfo = (
                  <div className="select-container">
                    <Select
                      key={`${index}-${key}-additional`}
                      options={[
                        { label: "lbs", value: "lbs", count: 0 },
                        { label: "kg", value: "kg", count: 0 },
                      ]}
                      value={selectedOption}
                      onChange={(selectedOption) =>
                        handleFieldChange(index, key, selectedOption)
                      }
                      isSearchable={true}
                      className="select"
                    />
                  </div>
                );
              } else if (key === "Condition") {
                additionalInfo = (
                  <div className="select-container">
                    <Select
                      key={`${index}-${key}-additional`}
                      options={[
                        { label: "New", value: "New", count: 0 },
                        {
                          label: "Preloved",
                          value: "Preloved",
                          count: 0,
                        },
                      ]}
                      value={selectedOption}
                      onChange={(selectedOption) =>
                        handleFieldChange(index, key, selectedOption)
                      }
                      isSearchable={true}
                      className="select"
                    />
                  </div>
                );
              }else if (key === "Volume_Unit"){
                additionalInfo = (
                  <div className="select-container">
                    <Select
                      key={`${index}-${key}-additional`}
                      options={[
                        { label: "oz", value: "oz", count: 0 },
                        { label: "ml", value: "ml", count: 0 },
                      ]}
                      value={selectedOption}
                      onChange={(selectedOption) =>
                        handleFieldChange(index, key, selectedOption)
                      }
                      isSearchable={true}
                      className="select"
                    />
                  </div>
                );
              } else if (key === "Cost_Price") {
                additionalInfo = (
                  <>
                    <textarea
                      placeholder="Enter a value"
                      value={selectedOptions[index]?.[key]?.additionalValue}
                      className="additional-textbox rounded"
                      onChange={(e) => {
                        handleAdditionalValueChange(index, key, e.target.value);
                      }}
                    ></textarea>
                  </>
                );
              } else if (key === "Retail_Price") {
                additionalInfo = (
                  <>
                    <textarea
                      placeholder="Enter a value"
                      value={selectedOptions[index]?.[key]?.additionalValue}
                      className="additional-textbox rounded"
                      onChange={(e) => {
                        handleAdditionalValueChange(index, key, e.target.value);
                      }}
                    ></textarea>
                  </>
                );
              } else if (key === "Suggested_Sell_Price") {
                additionalInfo = (
                  <>
                    <textarea
                      placeholder="Enter a value"
                      value={selectedOptions[index]?.[key]?.additionalValue}
                      className="additional-textbox rounded"
                      onChange={(e) => {
                        handleAdditionalValueChange(index, key, e.target.value);
                      }}
                    ></textarea>
                  </>
                );
              } else {
                additionalInfo = (
                  <>
                    {selectedOption && selectedOption.textbox ? (
                      <textarea
                        placeholder="Enter a value"
                        className="additional-textbox rounded"
                        onChange={(e) => {
                          handleAdditionalValueChange(
                            index,
                            key,
                            e.target.value
                          );
                        }}
                      ></textarea>
                    ) : selectedOption?.value === "use_AI" ? (
                      <>
                        <textarea
                          placeholder="Enter a value"
                          value={selectedOptions[index]?.[key]?.additionalValue}
                          className="additional-textbox rounded"
                          onChange={(e) => {
                            handleAdditionalValueChange(
                              index,
                              key,
                              e.target.value
                            );
                          }}
                        ></textarea>
                      </>
                    ) : selectedOption?.value === "hardcode_value" ? (
                      <textarea
                        placeholder="Enter a value"
                        value={selectedOptions[index]?.[key]?.additionalValue}
                        className="additional-textbox rounded"
                        onChange={(e) => {
                          handleAdditionalValueChange(
                            index,
                            key,
                            e.target.value
                          );
                        }}
                      ></textarea>
                    ) : selectedOption?.value === "extract" ? (
                      <textarea
                        placeholder="Enter a value"
                        value={selectedOptions[index]?.[key]?.additionalValue}
                        className="additional-textbox rounded"
                        onChange={(e) => {
                          handleAdditionalValueChange(
                            index,
                            key,
                            e.target.value
                          );
                        }}
                      ></textarea>
                    ) : (
                      <></>
                    )}
                    <br />
                    {selectedOption && selectedOption.value === "use_AI" && (
                      <label className="ml-3 text-success">
                        e.g. Please generate the {key} from{" "}
                        {selectedOption.message}
                      </label>
                    )}
                    {selectedOption && selectedOption.value === "extract" && (
                      <label className="ml-3 text-success">
                        Note: Please enter the name of the extract string.
                        <br />
                        e.g. sleeves
                      </label>
                    )}
                  </>
                );
              }

              return (
                <tr key={key}>
                  <td>{key}</td>
                  <td>
                    <div className="select-container">
                      <Select
                        options={
                          key === "Parent_Title" || key === "Variant_Title"
                            ? options?.filter((data) => data.value !== "use_AI")
                            : options
                        }
                        value={selectedOption}
                        onChange={(selectedOption) =>
                          handleFieldChange(index, key, selectedOption)
                        }
                        isSearchable={true}
                        className="select"
                        styles={{
                          option: (styles, { data }) => {
                            return {
                              ...styles,
                              background: data.color,
                            };
                          },
                        }}
                      />
                    </div>
                  </td>
                  <td>{additionalInfo}</td>
                  <td>
                    {radioValue === "folder_only" ? (
                      imageList === "" ? (
                        <>
                          <textarea
                            placeholder="Enter a value Add"
                            className="additional-textbox rounded"
                            onChange={(e) =>
                              handleRadioTextChange(index, key, e.target.value)
                            }
                          ></textarea>
                          <label className="text-success">
                            {dataFileMappingImageMsg}
                          </label>
                        </>
                      ) : (
                        <>
                          <textarea
                            placeholder="Enter a value Edit"
                            className="additional-textbox rounded"
                            value={
                              additionalTextValue.length === 0
                                ? imageList
                                : additionalTextValue[index][key]?.e
                            }
                            onChange={(e) =>
                              handleRadioTextChange(index, key, e.target.value)
                            }
                          ></textarea>
                          <label className="text-success">
                            {dataFileMappingImageMsg}
                          </label>
                        </>
                      )
                    ) : (
                      <></>
                    )}

                    {selectedOption && selectedOption.value === "extract" ? (
                      <>
                        <Autocomplete
                          disablePortal
                          id={`combo-box-demo-${index}`}
                          options={csvOption}
                          sx={{ width: 200, fontSize: "14px" }}
                          size="small"
                          onChange={(e, newVal) =>
                            handleExtractChange(
                              index,
                              key,
                              newVal?.value ? newVal.value : ""
                            )
                          }
                          value={selectedOptions[index]?.[key]?.supplierExtract}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Select..." />
                          )}
                        />
                        <label className="mt-2 text-success">
                          {dataFileMappingExtractMsg}
                        </label>
                      </>
                    ) : null}
                    {selectedOption && selectedOption.value === "use_AI" && (
                      <label className="text-success">
                        {dataFileMappingAIMsg}
                        <br />
                        {`{Parent_Title}`}
                        <br />
                        {`{Variant_Title}`}
                        <br />
                        {`{Plain_Description}`}
                        <br />
                        {`{Category_1}`}
                      </label>
                    )}
                  </td>
                </tr>
              );
            })}
          </>
        );
      })}
    </tbody>
  );
};

export default ProductFieldsMappingJsx;
