import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const tooltipForPrompt = (
  <Tooltip id="tooltipForPrompt">
    <strong>Please enter your prompt.</strong>
  </Tooltip>
);

const tooltipCopy = (
  <Tooltip id="tooltipForPrompt">
    <strong>Copy</strong>
  </Tooltip>
);

const AddBasicPropmptJsx = ({
  props,
  productAttributes,
  promptValue,
  handlePromptChange,
  calculateTextAreaHeight,
  registeredPromptKeys,
  handleCopyToClipboard,
}) => {
  return (
    <div className="col-sm-12">
      <table className="w-100 table-responsive-md custom-table-prompt">
        <thead>
          <tr>
            <th>
              Prompt
              <span className="font-weight-bold text-danger">*</span>
              <OverlayTrigger
                placement="left"
                overlay={tooltipForPrompt}
                className="mt-1"
              >
                <i
                  className="ml-2 fa fa-info-circle"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontSize: "18px",
                    marginTop: "2px",
                  }}
                ></i>
              </OverlayTrigger>
            </th>
            <th>Registered Prompt Key</th>
            <th>Product Attributes</th>
          </tr>
        </thead>
        {props.loading ? (
          <tbody>
            <tr>
              <td
                colSpan="3"
                className="loader-wrapper"
                style={{ padding: "2.3rem", width: "100%" }}
              >
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ padding: "2rem" }}
                ></i>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {productAttributes.map((attribute, index) => (
              <tr key={index}>
                {index === 0 && (
                  <td
                    className="w-50"
                    rowSpan={productAttributes.length}
                    style={{ verticalAlign: "top" }}
                  >
                    <textarea
                      className="ai-description-textarea rounded"
                      placeholder="Enter prompt value"
                      value={promptValue}
                      onChange={handlePromptChange}
                      style={{ height: calculateTextAreaHeight() }}
                    ></textarea>
                  </td>
                )}

                <td className="custom-td">{registeredPromptKeys[index]}</td>
                <td className="custom-td">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      {"{"}
                      {productAttributes[index]}
                      {"}"}
                    </span>
                    <span
                      onClick={() =>
                        handleCopyToClipboard(productAttributes[index])
                      }
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={tooltipCopy}
                        className="mt-1"
                      >
                        <i
                          className="fa fa-copy"
                          style={{
                            cursor: "pointer",
                            color: "#0387ff",
                          }}
                        ></i>
                      </OverlayTrigger>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default AddBasicPropmptJsx;
