import React from "react";
import { Modal } from "react-bootstrap";

const PromptMappingModel=({showModal, setShowModal})=>{
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal__main"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Prompt Guidelines
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "450px", overflowY: "auto" }}>
          <div className="p-3">
          <div className="mb-3">
              <h5>1. Prompt Attribute:</h5>
              <ul>
              This section represents the attribute list based on what you have entered in the previous steps' prompts.
              </ul>
            </div>
            <div className="mb-3">
              <h5>2. Product Attribute:</h5>
              <ul>
              This section represents the list of the system's default product attributes. All existing attributes are displayed in a dropdown. You can map the prompt attribute to the relevant product attribute.
              </ul>
              <ul style={{ listStyle: "conic-gradient", marginLeft:"4%" }}>
                <li>
                  <strong>Relevant Field Exists:</strong>If the relevant field exists based on the prompt attribute, you can map it to that field.
                </li>
                <br />
                <li>
                  <strong>Relevant Field Does Not Exist:</strong> If the field does not exist in the system for that specific prompt attribute, you can select the <code>"need_to_create"</code> option. Once you select this option and complete the form, the system will create a field for this attribute and store the value in this field. Additionally, the newly created attribute will be displayed in the dropdown list for future mapping.
                 
                </li>
                <br />
              </ul>
            </div>
            <div className="" style={{marginTop:"-2%"}}>
              <h5>3. Field Type:</h5>
              <ul>
              This section is used when you select the <code> "need_to_create" </code> option in the Product Attribute column. This helps the system identify the data type.
              </ul>
              <ul style={{ listStyle: "conic-gradient", marginLeft:"4%" }}>
                <li>
                  <strong>Text:</strong> If you select this value, the system will create a field in the database where the system will store data up to 255 characters.
                </li>
                <br />
                <li>
                  <strong>Textarea:</strong> If you select this value, the system will store long text in this field.
                 
                </li>
                <br />
              </ul>
            </div>
            <div className="mb-3">
            <div
            className="alert alert-primary col-12 mt-3 d-flex"
            role="alert"
          >
          <strong>Note:</strong>
          <p className="ml-2">
          If you need to store a long description, select the <code> "textarea"</code> option. If you need to store short data, select the <code>"text" </code> option.
          </p>
          </div>
           
          </div>
            <div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => setShowModal(false)}
            className="btn btn-outline-info"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PromptMappingModel
