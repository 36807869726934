import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiCopy, FiCheck } from "react-icons/fi";
import { IoMdCheckboxOutline } from "react-icons/io";

import "./PromptKey.css";

const PromptGuidelineMode = ({ showModal, setShowModal }) => {
  const [copied, setCopied] = useState(false);
  const promptText = `
  Brand: {Brand}, 
  model/style name:, 
  product type: , 
  color: {Main_Color}, 
  Variant_SKU: {Variant_SKU}, 
  `;

  const handleCopy = () => {
    // navigator.clipboard.writeText(promptText);
    const tempInput = document.createElement("input");
    const textWithCurlyBraces = promptText;
    tempInput.value = textWithCurlyBraces;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Change back to copy icon after 2 seconds
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal__main"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title" style={{ color: "#49c5b6" }}>
            Prompt Guidelines
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "450px", overflowY: "auto" }}>
          <div className="p-3">
            <div style={{ marginTop: "5px" }}>

              <h6 >There are two main sections in this screen:</h6>
            </div><br/>
            <div style={{ fontSize: "18px", fontWeight: "bold", background: "#EEEEEE", padding: "5px" }}>System Prompt
            </div>
            <ul className=" mt-2">Typically used to instruct the model on how to behave for all prompts. If you want to make changes, you can modify it, or leave it as is.</ul>
            <div className=" mt-3" style={{ fontSize: "18px", fontWeight: "bold", background: "#EEEEEE", padding: "5px" }}>Normal prompt</div>
            <div className="mb-3 mt-2">
              <h6 style={{ fontWeight: "bold" }}>1. Prompt:</h6>
              <ul>
                This section is used to insert a valid prompt. There are certain
                conditions which need to be followed for efficient results:
              </ul>
              <ul style={{ listStyle: "conic-gradient", marginLeft: "4%" }}>
                <li>
                  <strong>Attribute Identification:</strong> The system
                  identifies the attribute list based on the colon. If you want
                  to retrieve any attribute information from the API, use the
                  attribute followed by a colon.
                  <br />
                  Example: <code>Brand: Nike</code>
                </li>
                <br />
                <li>
                  <strong>Specific Attribute Information:</strong> If you want
                  to provide information for a specific attribute without using
                  a colon, just add the information directly.
                  <br />
                  Example:{" "}
                  <code>Please provide the brand name. Brand: Nike</code>
                </li>
                <br />
                <li>
                  <strong>Dynamic Data for Specific Attributes:</strong> To pass
                  dynamic data for a specific attribute, you need to pass the
                  product attribute field name in curly brackets, as shown in
                  the third column.
                  <br />
                  Example: <code>Brand: {`{Brand}`}</code>
                  <br />
                  Here, <code>{`{Brand}`}</code> refers to the actual value of
                  the product, which will be replaced during the API call.
                </li>
              </ul>
            </div>
            <div className="mb-3">
              <h6 style={{ fontWeight: "bold" }}>2. Registered Prompt Keys:</h6>
              <ul>
                This section displays all the currently registered prompt keys.
                These keys are used during prompt registration to validate the
                attributes you include in your prompts. If you attempt to use an
                attribute not listed here, you'll need to register it within the
                prompt key module before validating the prompt.
              </ul>
            </div>
            <div>
              <h6 style={{ fontWeight: "bold" }}>3. Product Attributes:</h6>
              <ul>
                This section displays a list of all available product
                attributes. These attributes can be used within your prompts to
                replace them with the actual value of the attribute during an
                API call. This allows you to create more dynamic and
                personalized prompts.
              </ul>
            </div>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Sample Prompt</th>
                    <th>
                      {copied ? (
                        <IoMdCheckboxOutline style={{ color: "green", marginLeft: "85%", fontSize: "20px" }} />
                      ) : (
                        <FiCopy
                          onClick={handleCopy}
                          style={{ cursor: "pointer", marginLeft: "85%", color: "#49c5b6", fontSize: "18px" }}
                        />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ display: "flex", width: "119%" }}>
                      <textarea
                        className="ai-description-textarea test-prompt"
                        type="text"
                        name="prompt_slug"
                        value={promptText}
                        style={{
                          border: "none",
                          width: "100%",
                          height: "150px",
                          background: "#fff",
                          overflowY: "hidden"
                        }}
                        disabled
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => setShowModal(false)}
            className="btn btn-outline-info"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PromptGuidelineMode;
