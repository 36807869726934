import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Nav, Collapse } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
  onLogOut,
  onLoggedin,
} from "../actions";
import "./NavbarMenu.css";
import { onSystemLoading } from "../actions";
import { withRouter } from "react-router-dom";
import { UserContext } from "../context/UserContext";

const LogOut = ({ propData }) => {
  const history = useHistory();
  const logoutHandler = () => {
    propData.onSystemLoading(true);
    const token = localStorage.getItem("token");

    const userId = propData.user.data._id;
    propData
      .onLogOut(token, userId)
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("_id");
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("company");
        localStorage.removeItem("retailer");
        localStorage.removeItem("newlyAddedRetailer");
        localStorage.removeItem("currentPage");

        localStorage.removeItem("role");
        propData.onLoggedin(false);
        history.push("/login");
        propData.onSystemLoading(false);
      })
      .catch((e) => {
        console.log(e);
        propData.onLoggedin(false);
        propData.onSystemLoading(false);
      });
  };
  return (
    <button className="icon-menu button-link" onClick={logoutHandler}>
      <i className="icon-login"></i>
    </button>
  );
};

class NavbarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.manageDropdown = [
      "/company",
      "/manage-company",
      "/retailer",
      "/manage-retailer",
      "/user",
      "/manage-user",
    ];
  }
  state = {
    linkupdate: false,
    colapsStatue: false,
  };
  componentDidMount() {
    this.props.tostMessageLoad(true);
    var res = window.location.pathname;
    res = res.split("/");
    res = res.length > 4 ? res[4] : "/";
    const { activeKey } = this.props;
    this.activeMenutabwhenNavigate("/" + activeKey);
  }

  logoutHandler = () => {
    this.props.onSystemLoading(true);
    const token = localStorage.getItem("token");

    const userId = this.props.user.data._id;
    this.props
      .onLogOut(token, userId)
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("_id");
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("company");
        localStorage.removeItem("retailer");
        localStorage.removeItem("newlyAddedRetailer");

        localStorage.removeItem("role");
        this.props.onLoggedin(false);
        this.props.history.push("/login");
        this.props.onSystemLoading(false);
      })
      .catch((e) => {
        console.log(e);
        this.props.onLoggedin(false);
        this.props.onSystemLoading(false);
      });
  };

  activeMenutabwhenNavigate(activeKey) {
    if (
      activeKey === "/dashboard" ||
      activeKey === "/demographic" ||
      activeKey === "/ioT"
    ) {
      this.activeMenutabContainer("dashboradContainer");
    } else if (
      activeKey === "/appinbox" ||
      activeKey === "/appchat" ||
      activeKey === "/appcalendar" ||
      activeKey === "/appcontact"
    ) {
      this.activeMenutabContainer("AppContainer");
    } else if (
      activeKey === "/filemanagerdashboard" ||
      activeKey === "/filedocuments" ||
      activeKey === "/filemedia"
    ) {
      this.activeMenutabContainer("FileManagerContainer");
    } else if (
      activeKey === "/blognewpost" ||
      activeKey === "/bloglist" ||
      activeKey === "/blogdetails"
    ) {
      this.activeMenutabContainer("BlogContainer");
    } else if (
      activeKey === "/widgetsdata" ||
      activeKey === "/widgetsweather" ||
      activeKey === "/widgetsblog"
    ) {
      this.activeMenutabContainer("WidgetsContainer");
    } else if (activeKey === "/login") {
      this.activeMenutabContainer("WidgetsContainer");
    } else if (activeKey === "/tablenormal") {
      this.activeMenutabContainer("TablesContainer");
    } else if (activeKey === "/echart") {
      this.activeMenutabContainer("chartsContainer");
    } else if (activeKey === "/leafletmap") {
      this.activeMenutabContainer("MapsContainer");
    }
  }

  activeMenutabContainer(id) {
    var parents = document.getElementById("main-menu");
    var activeMenu = document.getElementById(id);

    for (let index = 0; index < parents.children.length; index++) {
      if (parents.children[index].id !== id) {
        parents.children[index].classList.remove("active");
        parents.children[index].children[1].classList.remove("in");
      }
    }
    setTimeout(() => {
      if (activeMenu) {
        activeMenu.classList.toggle("active");
        activeMenu.children[1].classList.toggle("in");
      }
    }, 10);
  }

  render() {
    const { themeColor, toggleEqualizer, sideMenuTab, activeKey, history } =
      this.props;
    document.body.classList.add(themeColor);

    const supplier = this.props.user?.permissions?.add_supplier;
    const updateSupplier = this.props.user?.permissions?.update_supplier;
    const viewSupplier = this.props.user?.permissions?.view_supplier;
    const viewProductList = this.props.user?.permissions?.view_product;
    const cancelOrder = this.props.user?.permissions?.cancel_order;
    const viewPrompt = this.props.user?.permissions?.view_prompt_key;

    return (
      <UserContext.Consumer>
        {(userContext) => {
          const { userName } = userContext;
          const { userLogo } = userContext;

          return (
            <div>
              <nav className="navbar navbar-fixed-top">
                <div className="container-fluid">
                  <div className="navbar-btn">
                    <button
                      className="btn-toggle-offcanvas"
                      onClick={() => {
                        this.props.onPressSideMenuToggle();
                      }}
                    >
                      <i className="lnr lnr-menu fa fa-bars"></i>
                    </button>
                  </div>

                  <div className="navbar-brand">
                    <Link to="/dashboard">
                      <img
                        src="azura-logo.png"
                        alt="Azura Logo"
                        className="img-responsive logo website-logo"
                      />
                    </Link>
                  </div>

                  <div className="navbar-right">
                    <div id="navbar-menu">
                      <ul className="nav navbar-nav">
                        <li
                          className={
                            toggleEqualizer ? "show dropdown" : "dropdown"
                          }
                        >
                          <ul
                            className={
                              toggleEqualizer
                                ? "dropdown-menu user-menu menu-icon show"
                                : "dropdown-menu user-menu menu-icon"
                            }
                          >
                            <li className="menu-heading">ACCOUNT SETTINGS</li>
                            <li>
                              <a>
                                <i className="icon-note"></i> <span>Basic</span>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="icon-equalizer"></i>{" "}
                                <span>Preferences</span>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="icon-lock"></i>{" "}
                                <span>Privacy</span>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="icon-bell"></i>{" "}
                                <span>Notifications</span>
                              </a>
                            </li>
                            <li className="menu-heading">BILLING</li>
                            <li>
                              <a>
                                <i className="icon-credit-card"></i>{" "}
                                <span>Payments</span>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="icon-printer"></i>{" "}
                                <span>Invoices</span>
                              </a>
                            </li>
                            <li>
                              <a>
                                <i className="icon-refresh"></i>{" "}
                                <span>Renewals</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <LogOut propData={this.props} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>

              <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
                <div className="sidebar-scroll">
                  <div className="user-account d-flex align-items-center">
                    <img
                      src="user2.jpg"
                      className="rounded-circle user-photo"
                      alt=""
                    />
                    <Dropdown>
                      {/* <span style={{ display: "flex", marginTop: "10px" }}>
                    {localStorage.getItem("name")}
                  </span> */}
                      <Dropdown.Toggle
                        variant="none"
                        as="a"
                        id="dropdown-basic"
                        className="user-name"
                        style={{ cursor: "pointer" }}
                      >
                        <strong className="ml-4">{userName}</strong>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdown-menu-right account">
                        {/* <Dropdown.Item> */}
                        {this.props.user?.data.role === "SUPER_ADMIN" ? (
                          <>
                            <Dropdown.Item
                              onClick={() => history.push(`/profile`)}
                            >
                              {/* <Link to="profile"> */}
                              <i className="icon-user"></i>{" "}
                              <span>My Profile</span>
                              {/* </Link> */}
                            </Dropdown.Item>
                          </>
                        ) : null}
                        {this.props.user?.data.role == "COMPANY_ADMIN" ? (
                          <Dropdown.Item
                            onClick={() => history.push(`/profile`)}
                          >
                            {/* <Link to="company-profile"> */}
                            <i className="icon-user"></i>My Profile
                            {/* </Link> */}
                          </Dropdown.Item>
                        ) : null}
                        {this.props.user?.data.role == "RETAILER_ADMIN" ? (
                          <Dropdown.Item
                            onClick={() => history.push(`/profile`)}
                          >
                            {/* <Link to="retailer-profile"> */}
                            <i className="icon-user"></i>My Profile
                            {/* </Link> */}
                          </Dropdown.Item>
                        ) : null}
                        {this.props.user?.data.role == "RETAILER_USER" ? (
                          <Dropdown.Item
                            onClick={() => history.push(`/profile`)}
                          >
                            {/* <Link to="retailer-profile"> */}
                            <i className="icon-user"></i>My Profile
                            {/* </Link> */}
                          </Dropdown.Item>
                        ) : null}
                        {/* </Dropdown.Item> */}
                        <li className="divider"></li>
                        <Dropdown.Item
                          onClick={() => history.push(`/change-password`)}
                        >
                          <i class="fa fa-key" aria-hidden="true"></i>{" "}
                          <span>Change Password</span>
                        </Dropdown.Item>
                        <li className="divider"></li>

                        <Dropdown.Item onClick={this.logoutHandler}>
                          {" "}
                          <i className="icon-power"></i>Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <hr />
                  </div>
                  <Nav id="left-sidebar-nav" className="sidebar-nav">
                    <ul id="main-menu" className="metismenu">
                      <li className="" id="dashboardDropDown">
                        <a
                          href="#!"
                          className="d-none"
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer("dashboardDropDown");
                          }}
                        ></a>
                        <ul>
                          {this.props.user?.data.role == "SUPER_ADMIN" ||
                            this.props.user?.data.role == "COMPANY_ADMIN" ||
                            this.props.user?.data.role == "RETAILER_ADMIN" ||
                            this.props.user?.data.role == "RETAILER_USER" ? (
                            <li className="">
                              <Link
                                to="/dashboard"
                                className="dashboard first__link"
                              >
                                {" "}
                                <div className="row">
                                  <div className="col-3">
                                    <i
                                      className="icon-home"
                                      style={{ margin: "-43px", content: "" }}
                                    ></i>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        margin: "-37px",
                                        color: "#17191c",
                                      }}
                                      className="dashboard"
                                    >
                                      Dashboard
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          ) : null}
                        </ul>
                      </li>

                      {this.props.user?.data.role !== "RETAILER_USER" ? (
                        <li
                          className={
                            this.manageDropdown.includes(
                              this.props.location.pathname
                            )
                              ? "active"
                              : null
                          }
                          id="dataInsertDropDown"
                        >
                          <a
                            href="#!"
                            className="has-arrow"
                            onClick={(e) => {
                              e.preventDefault();
                              this.activeMenutabContainer("dataInsertDropDown");
                            }}
                          >
                            <i className="fa fa-building-o"></i>{" "}
                            <span>Manage</span>
                          </a>
                          <ul
                            className={
                              this.manageDropdown.includes(
                                this.props.location.pathname
                              )
                                ? "in collapse"
                                : "collapse"
                            }
                          >
                            {this.props.user?.data.role == "SUPER_ADMIN" ? (
                              <li
                                className={
                                  activeKey === "dashboard" ? "active" : ""
                                }
                              >
                                <Link to="/company">Company</Link>
                              </li>
                            ) : null}
                            {this.props.user?.data.role == "SUPER_ADMIN" ||
                              this.props.user?.data.role == "COMPANY_ADMIN" ? (
                              <li
                                className={
                                  activeKey === "dashboard" ? "active" : ""
                                }
                              >
                                <Link
                                  to="/retailer"
                                  onClick={() => {
                                    localStorage.removeItem(
                                      "supplierSettingId"
                                    );
                                    localStorage.removeItem(
                                      "selectedSupplierName"
                                    );
                                    localStorage.removeItem(
                                      "marketPlaceSettingId"
                                    );
                                    localStorage.removeItem(
                                      "marketPlaceSettingName"
                                    );
                                    localStorage.removeItem(
                                      "retailerIntegrationId"
                                    );
                                    localStorage.removeItem(
                                      "newlyAddedRetailer"
                                    );
                                    localStorage.removeItem("currentPage");
                                    localStorage.removeItem("addPromptId");
                                    localStorage.removeItem("supplierId");
                                    localStorage.removeItem("supplierName");

                                  }}

                                >
                                  Retailer
                                </Link>
                              </li>
                            ) : null}
                            <li
                              className={
                                activeKey === "dashboard" ? "active" : ""
                              }
                            >
                              <Link to="/user">User</Link>
                            </li>
                          </ul>
                        </li>
                      ) : null}
                      {this.props.user?.data.role == "SUPER_ADMIN" ||
                        supplier === true ||
                        updateSupplier === true ||
                        viewSupplier === true ? (
                        <li className="" id="dataInsertDropDown1">
                          <a
                            href="#!"
                            className="has-arrow"
                            onClick={(e) => {
                              e.preventDefault();
                              this.activeMenutabContainer(
                                "dataInsertDropDown1"
                              );
                            }}
                          >
                            <>
                              <i className="fa fa-gear"></i>{" "}
                              <span>Integration</span>
                            </>
                          </a>
                          <ul className="collapse">
                            <li
                              className={
                                activeKey === "dashboard" ? "active" : ""
                              }
                            >
                              <Link
                                to="/integration"
                                onClick={() => {
                                  localStorage.removeItem("supplierId");
                                  localStorage.removeItem("supplierName");
                                  localStorage.removeItem("selectedOption");
                                  localStorage.removeItem("marketPlaceId");
                                  localStorage.removeItem("marketPlaceName");
                                  localStorage.removeItem("integratorId");
                                  localStorage.removeItem("integratorName");
                                  localStorage.removeItem("currentPage");
                                  localStorage.removeItem("addPromptId");

                                }}
                              >
                                Manage Integration
                              </Link>
                            </li>
                          </ul>
                        </li>
                      ) : null}

                      {this.props.user?.data.role === "SUPER_ADMIN" ||
                        viewProductList === true ? (
                        <>
                          <li className="" id="productDropDown">
                            <a
                              href="#!"
                              className="has-arrow"
                              onClick={(e) => {
                                e.preventDefault();
                                this.activeMenutabContainer("productDropDown");
                              }}
                            >
                              <i className="fa fa-list-alt"></i>{" "}
                              <span>Products</span>
                            </a>
                            <ul className="collapse">
                              <li
                                className={
                                  activeKey === "dashboard" ? "active" : ""
                                }
                              >
                                <Link to="/products">Products List</Link>
                              </li>
                            </ul>
                          </li>
                        </>
                      ) : null}

                      {/*    {this.props.user?.data.role === "SUPER_ADMIN" ||
                      viewFileUpload ? (
                        <>
                          <li className="" id="fileDropdown">
                            <a
                              href="#!"
                              className="has-arrow"
                              onClick={(e) => {
                                e.preventDefault();
                                this.activeMenutabContainer("fileDropdown");
                              }}
                            >
                              <i className="fa fa-file"></i>{" "}
                              <span>File Upload</span>
                            </a>
                            <ul className="collapse">
                              <li
                                className={
                                  activeKey === "dashboard" ? "active" : ""
                                }
                                onClick={() => {
                                  localStorage.removeItem("supplierId");
                                  localStorage.removeItem("supplierName");
                                  localStorage.removeItem("addPromptId");

                                }}
                              >
                                <Link to="/file-upload">File Upload</Link>
                              </li>
                            </ul>
                          </li>
                        </>
                              ) : null}*/}

                      {this.props.user?.data.role === "SUPER_ADMIN" ||
                        cancelOrder === true ? (
                        <li className="" id="exportCsvDropDown">
                          <a
                            href="#!"
                            className="has-arrow"
                            onClick={(e) => {
                              e.preventDefault();
                              this.activeMenutabContainer("exportCsvDropDown");
                            }}
                          >
                            <i className="fa fa-tags"></i> <span>Export</span>
                          </a>
                          <ul className="collapse">
                            <li
                              className={
                                activeKey === "dashboard" ? "active" : ""
                              }
                            >
                              <Link to="/cancelOrder">Cancel Order</Link>
                            </li>
                          </ul>
                        </li>
                      ) : null}

                      {this.props.user?.data.role == "SUPER_ADMIN" ||
                        viewPrompt === true ? (
                        <>
                          <li className="" id="promptKeyDropdown">
                            <a
                              href="#!"
                              className="has-arrow"
                              onClick={(e) => {
                                e.preventDefault();
                                this.activeMenutabContainer(
                                  "promptKeyDropdown"
                                );
                              }}
                            >
                              <i className="fa fa-superpowers"></i>{" "}
                              <span onClick={() => {
                                localStorage.removeItem("promptKeyId");
                              }}>Prompt Settings</span>
                            </a>
                            <ul className="collapse">
                              <li
                                className={
                                  activeKey === "dashboard" ? "active" : ""
                                }
                              >
                                <Link
                                  to="/prompt-keys"
                                  onClick={() => {
                                    localStorage.removeItem("promptKeyId");
                                  }}
                                >Prompt Key</Link>
                              </li>
                              <li
                                className={
                                  activeKey === "dashboard" ? "active" : ""
                                }
                              >
                                <Link to="/prompt-list" onClick={() => {
                                  localStorage.removeItem("supplierId");
                                  localStorage.removeItem("addPromptId");
                                  localStorage.removeItem("currentPage")
                                }}> Prompt Add</Link>
                              </li>
                            </ul>
                          </li>
                        </>
                      ) : null}

                      <li className="" id="logDropDown">
                        <a
                          href="#!"
                          className="has-arrow"
                          onClick={(e) => {
                            e.preventDefault();
                            this.activeMenutabContainer("logDropDown");
                          }}
                        >
                          <i className="fa fa-history"></i> <span>Logs</span>
                        </a>
                        <ul className="collapse">
                          <li
                            className={
                              activeKey === "dashboard" ? "active" : ""
                            }
                          >
                            <Link to="/apilogs">API Logs</Link>
                          </li>
                          <li
                            className={
                              activeKey === "dashboard" ? "active" : ""
                            }
                          >
                            <Link to="/orderlogs">Order Logs</Link>
                          </li>
                          <li
                            className={
                              activeKey === "dashboard" ? "active" : ""
                            }
                          >
                            <Link to="/exportdatalogs">Export Logs</Link>
                          </li>
                          <li
                            className={
                              activeKey === "dashboard" ? "active" : ""
                            }
                            onClick={() => {
                              localStorage.removeItem("supplierId");
                              localStorage.removeItem("supplierName");
                            }}
                          >
                            <Link to="/file-upload">Import Logs</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Nav>
                  <Collapse in={this.state.colapsStatue}>
                    <div id="example-collapse-text">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className={
                              sideMenuTab[0] ? "nav-link active" : "nav-link"
                            }
                            data-toggle="tab"
                            onClick={() => {
                              this.props.onPressSideMenuTab(0);
                            }}
                          >
                            Menu
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              sideMenuTab[1] ? "nav-link active" : "nav-link"
                            }
                            data-toggle="tab"
                            onClick={() => {
                              this.props.onPressSideMenuTab(1);
                            }}
                          >
                            <i className="icon-book-open"></i>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              sideMenuTab[2] ? "nav-link active" : "nav-link"
                            }
                            data-toggle="tab"
                            onClick={() => {
                              this.props.onPressSideMenuTab(2);
                            }}
                          >
                            <i className="icon-settings"></i>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              sideMenuTab[3] ? "nav-link active" : "nav-link"
                            }
                            data-toggle="tab"
                            onClick={() => {
                              this.props.onPressSideMenuTab(3);
                            }}
                          >
                            <i className="icon-question"></i>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content p-l-0 p-r-0">
                        <div
                          className={
                            sideMenuTab[0] ? "tab-pane active show" : "tab-pane"
                          }
                          id="menu"
                        ></div>
                        <div
                          className={
                            sideMenuTab[1]
                              ? "tab-pane p-l-15 p-r-15 show active"
                              : "tab-pane p-l-15 p-r-15"
                          }
                          id="Chat"
                        >
                          <form>
                            <div className="input-group m-b-20">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="icon-magnifier"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                              />
                            </div>
                          </form>
                          <ul className="right_chat list-unstyled">
                            <li className="online">
                              <a>
                                <div className="media">
                                  <img
                                    className="media-object "
                                    alt=""
                                  />
                                  <div className="media-body">
                                    <span className="name">Chris Fox</span>
                                    <span className="message">
                                      Designer, Blogger
                                    </span>
                                    <span className="badge badge-outline status"></span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="online">
                              <a>
                                <div className="media">
                                  <img
                                    className="media-object "
                                    alt=""
                                  />
                                  <div className="media-body">
                                    <span className="name">Joge Lucky</span>
                                    <span className="message">
                                      Java Developer
                                    </span>
                                    <span className="badge badge-outline status"></span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="offline">
                              <a>
                                <div className="media">
                                  <img
                                    className="media-object "
                                    alt=""
                                  />
                                  <div className="media-body">
                                    <span className="name">Isabella</span>
                                    <span className="message">
                                      CEO, Thememakker
                                    </span>
                                    <span className="badge badge-outline status"></span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="offline">
                              <a>
                                <div className="media">
                                  <img
                                    className="media-object "
                                    alt=""
                                  />
                                  <div className="media-body">
                                    <span className="name">
                                      Folisise Chosielie
                                    </span>
                                    <span className="message">
                                      Art director, Movie Cut
                                    </span>
                                    <span className="badge badge-outline status"></span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="online">
                              <a>
                                <div className="media">
                                  <img
                                    className="media-object "
                                    alt=""
                                  />
                                  <div className="media-body">
                                    <span className="name">Alexander</span>
                                    <span className="message">
                                      Writter, Mag Editor
                                    </span>
                                    <span className="badge badge-outline status"></span>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div
                          className={
                            sideMenuTab[2]
                              ? "tab-pane p-l-15 p-r-15 show active"
                              : "tab-pane p-l-15 p-r-15"
                          }
                          id="setting"
                        >
                          <h6>Choose Mode</h6>
                          <ul className="choose-skin list-unstyled">
                            <li
                              data-theme="white"
                              className={
                                document.body.classList.contains("full-dark")
                                  ? ""
                                  : "active"
                              }
                              onClick={() => {
                                this.setState({ somethi: false });
                                document.body.classList.remove("full-dark");
                              }}
                            >
                              <div className="white"></div>
                              <span>Light</span>
                            </li>
                            <li
                              data-theme="black"
                              className={
                                document.body.classList.contains("full-dark")
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                this.setState({ somethi: true });
                                document.body.classList.add("full-dark");
                              }}
                            >
                              <div className="black"></div>
                              <span>Dark</span>
                            </li>
                          </ul>
                          <hr />
                          <h6>Choose Skin</h6>
                          <ul className="choose-skin list-unstyled">
                            <li
                              data-theme="purple"
                              className={
                                themeColor === "theme-purple" ? "active" : ""
                              }
                            >
                              <div
                                className="purple"
                                onClick={() => {
                                  if (themeColor !== "theme-purple") {
                                    document.body.classList.remove(themeColor);
                                  }
                                  this.props.onPressThemeColor("purple");
                                }}
                              ></div>
                              <span>Purple</span>
                            </li>
                            <li
                              data-theme="blue"
                              className={
                                themeColor === "theme-blue" ? "active" : ""
                              }
                            >
                              <div
                                className="blue"
                                onClick={() => {
                                  if (themeColor !== "theme-blue") {
                                    document.body.classList.remove(themeColor);
                                  }
                                  this.props.onPressThemeColor("blue");
                                }}
                              ></div>
                              <span>Blue</span>
                            </li>
                            <li
                              data-theme="cyan"
                              // className="active"
                              className={
                                themeColor === "theme-cyan" ? "active" : ""
                              }
                            >
                              <div
                                className="cyan"
                                onClick={() => {
                                  if (themeColor !== "theme-cyan") {
                                    document.body.classList.remove(themeColor);
                                  }
                                  this.props.onPressThemeColor("cyan");
                                }}
                              ></div>
                              <span>Cyan</span>
                            </li>
                            <li
                              data-theme="green"
                              className={
                                themeColor === "theme-green" ? "active" : ""
                              }
                            >
                              <div
                                className="green"
                                onClick={() => {
                                  if (themeColor !== "theme-green") {
                                    document.body.classList.remove(themeColor);
                                  }
                                  this.props.onPressThemeColor("green");
                                }}
                              ></div>
                              <span>Green</span>
                            </li>
                            <li
                              data-theme="orange"
                              className={
                                themeColor === "theme-orange" ? "active" : ""
                              }
                            >
                              <div
                                className="orange"
                                onClick={() => {
                                  if (themeColor !== "theme-orange") {
                                    document.body.classList.remove(themeColor);
                                  }
                                  this.props.onPressThemeColor("orange");
                                }}
                              ></div>
                              <span>Orange</span>
                            </li>
                            <li
                              data-theme="blush"
                              className={
                                themeColor === "theme-blush" ? "active" : ""
                              }
                            >
                              <div
                                className="blush"
                                onClick={() => {
                                  if (themeColor !== "theme-blush") {
                                    document.body.classList.remove(themeColor);
                                  }
                                  this.props.onPressThemeColor("blush");
                                }}
                              ></div>
                              <span>Blush</span>
                            </li>
                          </ul>
                          <hr />
                          <h6>General Settings</h6>
                          <ul className="setting-list list-unstyled">
                            <li>
                              <label className="fancy-checkbox">
                                <input type="checkbox" name="checkbox" />
                                <span>Report Panel Usag</span>
                              </label>
                            </li>
                            <li>
                              <label className="fancy-checkbox">
                                <input type="checkbox" name="checkbox" />
                                <span>Email Redirect</span>
                              </label>
                            </li>
                            {/* <li>
                          <label className="fancy-checkbox">
                            <input type="checkbox" name="checkbox" />
                            <span>Notifications</span>
                          </label>
                          </li>*/}
                            <li>
                              <label className="fancy-checkbox">
                                <input type="checkbox" name="checkbox" />
                                <span>Auto Updates</span>
                              </label>
                            </li>
                            <li>
                              <label className="fancy-checkbox">
                                <input type="checkbox" name="checkbox" />
                                <span>Offline</span>
                              </label>
                            </li>
                            <li>
                              <label className="fancy-checkbox">
                                <input type="checkbox" name="checkbox" />
                                <span>Location Permission</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div
                          className={
                            sideMenuTab[3]
                              ? "tab-pane p-l-15 p-r-15 show active"
                              : "tab-pane p-l-15 p-r-15"
                          }
                          id="question"
                        >
                          <form>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="icon-magnifier"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                              />
                            </div>
                          </form>
                          <ul className="list-unstyled question">
                            <li className="menu-heading">HOW-TO</li>
                            <li>
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                How to Create Campaign
                              </a>
                            </li>
                            <li>
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Boost Your Sales
                              </a>
                            </li>
                            <li>
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Website Analytics
                              </a>
                            </li>
                            <li className="menu-heading">ACCOUNT</li>
                            <li>
                              <a
                                href="registration"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Cearet New Account
                              </a>
                            </li>
                            <li>
                              <a
                                href="forgotpassword"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Change Password?
                              </a>
                            </li>
                            <li>
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Privacy &amp; Policy
                              </a>
                            </li>
                            <li className="menu-heading">BILLING</li>
                            <li>
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Payment info
                              </a>
                            </li>
                            <li>
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                Auto-Renewal
                              </a>
                            </li>
                            <li className="menu-button m-t-30">
                              <a
                                href="#!"
                                className="btn btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <i className="icon-question"></i> Need Help?
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

NavbarMenu.propTypes = {
  addClassactive: PropTypes.array.isRequired,
  addClassactiveChild: PropTypes.array.isRequired,
  addClassactiveChildApp: PropTypes.array.isRequired,
  addClassactiveChildFM: PropTypes.array.isRequired,
  addClassactiveChildBlog: PropTypes.array.isRequired,
  addClassactiveChildUI: PropTypes.array.isRequired,
  addClassactiveChildWidgets: PropTypes.array.isRequired,
  addClassactiveChildAuth: PropTypes.array.isRequired,
  addClassactiveChildPages: PropTypes.array.isRequired,
  addClassactiveChildForms: PropTypes.array.isRequired,
  addClassactiveChildTables: PropTypes.array.isRequired,
  addClassactiveChildChart: PropTypes.array.isRequired,
  addClassactiveChildMaps: PropTypes.array.isRequired,
  themeColor: PropTypes.string.isRequired,
  generalSetting: PropTypes.array.isRequired,
  toggleNotification: PropTypes.bool.isRequired,
  toggleEqualizer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ navigationReducer, loginReducer }) => {
  const {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
  } = navigationReducer;

  const { user } = loginReducer;

  return {
    addClassactive,
    addClassactiveChild,
    addClassactiveChildApp,
    addClassactiveChildFM,
    addClassactiveChildBlog,
    addClassactiveChildUI,
    addClassactiveChildWidgets,
    addClassactiveChildAuth,
    addClassactiveChildPages,
    addClassactiveChildForms,
    addClassactiveChildTables,
    addClassactiveChildChart,
    addClassactiveChildMaps,
    themeColor,
    generalSetting,
    toggleNotification,
    toggleEqualizer,
    menuProfileDropdown,
    sideMenuTab,
    isToastMessage,
    user,
  };
};

export default connect(mapStateToProps, {
  onPressDashbord,
  onPressDashbordChild,
  onPressThemeColor,
  onPressGeneralSetting,
  onPressNotification,
  onPressEqualizer,
  onPressSideMenuToggle,
  onPressMenuProfileDropdown,
  onPressSideMenuTab,
  tostMessageLoad,
  onLogOut,
  onLoggedin,
  onSystemLoading,
})(withRouter(NavbarMenu));
