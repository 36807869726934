import React, { useState, useEffect } from "react";
import axios from "axios";
import "../PromptSetting/PromptKeysList.css";
import { onLoading } from "../../actions";
import { connect } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { API_PATH } from "../ApiPath/Apipath";
import formatDateToMMDDYYYYhhmma from "../FormatedDate/FormatedDate";
import GeminiPromptHistory from "../ManagePrompt/GeminiPromptHistory";

const tooltip = (
  <Tooltip id="tooltip">
    <strong>Prompt History</strong>
  </Tooltip>
);
const edittooltip = (
  <Tooltip id="tooltip">
    <strong>Prompt Edit</strong>
  </Tooltip>
);

function AddPromptList(props) {
  const [promptList, setPromptList] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [dataLimit, setdataLimit] = useState(10);
  const [status, setStatus] = useState("active");
  const [type, setType] = useState("Prompt");
  const [autoId, setAutoId] = useState(1);
  const [showGeminiModal, setShowGeminiModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const history = useHistory();

  const getPromptInfo = async () => {
    props.onLoading(true);
    try {
      const response = await axios.post(`${API_PATH.GET_ALL_PROMPT_LIST}`, {
        page: currentPage,
        limit: dataLimit,
        type,
        status: status !== "all" ? (status === "active" ? 1 : 0) : null,
      });

      if (response.data.success) {
        setPromptList(response.data.data);
        setTotalPages(Math.ceil(response.data.totalRecord / dataLimit));
      } else {
        setPromptList([]);
      }
      props.onLoading(false);
    } catch (error) {
      console.log("error", error);
      props.onLoading(false);
    }
  };

  useEffect(() => {
    getPromptInfo();
  }, [currentPage, dataLimit, status]);

  const activateDeactivate = (event, promptId) => {
    const status = event.target.checked;
    Swal.fire({
      title: `${status ? "Activate" : "Deactivate"} Prompt?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${status ? "Activate" : "Deactivate"} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onLoading(true);
        axios
          .post(`${API_PATH.CHANGE_ADD_PROMPT_STATUS}`, {
            id: promptId,
            status: status,
          })
          .then((res) => {
            toast.success(res.data.message);

            getPromptInfo();
            setTimeout(() => {
              props.onLoading(false);
            }, 2000);
          })
          .catch((e) => {
            toast.error("Something Went Wrong");

            props.onLoading(false);
          });
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const openModal = (id) => {
    setSelectedId(id);
    setShowGeminiModal(true);
  };

  let filterList = [
    { label: "Activate", value: "active" },
    { label: "Deactivate", value: "deactive" },
    { label: "All", value: "all" },
  ];

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Prompt List"
            Breadcrumb={[{ name: "Prompt List", navigate: "#" }]}
            style={{ position: "sticky", top: 0, zIndex: 999 }}
          />
          <div className="tab-component">
            <div className="card">
              <div className="body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div style={{ minWidth: "110px" }}>
                    <Select
                      options={filterList}
                      onChange={(data) => {
                        setStatus(data.value);
                        setCurrentPage(1);
                      }}
                      defaultValue={filterList[0]}
                    />
                  </div>
                  <div className="d-flex">
                    <Link className="link-btn mr-3" to={`/manage-prompt`}>
                      Add Prompt
                    </Link>
                    <Link className="link-btn" to={`/test-prompt`}>
                      Test Prompt
                    </Link>
                  </div>
                </div>

                <div className="data-table">
                  {props.loading ? (
                    <div className="loader-wrapper">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : null}
                  <table className="table w-100 table-responsive-lg">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Prefix</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promptList.map((prompt, index) => (
                        <>
                          <tr className="custom-border-table">
                            <td>{prompt.id}</td>
                            <td>{prompt.supplierName}</td>
                            <td>{prompt.prefixName}</td>
                            <td>
                              {prompt.updated
                                ? formatDateToMMDDYYYYhhmma(prompt.updated)
                                : formatDateToMMDDYYYYhhmma(prompt.created)}
                            </td>
                            <td>
                              <Form.Check
                                type="switch"
                                id={`${prompt.id}`}
                                checked={prompt.status}
                                onChange={(e) =>
                                  activateDeactivate(e, prompt.id)
                                }
                              />
                            </td>
                            <td className="action-group">
                              <OverlayTrigger placement="top" overlay={edittooltip}>
                                <i
                                  data-placement="top"
                                  title="Edit"
                                  className="fa fa-edit edit"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "addPromptId",
                                      prompt.id
                                    );
                                    localStorage.setItem(
                                      "supplierId",
                                      prompt.supplierId
                                    );
                                    history.push(`/manage-prompt`);
                                  }}
                                  style={{ marginRight: "10px" }}
                                ></i>
                              </OverlayTrigger>{" "}
                              {"    "}
                              <OverlayTrigger placement="top" overlay={tooltip}>
                                <i
                                  className="fa fa-history"
                                  aria-hidden="true"
                                  style={{ color: "#49c5b6" }}
                                  onClick={() =>
                                    history.push(`/prompt-history`, {
                                      supplierId: prompt.supplierId,
                                    })
                                  }
                                ></i>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  {promptList?.length === 0 && (
                    <h4
                      className="no-data"
                      style={{ color: props.loading ? "white" : "#8b8a8a" }}
                    >
                      No Data Found
                    </h4>
                  )}
                  {promptList?.length > 0 && (
                    <div className="pagination-wrapper">
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            &laquo;
                          </button>
                        </li>
                        {[...Array(totalPages)].map((_, index) => (
                          <li
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            key={index + 1}
                          >
                            <button
                              className="page-link"
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          >
                            &raquo;
                          </button>
                        </li>
                      </ul>
                      <select
                        name="companyOwner"
                        className="form-control"
                        onChange={(e) => {
                          setCurrentPage(1);
                          setdataLimit(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GeminiPromptHistory
        showGeminiModal={showGeminiModal}
        setShowGeminiModal={setShowGeminiModal}
        selectedId={selectedId}
      />
    </div>
  );
}
const mapStateToProps = ({ LoadingReducer, loginReducer }) => ({
  loading: LoadingReducer.isLoading,
  user: loginReducer.user,
});
export default connect(mapStateToProps, { onLoading })(AddPromptList);
