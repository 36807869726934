import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { onLoading } from "../../actions";
import "./PromptKey.css";
import { Spinner } from "react-bootstrap";
import { API_PATH } from "../ApiPath/Apipath";
import PageHeader from "../../components/PageHeader";
import { useHistory } from "react-router-dom";

function AddPromptKey(props) {
  const [formData, setFormData] = useState({
    prompt_key: "",
    prompt_slug: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExit, setIsLoadingExit] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [promptList, setPromptList] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const history = useHistory();
  const promptId = localStorage.getItem("promptKeyId");
  const inputRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    getPromptDataById();
    fetchPromptList();

    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setFilteredPrompts([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchPromptList = async () => {
    try {
      const response = await axios.get(
        API_PATH.GET_ALL_PROMPT_KEY_LIST
      );
      if (response.data.success) {
        setPromptList(response.data.data);
      } else {
        toast.error("Failed to fetch prompt list!");
      }
    } catch (error) {
      console.error("Error fetching prompt list:", error);
      toast.error("An error occurred while fetching the prompt list.");
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.prompt_key.trim()) {
      errors.prompt_key = "Key is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const capitalizeTerm = (term) => {
    const standardTerms = ["SKU", "ASIN", "GTIN", "ISBN", "EAN", "UPC", "SEO"];
    const termParts = term.split("_");
    return termParts
      .map((part) => {
        const upperPart = part.toUpperCase();
        if (standardTerms.includes(upperPart)) {
          return upperPart;
        }
        // Capitalize the first letter and lowercase the rest
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
      })
      .join("_");
  };

  const generateSlug = (value) => {
    // Remove special characters and replace spaces with underscores
    const formattedValue = value.replace(/[^\w\s]/gi, "").replace(/\s+/g, "_");
    // Capitalize words and handle standard terms
    return capitalizeTerm(formattedValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // Capitalize specific terms if they are found in the input
    if (name === "prompt_key") {
      const standardTerms = [
        "SKU",
        "ASIN",
        "GTIN",
        "ISBN",
        "EAN",
        "UPC",
        "SEO",
      ];
      const uppercasedTerms = value
        .split(" ")
        .map((term) => {
          return standardTerms.includes(term.toUpperCase())
            ? term.toUpperCase()
            : term;
        })
        .join(" ");
      updatedValue = uppercasedTerms;

      // Filter the prompt list based on the input value
      if (updatedValue.trim() !== "") {
        const filtered = promptList.filter((prompt) =>
          prompt.prompt_key.toLowerCase().includes(updatedValue.toLowerCase())
        );
        setFilteredPrompts(filtered);
      } else {
        setFilteredPrompts([]);
      }
    }

    const updatedFormData = {
      ...formData,
      [name]: updatedValue,
    };

    if (name === "prompt_key") {
      updatedFormData.prompt_slug = generateSlug(updatedValue);
    }

    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const handleSelectPrompt = (prompt) => {
    setFormData({
      prompt_key: prompt.prompt_key,
      prompt_slug: prompt.prompt_slug,
    });
    setFilteredPrompts([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm(formData)) {
        setIsLoadingExit(true);
        setFormLoader(true);

        const apiUrl = `${API_PATH.ADD_OR_EDIT_PROMPT}`;
        const response = await axios.post(apiUrl, {
          id: promptId || undefined,
          prompt_key: formData.prompt_key || "",
          prompt_slug: formData.prompt_slug || "",
        });
        if (response.data.success === true) {
          toast.success(response.data.message || "Prompt key saved successfully!");
          history.push("/prompt-keys");
          if (promptId) {
            localStorage.removeItem("promptKeyId");
          }
        } else {
          toast.error(response.data.message || "Failed to save prompt key!");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while saving the prompt key.");
    } finally {
      setIsLoadingExit(false);
      setFormLoader(false);
    }
  };

  const getPromptDataById = () => {
    const promptKeyId = localStorage.getItem("promptKeyId");
    setFormLoader(true);
    axios
      .post(`${API_PATH.GET_PROMPT_KEY_BY_ID}`, {
        id: promptKeyId,
      })
      .then((response) => {
        const promptData = response.data.data;
        setFormData(promptData);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setFormLoader(false);
      });
  };

  return (
    <>
      <div
        style={{ flex: 1 }}
        onClick={() => {
          document.body.classList.remove("offcanvas-active");
        }}
      >
        <div>
          <div className="container-fluid">
            <PageHeader
              HeaderText={promptId ? "Update Prompt Key" : "Add Prompt Key"}
              Breadcrumb={[
                {
                  name: "Prompt keys List",
                  navigate: "/prompt-keys",
                  items: ["promptKeyId"],
                },
                {
                  name: promptId ? "Update Prompt Key" : "Add Prompt Key",
                  navigate: "#",
                },
              ]}
              className="page-header"
            />

            <div className="tab-component">
              <div className="card">
                <div className="body">
                  {props.updateFormLoading ? (
                    <div className="loader-wrapper">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : null}
                  <p className="supplier-onboarding">Prompt Key Registration</p>
                  <form
                    onSubmit={handleSubmit}
                    name="myForm"
                    encType="multipart/form-data"
                  >
                    <div style={{ marginTop: "35px" }}>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 button-class">
                          <div className="d-flex">
                            <button
                              className="btn btn-primary w-auto btn-lg mr-2"
                              type="submit"
                            >
                              {isLoadingExit ? (
                                <>
                                  <Spinner animation="border" size="sm" />{" "}
                                  Please wait...
                                </>
                              ) : (
                                "Save & Exit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>

                      {formLoader && (
                        <div className="loader-wrapper">
                          <i className="fa fa-refresh fa-spin"></i>
                        </div>
                      )}

                      <div className="row mt-3 mt-lg-0">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Prompt Key <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="prompt_key"
                              placeholder="Enter Key"
                              value={formData?.prompt_key}
                              onChange={handleChange}
                              ref={inputRef}
                            />
                            {formErrors.prompt_key && (
                              <span className="text-danger">
                                {formErrors.prompt_key}
                              </span>
                            )}
                            {filteredPrompts.length > 0 && (
                              <ul
                                className="dropdown-menu show"
                                ref={dropdownRef}
                                style={{
                                  width: inputRef.current.offsetWidth,
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {filteredPrompts.map((prompt) => (
                                  <li
                                    key={prompt.id}
                                    className="dropdown-items"
                                    onClick={() => handleSelectPrompt(prompt)}
                                  >
                                    {prompt.prompt_key}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Prompt Slug</label>
                            <input
                              className="form-control"
                              type="text"
                              name="prompt_slug"
                              value={formData?.prompt_slug}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ LoadingReducer }) => ({
  isLoading: LoadingReducer.isLoading,
});
export default connect(mapStateToProps, { onLoading })(AddPromptKey);
